// React APIs
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from '../authorization';

// Antd APIs
import { ListStates } from '../common';

//カスタマイズAPIs
import { BatchRecordDto, get, getNewesBatchRecord, HomeListRecordDto, getNewesLimited6BatchRecord } from './api';
import { Props as ListProps } from './HomeList'
import { Props as HomeBatchDetailProps } from './HomeBatchDetail'

/**
 * 所要計画のコンポネート
 */
export function HomeHoc(
    List: React.ComponentType<ListProps>,
    HomeBatchDetail: React.ComponentType<HomeBatchDetailProps>,
  ) {

  // URLのID
  const { date: urlDate } = useParams<{ date?: string }>();

  // 画面読み込み中状態
  const [listData, setListData] = useState<ListStates<HomeListRecordDto>>({loading: false, total: 0, data: [] });
  
  // バッチ詳細
  const [batchDetailDisable, setBatchDetailDisable] = useState<boolean>(false);
  // バッチ詳細データ
  const [batchDetailData, setBatchDetailData] = useState<BatchRecordDto[]>();

  //ユーザー
  const user = useUser();
  // URLのID
  const { batchName: urlBatchName } = useParams<{ batchName?: string }>();
  // URL変更のハンドル
  const navigate = useNavigate();

  // URLによって検索を再実施する
  useEffect(() => {

    if(!!urlBatchName) {
      getNewesLimited6BatchRecord(urlBatchName, user).then( (results: BatchRecordDto[]) => {
        setBatchDetailDisable(true)
        setBatchDetailData(results)
      })
    } else {
      
      setBatchDetailDisable(false)

      // 画面読み込み中状態を変更する
      setListData(data => ({ ...data, loading: true }));
      let searchResult: Promise<HomeListRecordDto> = get(user);
      let acceptanceBatchRecord: Promise<BatchRecordDto> = getNewesBatchRecord('inAcceptance', user);
      let orderDetailBatchRecord: Promise<BatchRecordDto> = getNewesBatchRecord('inOrderDetail', user);
      let paymentBatchRecord: Promise<BatchRecordDto> = getNewesBatchRecord('inPayment', user);


      Promise.allSettled([searchResult, acceptanceBatchRecord, orderDetailBatchRecord, paymentBatchRecord]).then( (allResult: any[]) => {

        let homeListRecordData = allResult[0].value as HomeListRecordDto;
        let acceptanceBatchRecordData = allResult[1].value as BatchRecordDto;
        let orderDetailBatchRecordData = allResult[2].value as BatchRecordDto;
        let paymentBatchRecordData = allResult[3].value as BatchRecordDto;
        if(!!homeListRecordData) {
          homeListRecordData.newestAcceptanceBatch = acceptanceBatchRecordData;
          homeListRecordData.newestOrderDetailBatch = orderDetailBatchRecordData;
          homeListRecordData.newestPaymentBatch = paymentBatchRecordData;
        }
        setListData({ loading: true , total: 0, data: [homeListRecordData] });
      })

      // searchResult.then((para: HomeListRecordDto) => {
      //   setListData({ loading: true , total: 0, data: [para] });
      // })
    }


  }, [urlDate, user, urlBatchName]);

  const clickDetail = (batchName: string) => {
    navigate(`/home/${batchName}`)
    setBatchDetailDisable(true)
  }

  const handleCloseBatchDetail = () => {
    setBatchDetailDisable(false)
    navigate('/home')
  }

  //画面リスト
  const listProps: ListProps = {

    data: listData.data[0],
    clickDetail: clickDetail

  };

  //バッチ詳細
  const batchDetailProps: HomeBatchDetailProps = {
    disable: batchDetailDisable,
    detailData: batchDetailData,
    handleClose: handleCloseBatchDetail
  }

  return (
    <>
      <List {...listProps} />
      { !user?.isSupplier && <HomeBatchDetail {...batchDetailProps} /> }
    </>
  );
  
}