import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { date } from "../util/display";

import { login } from "./api";

export interface User {
  isSupplier?: boolean;
  userName?: string;
  companyid?: string;
  auth: boolean;
  serviceState?: string;
}

export const useUser = () => {
  const [user, setUser] = useState<User | null>(null);
  const [cookies] = useCookies(['token']);

  const navigate = useNavigate();

  useEffect(() => {
    const token = cookies['token'];
    if (!!token) {
      login()
        .then((data) => {
          setUser({ ...data, isSupplier: data.companyType !== '2', auth: true });
        })
        .catch((error) => {
          console.log(error);
          setUser({ auth: false });
        });
    } else {
      setUser(null);
      window.location.assign('/auth/');
    }
  }, [cookies]);

  return user;
};
