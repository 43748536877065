import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from "react-router-dom";

// Antd APIs
// Library APIs
import * as XLSX from 'xlsx';
import { DetailStates, ListStates } from '../common';

// 共通のAPIs
// 機能内部のAPIs
import { get, getById, getAll, dto2search, getEstiamteDetail, getSupplier, EstimateRequestSpecifySupplierDto, getSpecifyResponse, updateRespondentsStatus, requestFinish, requestsCancel, updateRespondents, estimateCreate, EstiamteRespondentsResponseDto, convertSearchDto, convertSearch2Dto, supplierDto2search } from './api';
import { estimateModify, EstimateSearchDto, EstimateRequestListDto, EstimateRequestUpdateDto, downloadPassedDto } from './api';
import { EstimateRequestDetailListDto, EstimateRequestSupplierResponseListDto } from './api';
import { Props as ListProps } from './EstimationList';
import { Props as SearchProps } from './EstimationSearch';
import { Props as detailProps } from './EstimationDetail'
import { Props as EditDetailProps } from "./EstimationEditDetail";
import { Props as SupplierListProps } from "./EstimationSupplierList"
import { Props as EstimateSpecifyEditDetailProps } from './EstimationSpecifyEditDetail';
import { Props as EstimateSpecifyDetailProps } from './EstimationSpecifyDetail';

import { displayUtil } from '../util';
import { useFields } from '../fields';
import { EstimationUpload } from './EstimationUpload';
import { EstimatsCreateDetail } from './EstimationCreateDetail';
import { useUser } from '../authorization';
/**
 * 所要計画のコンポネート
 */
export function EstimationHoc(
    // Detail: React.ComponentType<DetailProps>,
    List: React.ComponentType<ListProps>,
    SupplierList: React.ComponentType<SupplierListProps>,
    Search: React.ComponentType<SearchProps>,
    Detail: React.ComponentType<detailProps>,
    EditDetail: React.ComponentType<EditDetailProps>,
    EstimateSpecifyEditDetail: React.ComponentType<EstimateSpecifyEditDetailProps>,
    EstimateSpecifyDetail: React.ComponentType<EstimateSpecifyDetailProps>) {
    // 共通のstates
    const intl = useIntl();
    const user = useUser();

    // URLのID
    const { random: rand, id: urlId, subId: subId } = useParams<{ random?: string, id?: string, subId?: string }>();
    //get url path
    // URLのquery文字列
    const { search } = useLocation();
    // URL変更のハンドル++
    const navigate = useNavigate();
    // 検索条件（フィルター、ページとソート順）
    const [searchDto, setSearchDto] = useState<EstimateSearchDto>(convertSearchDto(search));
    // 一覧のstates（i-Pro）
    const [listData, setListData] = useState<ListStates<EstimateRequestListDto>>({ loading: false, total: 0, data: [] });
    // 一覧のstates（サプライヤー）
    const [listDataSupplier, setListDataSupplier] = useState<ListStates<EstimateRequestSupplierResponseListDto>>({ loading: false, total: 0, data: [] });
    // 検査画面の表示状態
    const [searchVisible, setSearchVisible] = useState<boolean>(false);
    // 見積依頼詳細一覧のstates
    const [detailData, setDetailData] = useState<DetailStates<EstimateRequestDetailListDto>>({ visible: false });
    //見積依頼編集のstates
    const [editDetailData, setEditDetailData] = useState<DetailStates<EstimateRequestUpdateDto>>({ visible: false });
    //指定供应商回复信息
    const [requestSpecifySupplierData, setRequestSpecifySupplierData] = useState<DetailStates<EstimateRequestSpecifySupplierDto>>({ visible: false });
    const [disable, setDisable] = useState<boolean>(false);
    const [uploaEstimate, setUploaEstimate] = useState<EstimateRequestUpdateDto>();
    const [detailVisible, setDetailVisible] = useState<boolean>(false);
    //案件状態
    const statusOptions = useFields('estimateStatus');
    const responseStatus = useFields('responseStatus');
    const buyerDepartmentOptions = useFields('buyerDepartment');
    const estimatesTypeOptions = useFields('estimatesType');
    const responseStatusOptions = useFields('responseStatus');
    const suppliersOption = useFields('supplier');
    const [createEstimateForm, setCreateEstimateForm] = useState<DetailStates<EstimateRequestUpdateDto>>({ visible: false });
    const [uploadEstimate, setUploadEstimate] = useState<DetailStates<EstimateRequestUpdateDto>>({ visible: false });

    const [reSearch, setReSearch] = useState<boolean>(true)
    // URLのQuery変更によって検索を再実施する
    useEffect(() => {

        if (!!urlId) {
            if (!!subId) {
                getSpecifyResponse(urlId, subId)
                    .then((dto: EstimateRequestSpecifySupplierDto) => {
                        setRequestSpecifySupplierData({ visible: true, data: dto });
                        if (dto.supplier?.responseStatus === '003'
                            || dto.supplier?.responseStatus === '004'
                            || dto.status === '002'
                            || dto.status === '003') {
                            setDisable(true);
                        } else {
                            setDisable(false);
                        }
                    });
            } else {
                getById(urlId)
                    .then((record: EstimateRequestDetailListDto) => {
                        setDetailData({ visible: true, data: record });
                    })
                    .catch(() => { })
            }
        } else {
            setListData(data => ({ ...data, loading: true }));
            if (!!user?.isSupplier) {
                setSearchDto(convertSearch2Dto(search));
                getSupplier(search)
                    .then((para: [number, EstimateRequestSupplierResponseListDto[]]) => {
                        const [count, vos] = para;
                        setListDataSupplier({ loading: false, total: count, data: vos })
                    })
                    .catch(() => {
                        setListDataSupplier(data => ({ ...data, loading: false }))
                    });
            } else {
                setSearchDto(convertSearchDto(search));
                get(search)
                    .then((para: [number, EstimateRequestListDto[]]) => {
                        const [count, vos] = para;
                        setListData({ loading: false, total: count, data: vos })
                    })
                    .catch(() => {
                        setListData(data => ({ ...data, loading: false }))
                    });
            }
        }
    }, [rand, search, user, reSearch]);

    // 検索条件を変更する
    const setSearch = (researchDto: any) => {

        // 期間範囲の編集
        if (!!researchDto.requestDate && !!researchDto.requestDate[0]) {
            researchDto.requestDate[0] = researchDto.requestDate[0].startOf('day');
        }
        if (!!researchDto.requestDate && !!researchDto.requestDate[1]) {
            researchDto.requestDate[1] = researchDto.requestDate[1].endOf('day');
        }
        if (!!researchDto.answerDeadline && !!researchDto.answerDeadline[0]) {
            researchDto.answerDeadline[0] = researchDto.answerDeadline[0].startOf('day');
        }
        if (!!researchDto.answerDeadline && !!researchDto.answerDeadline[1]) {
            researchDto.answerDeadline[1] = researchDto.answerDeadline[1].endOf('day');
        }
        // 検索条件変更を反映する
        const query = dto2search(researchDto);
        // URLを再設定する
        navigate(`/estimations${query}`);
    };

    // 検索条件を変更する
    const supplierSetSearch = (researchDto: any) => {

        // 期間範囲の編集
        if (!!researchDto.requestDate && !!researchDto.requestDate[0]) {
            researchDto.requestDate[0] = researchDto.requestDate[0].startOf('day');
        }
        if (!!researchDto.requestDate && !!researchDto.requestDate[1]) {
            researchDto.requestDate[1] = researchDto.requestDate[1].endOf('day');
        }
        if (!!researchDto.answerDeadline && !!researchDto.answerDeadline[0]) {
            researchDto.answerDeadline[0] = researchDto.answerDeadline[0].startOf('day');
        }
        if (!!researchDto.answerDeadline && !!researchDto.answerDeadline[1]) {
            researchDto.answerDeadline[1] = researchDto.answerDeadline[1].endOf('day');
        }
        // 検索条件変更を反映する
        const query = supplierDto2search(researchDto);
        // URLを再設定する
        navigate(`/estimations${query}`);
    };



    // 検索の処理
    const handleSearch = (value: EstimateSearchDto) => {
        setSearchVisible(false);
        setSearch(value);
    };

    const supplierHandleSearch = (value: EstimateSearchDto) => {
        setSearchVisible(false);
        supplierSetSearch(value);
    };


    const handleSelectedRows = (selectedRows: EstimateRequestListDto[]) => {

    }

    const handleSubmit = (tempForm: EstimateRequestUpdateDto) => {
        let details = {
            ...tempForm,
            id: editDetailData.data?.id,
            version: editDetailData.data?.version,
            attachment: []
        } as EstimateRequestUpdateDto
        estimateModify(details).then((result: EstimateRequestUpdateDto) => {
            setEditDetailData({ data: result, visible: true });
        });
    }
    const handleSubmitCreate = (tempForm: EstimateRequestUpdateDto) => {
        return estimateCreate(tempForm).then((para: [number, EstimateRequestListDto[]]) => {
            setReSearch(!reSearch)
        });
    }
    const supplierDownload = () => {

        setListData(data => ({ ...data, loading: true }));

        const downloadSearchDto = { ...searchDto, rowCount: 0 };

        getSupplier(dto2search(downloadSearchDto))
            .then((para: [number, EstimateRequestSupplierResponseListDto[]]) => {

                setListData(data => ({ ...data, loading: false }));

                const [, vos] = para;
                const excelData = [
                    [
                        intl.formatMessage({ id: 'estimate.status' }),
                        intl.formatMessage({ id: 'estimate.responseStatus' }),
                        intl.formatMessage({ id: 'estimate.supplier' }),
                        intl.formatMessage({ id: 'estimate.supplierName' }),
                        intl.formatMessage({ id: 'estimate.requestNo' }),
                        intl.formatMessage({ id: 'estimate.item' }),
                        intl.formatMessage({ id: 'estimate.itemName' }),
                        intl.formatMessage({ id: 'estimate.requestDate' }),
                        intl.formatMessage({ id: 'estimate.answerDeadline' }),
                        intl.formatMessage({ id: 'estimate.estimatedEffectiveDate' }),
                        intl.formatMessage({ id: 'estimate.unitPrice' }),
                        intl.formatMessage({ id: 'estimate.unitCurrency' }),
                        intl.formatMessage({ id: 'estimate.moq' }),
                        intl.formatMessage({ id: 'estimate.mpq' }),
                        intl.formatMessage({ id: 'estimate.estimateStartDate' }),
                        intl.formatMessage({ id: 'estimate.purchasingManager' })
                    ],
                    ...(vos.map(vo => [
                        displayUtil.field(statusOptions)(vo.status),
                        displayUtil.field(responseStatus)(vo.responseStatus),
                        vo.supplier,
                        displayUtil.field(supplierOptions)(vo.supplier),
                        vo.requestNo,
                        vo.item,
                        vo.itemName,
                        displayUtil.date(vo.requestDate),
                        displayUtil.date(vo.answerDeadline),
                        displayUtil.date(vo.estimatedEffectiveDate),
                        vo.unitPrice,
                        vo.unitCurrency,
                        vo.moq,
                        vo.mpq,
                        displayUtil.date(vo.estimateStartDate),
                        vo.purchasingManagerName
                    ]))
                ];
                const worksheet = XLSX.utils.aoa_to_sheet(excelData);
                const workbook = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(workbook, worksheet, intl.formatMessage({ id: ' estimate ' }));
                XLSX.writeFile(workbook, `${intl.formatMessage({ id: 'estimate' })}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
            })
            .catch(() => {

                setListData(data => ({ ...data, loading: false }));
            });
    }

    const download = () => {

        const downloadSearch = dto2search({ ...searchDto, rowCount: 0 });

        setListData(data => ({ ...data, loading: true }));

        get(downloadSearch)
            .then((para: [number, EstimateRequestListDto[]]) => {

                setListData(data => ({ ...data, loading: false }));

                const [, vos] = para;
                const excelData = [
                    [
                        intl.formatMessage({ id: 'estimate.status' }),
                        intl.formatMessage({ id: 'estimate.requestNo' }),
                        intl.formatMessage({ id: 'estimate.buyerDepartment' }),
                        intl.formatMessage({ id: 'estimate.estimatesType' }),
                        intl.formatMessage({ id: 'estimate.purchasingManager' }),
                        intl.formatMessage({ id: 'estimate.item' }),
                        intl.formatMessage({ id: 'estimate.itemName' }),
                        intl.formatMessage({ id: 'estimate.planQuantity' }),
                        intl.formatMessage({ id: 'estimate.requestDate' }),
                        intl.formatMessage({ id: 'estimate.answerDeadline' }),
                        intl.formatMessage({ id: 'estimate.requestCount' }),
                        intl.formatMessage({ id: 'estimate.responseCount' })
                    ],
                    ...(vos.map(vo => [
                        displayUtil.field(statusOptions)(vo.status),
                        vo.requestNo,
                        displayUtil.field(buyerDepartmentOptions)(vo.buyerDepartmentCode),
                        displayUtil.field(estimatesTypeOptions)(vo.estimatesType),
                        vo.purchasingManagerName,
                        vo.item,
                        vo.itemName,
                        vo.planQuantity,
                        displayUtil.date(vo.requestDate),
                        displayUtil.date(vo.answerDeadline),
                        vo.requestCount,
                        vo.responseCount
                    ]))
                ];
                const worksheet = XLSX.utils.aoa_to_sheet(excelData);
                const workbook = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(workbook, worksheet, intl.formatMessage({ id: ' estimate ' }));
                XLSX.writeFile(workbook, `${intl.formatMessage({ id: 'estimate' })}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
            })
            .catch(() => {

                setListData(data => ({ ...data, loading: false }));
            });
    }


    const supplierOptions = useFields('supplier');
    const downloadPassedEstimations = () => {

        const downloadSearch = dto2search({ ...searchDto, rowCount: 0 });

        setListData(data => ({ ...data, loading: true }));

        getAll(downloadSearch)
            .then((para: void | downloadPassedDto[]) => {

                setListData(data => ({ ...data, loading: false }));

                let data = para as downloadPassedDto[]

                let paaseData = data.filter(d => d.responseStatus === '003')

                const excelData = [
                    [
                        // 一览
                        intl.formatMessage({ id: 'estimate.status' }),
                        intl.formatMessage({ id: 'estimate.requestNo' }),
                        intl.formatMessage({ id: 'estimate.buyerDepartment' }),
                        intl.formatMessage({ id: 'estimate.estimatesType' }),
                        intl.formatMessage({ id: 'estimate.purchasingManager' }),
                        intl.formatMessage({ id: 'estimate.item' }),
                        intl.formatMessage({ id: 'estimate.itemName' }),
                        intl.formatMessage({ id: 'estimate.planQuantity' }),
                        intl.formatMessage({ id: 'estimate.requestDate' }),
                        intl.formatMessage({ id: 'estimate.answerDeadline' }),
                        // intl.formatMessage({ id: 'estimate.requestCount' }),
                        // intl.formatMessage({ id: 'estimate.responseCount' }),

                        // 見積依頼情報 -> 見積回答一覧
                        intl.formatMessage({ id: 'estimate.responseStatus' }), //回答状態
                        intl.formatMessage({ id: 'estimate.supplier' }), // 受注先
                        intl.formatMessage({ id: 'estimate.supplierName' }), // 受注名称
                        intl.formatMessage({ id: 'estimate.estimatedEffectiveDate' }), // 見積有効日
                        intl.formatMessage({ id: 'estimate.makerName2' }), // メーカー名
                        intl.formatMessage({ id: 'estimate.makerItem2' }), // メーカー品番
                        intl.formatMessage({ id: 'estimate.itemTypeCode2' }), // 部品種別
                        intl.formatMessage({ id: 'estimate.moq' }), // 最低発注数量
                        intl.formatMessage({ id: 'estimate.mpq' }), // 最小梱包数量
                        intl.formatMessage({ id: 'estimate.leadTime' }), // リードタイム
                        intl.formatMessage({ id: 'estimate.unitPrice' }), // 単価
                        intl.formatMessage({ id: 'estimate.currency' }), // 単価通貨コード
                        intl.formatMessage({ id: 'estimate.type' }), // 型の種類
                        intl.formatMessage({ id: 'estimate.cost' }), // 型費
                        intl.formatMessage({ id: 'estimate.currency' }), // 型通貨コード
                        intl.formatMessage({ id: 'estimate.processNum' }), // 型の工程数
                        intl.formatMessage({ id: 'estimate.moldsNum' }), // 型の面数
                        intl.formatMessage({ id: 'estimate.estimateStartDate' }), // 見積適用開始日
                        intl.formatMessage({ id: 'estimate.estimateEndDate' }), // 見積適用終了日
                        intl.formatMessage({ id: 'common.userName' }), // 更新者
                        intl.formatMessage({ id: 'common.userModifiedDate' }), // 更新日
                    ],
                    ...(paaseData.map(vo => [
                        // 一览
                        displayUtil.field(statusOptions)(vo.status),
                        vo.requestNo,
                        displayUtil.field(buyerDepartmentOptions)(vo.buyerDepartmentCode),
                        displayUtil.field(estimatesTypeOptions)(vo.estimatesType),
                        vo.purchasingManagerName,
                        vo.item,
                        vo.itemName,
                        vo.planQuantity,
                        displayUtil.date(vo.requestDate),
                        displayUtil.date(vo.answerDeadline),
                        // vo.requestCount,
                        // vo.responseCount,

                        // 見積依頼情報 -> 見積回答一覧
                        displayUtil.field(responseStatusOptions)(vo.responseStatus), //回答状態
                        vo.supplier, //受注先
                        displayUtil.field(supplierOptions)(vo.supplier), //受注名称
                        displayUtil.date(dayjs(vo.estimatedEffectiveDate)), // 見積有効日
                        vo.makerName, // メーカー名
                        vo.makerItem, // メーカー品番
                        vo.itemTypeCode, // 部品種別
                        vo.moq, // 最低発注数量
                        vo.mpq, // 最小梱包数量
                        vo.leadTime, // リードタイム
                        vo.unitPrice, // 単価
                        vo.unitCurrency, // 単価通貨コード
                        vo.moldType, // 型の種類
                        vo.moldCost, // 型費
                        vo.moldCurrency, // 型通貨コード
                        vo.moldProcessNum, // 型の工程数
                        vo.moldNum, // 型の面数
                        displayUtil.date(dayjs(vo.estimateStartDate)), // 見積適用開始日
                        displayUtil.date(dayjs(vo.estimateEndDate)), // 見積適用終了日
                        vo.userName, // 更新者
                        displayUtil.date(dayjs(vo.modifiedTime)) // 更新日
                    ]))
                ];
                const worksheet = XLSX.utils.aoa_to_sheet(excelData);
                const workbook = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(workbook, worksheet, intl.formatMessage({ id: ' estimate ' }));
                XLSX.writeFile(workbook, `${intl.formatMessage({ id: 'estimate' })}(${intl.formatMessage({ id: 'estimate.approve' })})_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
            })
            .catch(() => {

                setListData(data => ({ ...data, loading: false }));
            });
    }
    const downloadPassedEstimationsSupplier = () => {

        const downloadSearchDto = { ...searchDto, rowCount: 0 };

        setListData(data => ({ ...data, loading: true }));

        getSupplier(dto2search(downloadSearchDto))
            .then((para: [number, EstimateRequestSupplierResponseListDto[]]) => {

                setListData(data => ({ ...data, loading: false }));

                const [, vos] = para;
                const passedVos = vos.filter(vo => vo.responseStatus === '003')
                const excelData = [
                    [
                        intl.formatMessage({ id: 'estimate.status' }),
                        intl.formatMessage({ id: 'estimate.responseStatus' }),
                        intl.formatMessage({ id: 'estimate.supplier' }),
                        intl.formatMessage({ id: 'estimate.supplierName' }),
                        intl.formatMessage({ id: 'estimate.requestNo' }),
                        intl.formatMessage({ id: 'estimate.item' }),
                        intl.formatMessage({ id: 'estimate.itemName' }),
                        intl.formatMessage({ id: 'estimate.requestDate' }),
                        intl.formatMessage({ id: 'estimate.answerDeadline' }),
                        intl.formatMessage({ id: 'estimate.estimatedEffectiveDate' }),
                        intl.formatMessage({ id: 'estimate.unitPrice' }),
                        intl.formatMessage({ id: 'estimate.unitCurrency' }),
                        intl.formatMessage({ id: 'estimate.moq' }),
                        intl.formatMessage({ id: 'estimate.mpq' }),
                        intl.formatMessage({ id: 'estimate.estimateStartDate' }),
                        intl.formatMessage({ id: 'estimate.purchasingManager' }),
                    ],
                    ...(passedVos.map(vo => [
                        displayUtil.field(statusOptions)(vo.status),
                        displayUtil.field(responseStatus)(vo.responseStatus),
                        vo.supplier,
                        displayUtil.field(suppliersOption)(vo.supplier),
                        vo.requestNo,
                        vo.item,
                        vo.itemName,
                        displayUtil.date(vo.requestDate),
                        displayUtil.date(vo.answerDeadline),
                        displayUtil.date(vo.estimatedEffectiveDate),
                        vo.unitPrice,
                        vo.unitCurrency,
                        vo.moq,
                        vo.mpq,
                        displayUtil.date(vo.estimateStartDate),
                        vo.purchasingManagerName,
                    ]))
                ];
                const worksheet = XLSX.utils.aoa_to_sheet(excelData);
                const workbook = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(workbook, worksheet, intl.formatMessage({ id: ' estimate ' }));
                XLSX.writeFile(workbook, `${intl.formatMessage({ id: 'estimate' })}(${intl.formatMessage({ id: 'estimate.approve' })})_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
            })
            .catch(() => {

                setListData(data => ({ ...data, loading: false }));
            });
    }
    const reset = (rowCount: number) => {
        setSearch({

            requestNo: '',

            requestDate: [null, null],

            answerDeadline: [null, null],

            buyerDepartments: [],

            purchasingManagerName: '',

            item: [],

            itemName: '',

            rowCount: rowCount,

            status: [],

            responseStatus: [],

            estimatesType: [],

            suppliers: []

        } as EstimateSearchDto)
    }
    const listProps = {

        // 画面loading状態
        loading: listData.loading,
        // 件数合計
        total: listData.total,
        // 一覧画面の表示データ
        // data: listData.data,
        data: listData.data,
        // 検索条件（フィルター、ページとソート順）
        searchDto: searchDto,
        // 検査画面を表示する
        openSearchModal: () => setSearchVisible(true),
        //Download
        download: download,
        downloadPassed: downloadPassedEstimations,
        createEstimate: () => setCreateEstimateForm({ visible: true }),
        uploadEstimate: () => setUploadEstimate({ visible: true }),
        // 検査画面を表示する
        handleResearch: setSearch,
        handleSearch: handleSearch,
        handleSelectedRows: handleSelectedRows,
        reset: reset
    };
    const supplierProps = {

        // 画面loading状態
        loading: listDataSupplier.loading,
        // 件数合計
        total: listDataSupplier.total,
        // 一覧画面の表示データ
        data: listDataSupplier.data,
        // 検索条件（フィルター、ページとソート順）
        searchDto: searchDto,
        // 検査画面を表示する
        openSearchModal: () => setSearchVisible(true),
        //Download
        download: supplierDownload,
        downloadPassed: downloadPassedEstimationsSupplier,
        // 検査画面を表示する
        handleResearch: supplierSetSearch,
        handleSearch: supplierHandleSearch,
        handleSelectedRows: handleSelectedRows,
        reset:reset
    };

    const searchProps = {
        // 検査画面の表示状態
        visible: searchVisible,
        searchDto: searchDto,
        handleSearch: (value: EstimateSearchDto) => {
            value.buyerDepartments = !!value.buyerDepartments ? value.buyerDepartments : [];
            value.purchasingManagerName = !!value.purchasingManagerName ? value.purchasingManagerName : '';
            value.estimatesType = typeof (value.estimatesType) === 'string' ? [value.estimatesType] : value.estimatesType
            setSearchVisible(false);
            setSearch(value);
        },
        close: () => setSearchVisible(false),
    };

    const detailProps = {
        // 詳細画面の表示状態
        visible: detailData.visible,
        detail: detailData.data,
        visibility: user?.isSupplier,
        close: () => {

            setDetailData(data => ({ ...data, visible: false }));
            const query = dto2search(searchDto);
            // URLを再設定する
            navigate(`/estimations${query}`);
        },
        openRequestDetailModal: () => {
            setDetailVisible(true);
            if (!!urlId) {
                getEstiamteDetail(urlId)
                    .then((dto: EstimateRequestUpdateDto) => {
                        setEditDetailData({ visible: true, data: dto });
                    }).catch(() => {
                    })
            }

        },
        requestClose: () => {
            if (!!detailData?.data) {
                requestFinish(detailData.data).then((result: EstimateRequestDetailListDto) => {
                    setDetailData({ visible: false })
                    if (dto2search(searchDto) !== '') {
                        navigate(-1);
                    } else {
                        navigate('/estimations');
                    }
                })
            }
        },
        cancel: () => {
            if (!!detailData?.data) {
                requestsCancel(detailData?.data).then((result: EstimateRequestDetailListDto) => {
                    setDetailData({ visible: false })
                    if (dto2search(searchDto) !== '') {
                        navigate(-1);
                    } else {
                        navigate('/estimations');
                    }
                })
            }
        }
    };

    const editDetailProps = {
        // 詳細画面の表示状態
        visible: editDetailData.visible,
        detail: editDetailData.data,
        handleSubmit: handleSubmit,
        close: () => {
            setEditDetailData({ visible: false });
            let random = Math.round(Math.random() * 100000);
            navigate(`/estimations/detail/${random}/${urlId}`);
        },
    };

    const specifyEditDetailProps: EstimateSpecifyEditDetailProps = {
        // 詳細画面の表示状態
        visible: requestSpecifySupplierData.visible,
        detail: requestSpecifySupplierData.data,
        enable: disable,
        close: () => {
            setRequestSpecifySupplierData({ visible: false });
            navigate('/estimations');
        },
        joining: (tempForm: EstiamteRespondentsResponseDto) => {
            setRequestSpecifySupplierData({ visible: false });
            if (!!requestSpecifySupplierData.data && !!requestSpecifySupplierData.data.supplier) {
                requestSpecifySupplierData.data.supplier.makerName = tempForm.makerName
                requestSpecifySupplierData.data.supplier.makerItem = tempForm.makerItem
                requestSpecifySupplierData.data.supplier.contractConditionsCode = tempForm.contractConditionsCode
                requestSpecifySupplierData.data.supplier.editionCurrency = tempForm.editionCurrency
                requestSpecifySupplierData.data.supplier.editionFee = tempForm.editionFee
                requestSpecifySupplierData.data.supplier.estimateEndDate = tempForm.estimateEndDate
                requestSpecifySupplierData.data.supplier.estimatedEffectiveDate = tempForm.estimatedEffectiveDate
                requestSpecifySupplierData.data.supplier.estimateStartDate = tempForm.estimateStartDate
                requestSpecifySupplierData.data.supplier.itemTypeCode = tempForm.itemTypeCode
                requestSpecifySupplierData.data.supplier.jigCost = tempForm.jigCost
                requestSpecifySupplierData.data.supplier.jigCurrency = tempForm.jigCurrency
                requestSpecifySupplierData.data.supplier.leadTime = tempForm.leadTime
                requestSpecifySupplierData.data.supplier.moldCost = tempForm.moldCost
                requestSpecifySupplierData.data.supplier.moldCurrency = tempForm.moldCurrency
                requestSpecifySupplierData.data.supplier.moldNum = tempForm.moldNum
                requestSpecifySupplierData.data.supplier.moldProcessNum = tempForm.moldProcessNum
                requestSpecifySupplierData.data.supplier.moq = tempForm.moq
                requestSpecifySupplierData.data.supplier.mpq = tempForm.mpq
                requestSpecifySupplierData.data.supplier.unitPrice = tempForm.unitPrice
                requestSpecifySupplierData.data.supplier.unitCurrency = tempForm.unitCurrency
                requestSpecifySupplierData.data.supplier.moldType = tempForm.moldType
                requestSpecifySupplierData.data.supplier.repairsCost = tempForm.repairsCost
                requestSpecifySupplierData.data.supplier.repairsCurrency = tempForm.repairsCurrency
                requestSpecifySupplierData.data.supplier.remarks = tempForm.remarks
                requestSpecifySupplierData.data.supplier.prevLocation = tempForm.prevLocation
                requestSpecifySupplierData.data.supplier.nextLocation = tempForm.nextLocation
                requestSpecifySupplierData.data.supplier.originalCountryName = tempForm.originalCountryName
                requestSpecifySupplierData.data.supplier.attachments = tempForm.attachments
                requestSpecifySupplierData.data.status = '001'
                updateRespondents(requestSpecifySupplierData.data).then((result: EstimateRequestDetailListDto) => {
                    // setDetailData({ visible: true, data: result });
                    navigate('/estimations');
                })
            }
        },

        absenting: () => {
            setRequestSpecifySupplierData({ visible: false });
            if (!!requestSpecifySupplierData.data
                && !!requestSpecifySupplierData.data.supplier
                && !!requestSpecifySupplierData.data.supplier.responseStatus) {
                requestSpecifySupplierData.data.supplier.responseStatus = '002'
                updateRespondentsStatus(requestSpecifySupplierData.data).then((result: EstimateRequestDetailListDto) => {
                    // setDetailData({ visible: true, data: result });
                    navigate('/estimations');
                })
            }
        }
    };

    const specifyDetailProps: EstimateSpecifyDetailProps = {
        // 詳細画面の表示状態
        visible: requestSpecifySupplierData.visible,
        detail: requestSpecifySupplierData.data,
        enable: disable,
        reApply: () => {
            setStatus('006');
            if (!!requestSpecifySupplierData.data) {
                requestSpecifySupplierData.data.supplier.responseStatus = '006';
                updateRespondentsStatus(requestSpecifySupplierData.data).then((result: EstimateRequestDetailListDto) => {
                    // push('/estimations');
                    setDetailData({ visible: true, data: result });
                })
            }
            setRequestSpecifySupplierData({ visible: false });
        },
        adopt: () => {
            setStatus('003');
            if (!!requestSpecifySupplierData.data) {
                requestSpecifySupplierData.data.supplier.responseStatus = '003';
                setRequestSpecifySupplierData({ visible: false });
                updateRespondentsStatus(requestSpecifySupplierData.data).then((result: EstimateRequestDetailListDto) => {
                    // push('/estimations');
                    setDetailData({ visible: true, data: result });
                })
            }
        },
        refuse: () => {
            setStatus('004');
            if (!!requestSpecifySupplierData.data) {
                requestSpecifySupplierData.data.supplier.responseStatus = '004';
                updateRespondentsStatus(requestSpecifySupplierData.data).then((result: EstimateRequestDetailListDto) => {
                    // push('/estimations');
                    setDetailData({ visible: true, data: result });
                })
            }
            setRequestSpecifySupplierData({ visible: false });
        },
        close: () => {
            setRequestSpecifySupplierData({ visible: false });
            let random = Math.round(Math.random() * 100000);
            navigate(`/estimations/detail/${random}/${urlId}`);
        }
    };

    const creatDetailProps = {
        visible: createEstimateForm.visible,
        detail: createEstimateForm.data,
        handleSubmit: handleSubmitCreate,
        close: () => {
            setCreateEstimateForm({ visible: false });
        }
    }

    const uploadEstimateProps = {
        visible: uploadEstimate.visible,
        datail: uploadEstimate.data,
        handleSubmit: handleSubmit,
        close: () => {
            setUploadEstimate({ visible: false });
        }
    }

    function setStatus(status: string) {
        setRequestSpecifySupplierData(requestSpecifySupplier => {
            if (!!requestSpecifySupplier.data && !!requestSpecifySupplier.data.supplier) {
                requestSpecifySupplier.data.supplier.responseStatus = status;
            }
            return requestSpecifySupplier;
        })
    }

    return (
        <>
            {
                !!user?.isSupplier ? <SupplierList {...supplierProps} /> : <List {...listProps} />
            }
            {
                !!user?.isSupplier ? '' : <Detail {...detailProps} />
            }
            <Search {...searchProps} />
            <EditDetail {...editDetailProps} />
            <EstimationUpload {...uploadEstimateProps} />
            <EstimatsCreateDetail {...creatDetailProps} />
            {
                !!user?.isSupplier ? <EstimateSpecifyEditDetail {...specifyEditDetailProps} /> : <EstimateSpecifyDetail {...specifyDetailProps} />
            }
        </>
    );
}