import React, { Key, useState } from 'react';
import { FormattedMessage, useIntl, } from 'react-intl';
import { Button, Popconfirm, Table, } from 'antd';
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, RollbackOutlined, SearchOutlined, UploadOutlined} from '@ant-design/icons';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnsType } from 'antd/lib/table';
import { SorterResult } from 'antd/es/table/interface';

import { arrayUtil, displayUtil, filterUtil, } from '../util'
import { InputFilter, RangeFilter, Filter, DateRange, SelectFilter, constListScroll, constPageSizeOptions, } from '../common'
import { useFields } from '../fields';

import { SupplierEstimatesSearchDto, SupplierEstimatesDto, } from './api'
import './index.css';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../authorization';

export interface Props {
  loading: boolean;
  searchDto: SupplierEstimatesSearchDto;
  total: number;
  data: SupplierEstimatesDto[];
  remove: (dto: SupplierEstimatesDto) => void;
  openRequestModal: () => void;
  openSearchModal: () => void;
  handleResearch: (researchDto: SupplierEstimatesSearchDto) => void;
  handleSearch: (searchDto: SupplierEstimatesSearchDto) => void;
  downloadModel: () => void;
  downloadTemplate: () => void;
  downloadPassed: () => void;
  reset:(rowCount:number)=> void;

}

export function SupplierEstimatesList(props: Props) {

  const intl = useIntl();
  const navigate = useNavigate();
  const user = useUser();
  const statusOptions = useFields('status');
  const supplierOptions = useFields('supplier');
  const fullSupplierOptions = useFields('fullSupplier');
  const buyerOptions = useFields('buyer');
  const [rowCount, setRowCount] = useState<number>(10);
  // ページング、フィルター、ソート順の処理
  const handleTableChange = (pagination: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: SorterResult<SupplierEstimatesDto> | SorterResult<SupplierEstimatesDto>[],
    extra: any) => {

    // 再検索の条件を作成する
    const researchDto: SupplierEstimatesSearchDto = { ...props.searchDto };
    let researchFlag = false;

    //案件状態の場合`
    const { status: preStatus } = researchDto;
    const { status } = filters;
   
    const curentStatus = status ? status as string[] : [];
    if (!!pagination.pageSize && rowCount !== pagination.pageSize) {
      setRowCount(pagination.pageSize)
    }

    if (!arrayUtil.equals(curentStatus, preStatus)) {
      researchDto.status = curentStatus;
      props.handleSearch(researchDto);
    }

    

    if (!!pagination.pageSize && props.searchDto.rowCount !== pagination.pageSize) {
      researchDto.page = 1;
      researchDto.rowCount = pagination.pageSize;
      researchFlag = true;
    }
    else if (props.searchDto.page !== pagination.current) {
      researchDto.page = pagination.current;
      researchFlag = true;
    }

    const fixSorter = sorter as SorterResult<SupplierEstimatesDto>;
    if (props.searchDto.sortField !== fixSorter.field
      || props.searchDto.sortOrder !== fixSorter.order) {
      researchDto.sortField = fixSorter.field as string | undefined;
      researchDto.sortOrder = fixSorter.order;
      researchFlag = true;
    }

    if (researchFlag) {
      props.handleResearch(researchDto);
    }
  };

  const filterInput = (buyerTitle: 'requestNo' | 'purchasingManagerName' | 'supplierName') =>
    (value?: string) => {
      if (props.searchDto[buyerTitle] !== value) {
        const researchDto: SupplierEstimatesSearchDto = { ...props.searchDto };
        researchDto[buyerTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };

  const filterSelect = (buyerTitle: 'status' | 'buyers' | 'suppliers') =>
    (value: string[]) => {
      if (props.searchDto[buyerTitle] !== value) {
        const researchDto: SupplierEstimatesSearchDto = { ...props.searchDto };
        researchDto[buyerTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };

  const filterRanger = (buyerTitle: 'requestDate') =>
    (value: DateRange) => {
      if (props.searchDto[buyerTitle] !== value) {
        const researchDto: SupplierEstimatesSearchDto = { ...props.searchDto };
        researchDto[buyerTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };

  const sortOrder = (buyerTitle: string): 'ascend' | 'descend' | null | undefined => {

    if (buyerTitle === props.searchDto.sortField) {
      return props.searchDto.sortOrder;
    }

    return null;
  };

  // 案件状態 
  const statusTitle = <FormattedMessage id='estimate.status' />;
  // 入札依頼番号
  const requestNoTitle = <FormattedMessage id='estimate.requestNo' />;
  // 購入元コード
  const buyerTitle = <FormattedMessage id='estimate.buyer' />;
  // 購入元名称
  const buyerNameTitle = <FormattedMessage id='estimate.buyerName' />;
  // 購入先コード 
  const supplierTitle = <FormattedMessage id='estimate.supplier' />;
  // 購入先名称
  const supplierNameTitle = <FormattedMessage id='estimate.supplierName' />;
  // 依頼日
  const requestDateTitle = <FormattedMessage id='estimate.requestDate' />;
  // 購買担当
  const purchasingManagerNameTitle = <FormattedMessage id='estimate.purchasingManager' />;

  const width100 = '100px';
  const width120 = '120px';
  const width140 = '140px';
  const width150 = '150px';
  const width160 = '160px';
  const width200 = '200px';
  const operationTitle = <FormattedMessage id='common.operation' />;
  type filterType = Filter<SupplierEstimatesDto>;

  const statusFilter: filterType = {
    filters: filterUtil.field2Fileter(statusOptions), filterMultiple: true,
    filteredValue: props.searchDto.status,
    onFilter: (value: any, record: SupplierEstimatesDto) => record.status === value
  };
  const requestNoFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <InputFilter value={props.searchDto.requestNo} onChange={filterInput('requestNo')} />,
    filteredValue: !props.searchDto.requestNo ? [] : [props.searchDto.requestNo],
  };
  const suppliersFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <SelectFilter fields={fullSupplierOptions} values={props.searchDto.suppliers} onChange={filterSelect('suppliers')} />,
    filteredValue: props.searchDto.suppliers,
  };
  const buyersFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <SelectFilter fields={buyerOptions} values={props.searchDto.buyers} onChange={filterSelect('buyers')} />,
    filteredValue: props.searchDto.buyers,
  };
  const requestDateFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <RangeFilter values={props.searchDto.requestDate} onChange={filterRanger('requestDate')} />,
    filteredValue: filterUtil.range2stringArray(props.searchDto.requestDate),
  };
  const purchasingManagerNameFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <InputFilter value={props.searchDto.purchasingManagerName} onChange={filterInput('purchasingManagerName')} />,
    filteredValue: !props.searchDto.purchasingManagerName ? [] : [props.searchDto.purchasingManagerName],
  };


  const supplierNameFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <InputFilter value={props.searchDto.supplierName} onChange={filterInput('supplierName')} />,
    filteredValue: !props.searchDto.supplierName ? [] : [props.searchDto.supplierName],
  };

  const requestNoLink = (text: any, record: SupplierEstimatesDto, index: number) => {
    return <Link to={`/supplierestimates/${record.id}`}>{record.requestNo}</Link>;
  };

  const editButtons = (text: any, record: SupplierEstimatesDto, index?: number) => {

    let supplierEstimatesStatusDisable = record.status === '002' ? true : false
    let supplierEstimatesDetailStatusDisable = record.details.filter(detail => detail.responseStatus === '003').length > 0 ? true : false
    let buttonDisabled = supplierEstimatesStatusDisable || supplierEstimatesDetailStatusDisable

    return (<>
      <Popconfirm
        disabled={buttonDisabled}
        title={intl.formatMessage({ id: 'message.delConfirm' })}
        onConfirm={() => props.remove(record)}
        okText={intl.formatMessage({ id: 'common.confirm' })}
        cancelText={intl.formatMessage({ id: 'common.cancel' })} >
        <Button disabled={buttonDisabled} style={{height: '2.5vh', width: '2.5vw', fontSize: '1.5vh', padding: '0', lineHeight: '100%', display: 'block', alignItems: 'center', justifyContent: 'center'}}>
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    </>);
  };

  const columns: ColumnsType<SupplierEstimatesDto> = [];
  // 案件状態
  columns.push({ title: statusTitle, dataIndex: 'status', render: displayUtil.field(statusOptions), width: width140, sorter: true, sortOrder: sortOrder('status'), ...statusFilter });
  // 入札依頼番号
  columns.push({ title: requestNoTitle, dataIndex: 'requestNo', render: requestNoLink, width: width140, sorter: true, sortOrder: sortOrder('requestNo'), ...requestNoFilter });
  if (!!user?.isSupplier) {
    // 購入先コード
    // columns.push({ title: buyerTitle, dataIndex: 'buyer', width: width140, sorter: true, sortOrder: sortOrder('buyer'), ...buyersFilter });
    // 購入先名
    columns.push({ title: buyerNameTitle, dataIndex: 'buyer', render: displayUtil.field(buyerOptions), width: width200, ellipsis: true, sorter: false, });
  }

  // if (!user?.isSupplier) {
    // 購入元コード
    columns.push({ title: supplierTitle, dataIndex: 'supplier', width: width140, sorter: true, sortOrder: sortOrder('supplier'), ...suppliersFilter });
    // 購入元名称
    columns.push({ title: supplierNameTitle, dataIndex: 'supplier', render: displayUtil.field(supplierOptions), width: width200, ellipsis: true, sorter: false, ...supplierNameFilter});
  // }
  // 購買担当
  columns.push({ title: purchasingManagerNameTitle, dataIndex: 'purchasingManagerName', width: width150, ellipsis: true, sorter: true, sortOrder: sortOrder('purchasingManagerName'), ...purchasingManagerNameFilter });
  // 依頼日
  columns.push({ title: requestDateTitle, dataIndex: 'requestDate', render: displayUtil.date, width: width140, ellipsis: true, sorter: true, sortOrder: sortOrder('requestDate'), ...requestDateFilter });
  if (!!user?.isSupplier) {
    // アクション
    columns.push({ title: operationTitle, render: editButtons, width: width160, sorter: false });
  }

  const paginationProps: TablePaginationConfig = {
    current: props.searchDto.page,
    total: props.total,
    defaultPageSize: props.searchDto.rowCount,
    position: ['bottomLeft'],
    showSizeChanger: true,
    pageSizeOptions: constPageSizeOptions,
  };

  const title = () => (<>
    {!!user?.isSupplier &&
      <FormattedMessage id='supplierEstimates.supplierList' />}
    {!user?.isSupplier &&
      <FormattedMessage id='supplierEstimates.list' />}
    <Button className='ant-btn' style={{ margin: '0 0 0 10px', marginRight: 10 }} onClick={props.openSearchModal}>
      <SearchOutlined />
      <FormattedMessage id='common.conditions' />
    </Button>
    <Button onClick={props.downloadModel} style={{ margin: '0 0 0 10px', marginRight: 10 }}>
      <DownloadOutlined />
      <FormattedMessage id='common.download' />
    </Button>
    {!!user?.isSupplier &&
      <div style={{ display: 'inline-block' }}>
        <Button onClick={props.openRequestModal} style={{ margin: '0 0 0 10px', marginRight: 10 }}>
          <UploadOutlined />
          <FormattedMessage id='supplierEstimates.requestList' />
        </Button>
      </div>
    }
    {!!user?.isSupplier &&
      <div style={{ display: 'inline-block' }}>
        <Button onClick={props.downloadTemplate} style={{ margin: '0 0 0 10px', marginRight: 10 }}>
          <DownloadOutlined />
          <FormattedMessage id='supplierEstimates.templateDownload' />
        </Button>
      </div>
    }
    <Button onClick={props.downloadPassed} style={{ marginLeft: 10, marginRight: 10 }} >
      <DownloadOutlined />
      <FormattedMessage id='common.downloadPassed' />
    </Button>

    
    <Button className='reset-btn' onClick={() => { props.reset(rowCount); }}>
      <RollbackOutlined />
      <FormattedMessage id='common.reset' />
    </Button>
  </>)

  return (
    <Table<SupplierEstimatesDto>
      className="list-table"
      pagination={paginationProps}
      columns={columns}
      dataSource={props.data}
      loading={props.loading}
      rowClassName={displayUtil.rowClassName}
      rowKey={'id'}
      tableLayout={'fixed'}
      title={title}
      onChange={handleTableChange}
      scroll={constListScroll}
    />
  );
}
