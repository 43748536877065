import React, { Key, useState } from 'react';
import { FormattedMessage, useIntl, } from 'react-intl';
import { Button, Card, Col, Popconfirm, Row, Table, } from 'antd';
import { RollbackOutlined, SearchOutlined, DownloadOutlined, UploadOutlined, FunnelPlotFilled } from '@ant-design/icons';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnsType } from 'antd/lib/table';
import { ExpandableConfig, SorterResult } from 'antd/es/table/interface';

import { arrayUtil, displayUtil, filterUtil, } from '../util'
import { InputFilter, RangeFilter, Filter, DateRange, SelectFilter, constListScroll, constPageSizeOptions, NumberRangeFilter, NumberRange, } from '../common'
import { useFields } from '../fields';

import { OrderResponseSearchDto, OrderListRecordDto } from './api'
import './index.css';
import { Link } from 'react-router-dom';
import { useUser } from '../authorization';

export interface Props {
  loading: boolean;
  searchDto: OrderResponseSearchDto;
  total: number;
  data: OrderListRecordDto[];
  download: () => void;
  openSearchModal: () => void;
  openUpload: (url: 'uploadorderresponses' | 'uploaddeliveryinfos' | 'uploadDevInstrucstions') => () => void;
  handleResearch: (researchDto: OrderResponseSearchDto) => void;
  downloadInstrucstion: () => void;
  readAll: () => void;
  reset: (rowCount: number) => void;
}

export function OrderResponseList(props: Props) {

  // 共通のstates
  const intl = useIntl();

  const user = useUser();

  const itemOptions = useFields('item');
  const fullSupplierOptions = useFields('fullSupplier')
  const infoTypeOptions = useFields('infoType');
  const qualityCheckFlagOptions = useFields('qualityCheckFlag');
  const orderFinishFlagOptions = useFields('orderFinishFlag')
  const [rowCount, setRowCount] = useState<number>(10);
  // ページング、フィルター、ソート順の処理
  const handleTableChange = (pagination: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: SorterResult<OrderListRecordDto> | SorterResult<OrderListRecordDto>[],
    extra: any) => {

    // 再検索の条件を作成する
    const researchDto: OrderResponseSearchDto = { ...props.searchDto };
    let researchFlag = false;
    //保管場所
    const { locationCd: preLocationCd } = researchDto;
    const { locationCd } = filters;
    const preLocationCds = preLocationCd ? [preLocationCd] : [];
    const curentLocationCds = locationCd ? locationCd as string[] : [];

    if (!!pagination.pageSize && rowCount !== pagination.pageSize) {
      setRowCount(pagination.pageSize)
    }

    if (!arrayUtil.equals(curentLocationCds, preLocationCds)) {
      researchDto.locationCd = curentLocationCds[0];
      props.handleResearch(researchDto);
    }
    //情報種類
    const { infoType: preInfoType } = researchDto;
    const { infoType } = filters;
    const preInfoTypes = preInfoType ? [preInfoType] : [];
    const curentInfoTypes = infoType ? infoType as string[] : [];
    if (!arrayUtil.equals(curentInfoTypes, preInfoTypes)) {
      researchDto.infoType = curentInfoTypes[0];
      props.handleResearch(researchDto);
    }

    //情報種類
    const { finishFlag: preFinishFlag } = researchDto;
    const { finishFlag } = filters;
    const preFinishFlags = preFinishFlag ? [preFinishFlag] : [];
    const curentFinishFlags = finishFlag ? finishFlag as string[] : [];
    if (!arrayUtil.equals(curentFinishFlags, preFinishFlags)) {
      researchDto.finishFlag = curentFinishFlags[0];
      props.handleResearch(researchDto);
    }

    if (!!pagination.pageSize && props.searchDto.rowCount !== pagination.pageSize) {
      researchDto.page = 1;
      researchDto.rowCount = pagination.pageSize;
      researchFlag = true;
    }
    else if (props.searchDto.page !== pagination.current) {
      researchDto.page = pagination.current;
      researchFlag = true;
    }

    const fixSorter = sorter as SorterResult<OrderListRecordDto>;
    if (props.searchDto.sortField !== fixSorter.field
      || props.searchDto.sortOrder !== fixSorter.order) {
      researchDto.sortField = fixSorter.field as string | undefined;
      researchDto.sortOrder = fixSorter.order;
      researchFlag = true;
    }

    if (researchFlag) {
      props.handleResearch(researchDto);
    }
  };

  const filterInput = (itemTitle: 'orderNo' | 'itemName' | 'supplierName' | 'purchasingManagerName' | 'locationCd') =>
    (value?: string) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: OrderResponseSearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };

  const filterSelect = (itemTitle: 'items' | 'suppliers') =>
    (value: string[]) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: OrderResponseSearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };

  const filterRanger = (itemTitle: 'orderDate' | 'productionPlanDate' | 'iniDeliveryDate') =>
    (value: DateRange) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: OrderResponseSearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };

  const filterNumberRanger = (itemTitle: 'unitPrice' | 'amount' | 'balanceAmount') => {
    return (value: NumberRange) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: OrderResponseSearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };
  }

  const numberRangeFilterItemHighLight = (range: NumberRange) => {
    if (range === undefined || range === null) {
      return false
    }
    if (range[0] !== undefined && range[0] !== null) {
      return true
    }
    if (range[1] !== undefined && range[1] !== null) {
      return true
    }
    return false
  }

  const sortOrder = (itemTitle: string): 'ascend' | 'descend' | null | undefined => {

    if (itemTitle === props.searchDto.sortField) {
      return props.searchDto.sortOrder;
    }

    return null;
  };

  // 注文番号
  const orderNoTitle = <FormattedMessage id='orderDetail.orderNo' />;
  // 品番
  const itemTitle = <FormattedMessage id='orderDetail.item' />;
  // 品名
  const itemNameTitle = <FormattedMessage id='orderDetail.itemName' />;
  // 注文年月日
  const orderDateTitle = <FormattedMessage id='orderDetail.orderDate' />;
  // 受注者名称
  const buyerNameTitle = <FormattedMessage id='orderDetail.buyerName' />;
  // 受注者コード
  const supplierTitle = <FormattedMessage id='orderDetail.supplier' />;
  // 受注者名称
  const supplierNameTitle = <FormattedMessage id='orderDetail.supplierName' />;
  // 単価
  const unitPriceTitle = <FormattedMessage id='orderDetail.unitPrice' />;
  // 単位
  const unitTitle = <FormattedMessage id='orderDetail.unit' />;
  // 数量
  const quantityTitle = <FormattedMessage id='orderDetail.quantity' />;
  // 金額
  const amountTitle = <FormattedMessage id='orderDetail.amount' />;
  // 購買担当
  const purchasingManagerTitle = <FormattedMessage id='orderDetail.purchasingManager' />;
  // 納期
  const iniDeliveryDateTitle = <FormattedMessage id='orderDetail.iniDeliveryDate' />;
  // 再日程日
  const productionPlanDateTitle = <FormattedMessage id='orderDetail.productionPlanDate' />;
  // 保管場所
  const locationCdTitle = <FormattedMessage id='orderDetail.locationCd' />;
  // 情種類
  const infoTypeNameTitle = <FormattedMessage id='orderDetail.infoType' />;
  // 入庫数量
  const acceptanceQuantityTitle = <FormattedMessage id='orderDetail.acceptanceQuantity' />;
  // 出荷数量
  const deliveryQuantityTitle = <FormattedMessage id='orderDetail.deliveryQuantity' />;
  // 注文残数量
  const balanceQuantityTitle = <FormattedMessage id='orderDetail.balanceQuantity' />;
  // 注文残金額
  const balanceAmountTitle = <FormattedMessage id='orderDetail.balanceAmount' />;
  // 通貨
  const currencyCodeTitle = <FormattedMessage id='orderDetail.currencyCode' />;
  // 納入完了フラグ
  const orderFinishFlagTitle = <FormattedMessage id='orderDetail.orderFinishFlag' />;
  // 備考
  const remarkTitle = <FormattedMessage id='orderDetail.remark' />;

  type filterType = Filter<OrderListRecordDto>;

  // 注文番号 フィルタ
  const orderNoFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <InputFilter value={props.searchDto.orderNo} onChange={filterInput('orderNo')} />,
    filteredValue: !props.searchDto.orderNo ? [] : [props.searchDto.orderNo],
  };
  // 注文日 フィルタ
  const orderDateFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <RangeFilter values={props.searchDto.orderDate} onChange={filterRanger('orderDate')} />,
    filteredValue: filterUtil.range2stringArray(props.searchDto.orderDate),
  };
  // 受注者コード フィルタ
  const suppliersFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <SelectFilter fields={fullSupplierOptions} values={props.searchDto.suppliers} onChange={filterSelect('suppliers')} />,
    filteredValue: props.searchDto.suppliers,
  };
  // 受注者名称 フィルタ
  const supplierNameFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <InputFilter value={props.searchDto.supplierName} onChange={filterInput('supplierName')} />,
    filteredValue: !props.searchDto.supplierName ? [] : [props.searchDto.supplierName],
  };
  // 品目コード フィルタ
  const itemsFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <SelectFilter fields={itemOptions} values={props.searchDto.items} onChange={filterSelect('items')} />,
    filteredValue: props.searchDto.items,
  };
  // 品目名 フィルタ
  const itemNameFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <InputFilter value={props.searchDto.itemName} onChange={filterInput('itemName')} />,
    filteredValue: !props.searchDto.itemName ? [] : [props.searchDto.itemName],
  };
  // 納入予定日 フィルタ
  const iniDeliveryDateFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <RangeFilter values={props.searchDto.iniDeliveryDate} onChange={filterRanger('iniDeliveryDate')} />,
    filteredValue: filterUtil.range2stringArray(props.searchDto.iniDeliveryDate),
  };
  // 再日程日 フィルタ
  const productionPlanDateFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <RangeFilter values={props.searchDto.productionPlanDate} onChange={filterRanger('productionPlanDate')} />,
    filteredValue: filterUtil.range2stringArray(props.searchDto.productionPlanDate),
  };
  // 購買担当 フィルタ
  const purchasingManagerFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <InputFilter value={props.searchDto.purchasingManagerName} onChange={filterInput('purchasingManagerName')} />,
    filteredValue: !props.searchDto.purchasingManagerName ? [] : [props.searchDto.purchasingManagerName],
  };
  // 保管場所 フィルタ
  const locationCdFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filteredValue: !props.searchDto.locationCd ? [] : [props.searchDto.locationCd],
    filterDropdown: <InputFilter value={props.searchDto.locationCd} onChange={filterInput('locationCd')} />,
  };
  // 情種類 フィルタ
  const infoTypeFilter: filterType = {
    filters: filterUtil.field2Fileter(infoTypeOptions), filterMultiple: false,
    filteredValue: !props.searchDto.infoType ? [] : [props.searchDto.infoType],
    onFilter: (value: any, record: OrderListRecordDto) => record.infoType === value
  };
  // 単価 フィルタ
  const unitPriceFilter: filterType = {
    filterIcon: <FunnelPlotFilled />,
    filterDropdown: <NumberRangeFilter values={props.searchDto.unitPrice} onChange={filterNumberRanger('unitPrice')} />,
    filteredValue: filterUtil.numberRange2stringArray(props.searchDto.unitPrice),
    filtered: numberRangeFilterItemHighLight(props.searchDto.unitPrice),
  }
  // 注文金額 フィルタ
  const amountFilter: filterType = {
    filterIcon: <FunnelPlotFilled />,
    filterDropdown: <NumberRangeFilter values={props.searchDto.amount} onChange={filterNumberRanger('amount')} />,
    filteredValue: filterUtil.numberRange2stringArray(props.searchDto.amount),
    filtered: numberRangeFilterItemHighLight(props.searchDto.amount),
  }
  // 注文残金額 フィルタ
  const balanceAmountFilter: filterType = {
    filterIcon: <FunnelPlotFilled />,
    filterDropdown: <NumberRangeFilter values={props.searchDto.balanceAmount} onChange={filterNumberRanger('balanceAmount')} />,
    filteredValue: filterUtil.numberRange2stringArray(props.searchDto.balanceAmount),
    filtered: numberRangeFilterItemHighLight(props.searchDto.balanceAmount),
  }

  const orderFinishFlagFilter: filterType = {
    filters: filterUtil.field2Fileter(orderFinishFlagOptions), filterMultiple: false,
    filteredValue: !props.searchDto.finishFlag ? [] : [props.searchDto.finishFlag],
  };

  const orderNoLink = (text: any, record: OrderListRecordDto, index: number) => {
    return <Link to={`/orderresponses/${record.id}`}>{displayUtil.concatOrderNo(record.orderNo, record.detailNo)}</Link>;
  };
  //金額フォーマット
  const showUnitPrice = (text: any, record: OrderListRecordDto, index?: number) => {
    return displayUtil.currency(record.currencyCode, record.unitPrice)
  };
  //注文残数
  const showBalanceQuantity = (record: OrderListRecordDto, text?: any, index?: number): number => {
    if(record) {
      const quantity1: number = (record.quantity === undefined ? 0 : record.quantity)
      const deliveryQuantity: number = record.deliveryQuantity === undefined ? 0 : record.deliveryQuantity
      return quantity1 - deliveryQuantity;
    } else {
      return 0
    }
  };
  //注文残額
  const showBalanceAmount = (text: any, record: OrderListRecordDto, index?: number) => {
    if(record) {
      const quantity: number = (record.quantity === undefined ? 0 : record.quantity);
      const deliveryQuantity: number = record.deliveryQuantity === undefined ? 0 : record.deliveryQuantity
      const unitPrice: number = (record.unitPrice === undefined ? 0 : record.unitPrice);
      const balanceAmount = displayUtil.amount(record?.currencyCode, quantity, unitPrice) - displayUtil.amount(record?.currencyCode, deliveryQuantity, unitPrice);
      return displayUtil.currency(record?.currencyCode, balanceAmount);
    } else {
      return ''
    }
  };

  const showAmount = (text: any, record: OrderListRecordDto, index?: number) => {
    return displayUtil.currency(record.currencyCode, record.amount)
  };
  const showRemark = (text: any, record: OrderListRecordDto, index?: number) => {
    var checkflag = displayUtil.field(qualityCheckFlagOptions)(record.qualityCheckFlag);
    return checkflag + "／" + record.text
  };

  const width80 = '80px';
  const width100 = '100px';
  const width120 = '120px';
  const width140 = '140px';
  const width160 = '160px';
  const width150 = '150px';
  const width180 = '180px';
  const width200 = '200px';
  const width280 = '280px';

  const productionPlanDateItem = user?.serviceState === 'cn' ? {
    title: productionPlanDateTitle, dataIndex: 'productionPlanDate', render: displayUtil.date, width: width140,
    sorter: true, sortOrder: sortOrder('productionPlanDate'), ...productionPlanDateFilter
  } : {
    title: productionPlanDateTitle, dataIndex: 'productionPlanDate', render: () => '', width: width140,
    sorter: false, 
  };

  const array = [
    // 発注者名称
    { title: buyerNameTitle, dataIndex: 'buyerName', width: width180, ellipsis: true, },
    // 受注者コード
    {
      title: supplierTitle, dataIndex: 'supplier', width: width150, sorter: true,
      sortOrder: sortOrder('supplier'), ...suppliersFilter
    },
    // 受注者名称
    {
      title: supplierNameTitle, dataIndex: 'supplierName', width: width280, ellipsis: true,
      sorter: true, sortOrder: sortOrder('supplierName'), ...supplierNameFilter
    },
    // 注文日
    {
      title: orderDateTitle, dataIndex: 'orderDate', render: displayUtil.date, width: width140,
      sorter: true, sortOrder: sortOrder('orderDate'), ...orderDateFilter
    },
    // 品目コード
    {
      title: itemTitle, dataIndex: 'item', width: width160,
      sorter: true, sortOrder: sortOrder('item'), ...itemsFilter
    },
    // 品目名
    {
      title: itemNameTitle, dataIndex: 'itemName', width: width160, ellipsis: true,
      sorter: true, sortOrder: sortOrder('itemName'), ...itemNameFilter
    },
    // 注文番号
    {
      title: orderNoTitle, dataIndex: 'orderNo', render: orderNoLink, width: width200,
      sorter: true, sortOrder: sortOrder('orderNo'), ...orderNoFilter
    },
    // // 明細番号
    // { title: detailNoTitle, dataIndex: 'detailNo', width: width80, colSpan: 0},
    // 情種類
    {
      title: infoTypeNameTitle, dataIndex: 'infoType', render: displayUtil.field(infoTypeOptions), width: width140,
      sorter: true, sortOrder: sortOrder('infoType'), ...infoTypeFilter
    },
    // 納入予定日
    {
      title: iniDeliveryDateTitle, dataIndex: 'iniDeliveryDate', render: displayUtil.date, width: width160,
      sorter: true, sortOrder: sortOrder('iniDeliveryDate'), ...iniDeliveryDateFilter
    },
    // 再日程日
    productionPlanDateItem,
    // 注文数量　
    { title: quantityTitle, dataIndex: 'quantity', width: width100 },
    // 入庫数量　
    { title: acceptanceQuantityTitle, dataIndex: 'acceptanceQuantity', width: width100 },
    // 出荷数量
    { title: deliveryQuantityTitle, dataIndex: 'deliveryQuantity', width: width100 },
    // 注文残数
    { title: balanceQuantityTitle, dataIndex: '', width: width120, render: showBalanceQuantity },
    // 単位
    { title: unitTitle, dataIndex: 'unit', width: width80 },
    // 単価
    {
      title: unitPriceTitle,
      dataIndex: 'unitPrice',
      width: width160,
      render: showUnitPrice,
      sorter: true,
      sortOrder: sortOrder('unitPrice'),
      ...unitPriceFilter,
    },
    // 注文金額
    {
      title: amountTitle,
      dataIndex: 'amount',
      width: width160,
      render: showAmount,
      sorter: true,
      sortOrder: sortOrder('amount'),
      ...amountFilter,
    },
    // 注文残金額
    {
      title: balanceAmountTitle,
      dataIndex: 'balanceAmount', // ソートアイコンハイライト用
      width: width160,
      render: showBalanceAmount,
      ...balanceAmountFilter,
    },
    // 通貨
    { title: currencyCodeTitle, dataIndex: 'currencyCode', width: width100 },
    // 購買担当
    {
      title: purchasingManagerTitle, dataIndex: 'purchasingManagerName', width: width180, ellipsis: true,
      sorter: true, sortOrder: sortOrder('purchasingManagerName'), ...purchasingManagerFilter
    },
    // 保管場所
    {
      title: locationCdTitle, dataIndex: 'locationCd', width: width180, ellipsis: true,
      sorter: true, sortOrder: sortOrder('locationCd'), ...locationCdFilter
    },
    // 備考
    { title: remarkTitle, render: showRemark, width: width140, ellipsis: true },
    // 納入完了フラグ
    {
      title: orderFinishFlagTitle, dataIndex: 'finishFlag', render: displayUtil.field(orderFinishFlagOptions), width: width180, ellipsis: true, 
      ...orderFinishFlagFilter
    },
  ];

  // const columns: ColumnsType<OrderListRecordDto> = user?.isSupplier ? array.filter(item => item.dataIndex !== 'supplier' && item.dataIndex !== 'supplierName') : array.filter(item => item.dataIndex !== 'byuer' && item.dataIndex !== 'buyerName');
  const columns: ColumnsType<OrderListRecordDto> = user?.isSupplier ? array : array.filter(item => item.dataIndex !== 'byuer' && item.dataIndex !== 'buyerName');
  const expandedRowRender = (record: OrderListRecordDto, index: any, indent: any, expanded: any) => {

    let deliveryInfoCols = [];
    for (let deliveryInfo of record.deliveryDetails) {

      deliveryInfoCols.push(
        <Col span={5} style={{ textAlign: 'right', fontWeight: 'bold', }}>
          <FormattedMessage id='orderResponse.detailNo' />
        </Col>);
      deliveryInfoCols.push(
        <Col span={1} style={{ textDecorationLine: 'underline' }}>
          {deliveryInfo.detailNo}
        </Col>);
      deliveryInfoCols.push(
        <Col span={4} style={{ textAlign: 'right', fontWeight: 'bold', }}>
          <FormattedMessage id='orderResponse.estimatedTimeArriva' />
        </Col>);
      deliveryInfoCols.push(
        <Col span={6} style={{ textDecorationLine: 'underline' }}>
          {displayUtil.date(deliveryInfo.estimatedTimeArriva)}
        </Col>);
      deliveryInfoCols.push(
        <Col span={3} style={{ textAlign: 'right', fontWeight: 'bold', }}>
          <FormattedMessage id='orderResponse.quantity' />
        </Col>);
      deliveryInfoCols.push(
        <Col span={5} style={{ textDecorationLine: 'underline' }}>
          {deliveryInfo.quantity}
        </Col>);
    }

    let orderResponseCols = [];

    for (let orderResponse of record.orderResponses) {

      orderResponseCols.push(
        <Col span={3} style={{ textAlign: 'right', fontWeight: 'bold', }}>
          <FormattedMessage id='orderResponse.detailNo' />
        </Col>);
      orderResponseCols.push(
        <Col span={3} style={{ textDecorationLine: 'underline' }}>
          {orderResponse.detailNo}
        </Col>);
      orderResponseCols.push(
        <Col span={4} style={{ textAlign: 'right', fontWeight: 'bold', }}>
          <FormattedMessage id='orderResponse.deliveryPlanDate' />
        </Col>);
      orderResponseCols.push(
        <Col span={4} style={{ textDecorationLine: 'underline' }}>
          {displayUtil.date(orderResponse.deliveryPlanDate)}
        </Col>);
      orderResponseCols.push(
        <Col span={2} style={{ textAlign: 'right', fontWeight: 'bold', }}>
          <FormattedMessage id='orderResponse.quantity' />
        </Col>);
      orderResponseCols.push(
        <Col span={3} style={{ textDecorationLine: 'underline' }}>
          {orderResponse.quantity}
        </Col>);
      orderResponseCols.push(
        <Col span={4} style={{ color: 'red', fontWeight: 'bold' }}>
          {orderResponse.message}
        </Col>);
    }

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={5}>
            <Card title={<FormattedMessage id='orderResponse.deliveryInfo' />}>
              <Row gutter={[16, 8]}>
                {deliveryInfoCols}
              </Row>
            </Card>
          </Col>
          <Col span={6}>
            {/* <Card title={record.deliveryFlag === '2'  ? <FormattedMessage id='orderResponse.instruction' /> : <FormattedMessage id='orderResponse' />}> */}
            <Card title={<FormattedMessage id='orderResponse' />}>
              <Row gutter={[16, 8]}>
                {orderResponseCols}
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    );
  }

  const rowExpandable = (record: OrderListRecordDto) => {
    return record.orderResponses.length > 0 || record.deliveryDetails.length > 0;
  };

  const expandableProps: ExpandableConfig<OrderListRecordDto> = {
    defaultExpandAllRows: false,
    expandedRowRender: expandedRowRender,
    rowExpandable: rowExpandable,
    columnWidth: '40px',
  };


  const paginationProps: TablePaginationConfig = {
    current: props.searchDto.page,
    total: props.total,
    defaultPageSize: props.searchDto.rowCount,
    position: ['bottomLeft'],
    showSizeChanger: true,
    pageSizeOptions: constPageSizeOptions,
  };

  const title = () => (<>
    <FormattedMessage id='orderResponse.list' />
    <Button onClick={props.openSearchModal} style={{ marginLeft: 10, marginRight: 10 }} >
      <SearchOutlined />
      <FormattedMessage id='common.conditions' />
    </Button>

    <Button onClick={props.download} style={{ marginLeft: 10, marginRight: 10 }} >
      <DownloadOutlined />
      <FormattedMessage id='common.download' />
    </Button>
    {user?.serviceState === 'cn' &&
      <>
        <Button style={{ marginLeft: 10, marginRight: 10 }} onClick={props.downloadInstrucstion}>
          <DownloadOutlined />
          <FormattedMessage id='orderResponse.downloadDevInstrucstionInfos' />
        </Button>
        {!user?.isSupplier &&
          <Button onClick={props.openUpload('uploadDevInstrucstions')} style={{ marginLeft: 10, marginRight: 10 }} >
            <UploadOutlined />
            <FormattedMessage id='orderResponse.uploadDeliveryInstruction' />
          </Button>
        }
      </>
    }
    {/* {user?.isSupplier && */}
    <Button onClick={props.openUpload('uploadorderresponses')} style={{ marginLeft: 10, marginRight: 10 }} >
      <UploadOutlined />
      <FormattedMessage id='orderResponse.uploadOrderResponses' />
    </Button>
    {/* } */}
    {/* {user?.isSupplier && */}
    <Button onClick={props.openUpload('uploaddeliveryinfos')} style={{ marginLeft: 10, marginRight: 10 }} >
      <UploadOutlined />
      <FormattedMessage id='orderResponse.uploadDeliveryInfos' />
    </Button>
    {/* } */}
    <Button className='reset-btn' onClick={() => { props.reset(rowCount); }}>
      <RollbackOutlined />
      <FormattedMessage id='common.reset' />
    </Button>
    <Popconfirm
      title={intl.formatMessage({ id: 'message.readAllConfirm' })}
      onConfirm={() => { props.readAll(); }}
      okText={intl.formatMessage({ id: 'common.confirm' })}
      cancelText={intl.formatMessage({ id: 'common.cancel2' })} >
      <Button className='reset-btn'>
        <RollbackOutlined />
        <FormattedMessage id='common.readAll' />
      </Button>
    </Popconfirm>
  </>)

  return (
    <Table<OrderListRecordDto>
      className="list-table"
      pagination={paginationProps}
      expandable={expandableProps}
      columns={columns}
      dataSource={props.data}
      loading={props.loading}
      rowClassName={displayUtil.rowClassName}
      rowKey={'id'}
      tableLayout={'fixed'}
      title={title}
      onChange={handleTableChange}
      scroll={constListScroll}
    />
  );
}
