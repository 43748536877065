import { Form, Input, InputRef } from "antd";
import { InventoryDetailDto } from "./api";
import { useContext, useRef } from "react";
import { FormInstance, Rule } from "antd/lib/form";
import React from "react";
import { EditableContext } from "./SupplierInventoryDetail";

interface EditableCellProps {
  title: string,
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof InventoryDetailDto;
  dataIndexs: [keyof InventoryDetailDto];
  record: InventoryDetailDto;
  inputType: "input" | 'number';
  editableCellInputRules: (form: FormInstance<any>) => Rule[] | undefined,

  recordDataRef: React.MutableRefObject<InventoryDetailDto | undefined> | undefined,
  editingCellRowRef: React.MutableRefObject<React.Key | undefined> | undefined,
  editingCellColRef: React.MutableRefObject<React.Key | undefined> | undefined,

  nextEditingCellRowRef: React.MutableRefObject<React.Key | undefined> | undefined,
  nextEditingCellColRef: React.MutableRefObject<React.Key | undefined> | undefined,
  nextRecordDataRef: React.MutableRefObject<InventoryDetailDto | undefined> | undefined,

  validationFailed: React.MutableRefObject<boolean> | undefined,

  handleRefresh: () => void,
}

export const EditableCell: React.FC<EditableCellProps> = React.memo(
  ({
    title,
    editable,
    children,
    dataIndex,
    dataIndexs,
    record,
    inputType,
    editableCellInputRules,

    recordDataRef,
    editingCellRowRef,
    editingCellColRef,

    nextRecordDataRef,
    nextEditingCellRowRef,
    nextEditingCellColRef,

    validationFailed,

    handleRefresh,

    ...restProps
  }) => {
    const editingRef = useRef<boolean>(false)
    const inputRef = useRef<InputRef>(null); // Ant-design provided the "InputRef" type for Input components after v4.19.0, but now the project is using v4.13.0, So we can only use 'Input' type
    const form = useContext(EditableContext)!;
    const rules = editableCellInputRules ? editableCellInputRules(form) : []

    const startEdit = (editingCellRow: React.Key | undefined, editingCellCol: React.Key | undefined) => {
      if (record && record.id === editingCellRow) {
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        editingRef.current = true
        if (dataIndex === editingCellCol) {
          // 定义一个函数来检查 inputRef.current 是否存在并聚焦
          const checkAndFocus = () => {
            if (inputRef.current && form && rules) {
              // 如果存在，则聚焦
              inputRef.current.focus();
              // 执行表单验证
              form.validateFields().then(() => {
                validationFailed && (validationFailed.current = false)
              }).catch(() => {
                validationFailed && (validationFailed.current = true)
              })
              // 清除定时器
              clearInterval(intervalId);
            }
          }
          // 设置一个定时器，定期检查 inputRef.current
          const intervalId = setInterval(checkAndFocus, 30); // 每30毫秒检查一次
        }
      }
    };

    startEdit(editingCellRowRef?.current, editingCellColRef?.current)

    const updateNextEditCell = () => {
      if (!(validationFailed?.current) && (record.id !== nextEditingCellRowRef?.current || dataIndex !== nextEditingCellColRef?.current)) {
        nextEditingCellRowRef && (nextEditingCellRowRef.current = record.id)
        nextEditingCellColRef && (nextEditingCellColRef.current = dataIndex)
        handleRefresh()
      }
    }

    const updateNextRecordData = () => {
      form.validateFields().then(() => {
        validationFailed && (validationFailed.current = false)
        nextRecordDataRef && (nextRecordDataRef.current = {...record, ...form.getFieldsValue()})
      }).catch(() => {
        validationFailed && (validationFailed.current = true)
      })
    }

    const onBlur = () => {
      form.validateFields().then(() => {
        nextEditingCellRowRef && (nextEditingCellRowRef.current = undefined)
        nextEditingCellColRef && (nextEditingCellColRef.current = undefined)
        validationFailed && (validationFailed.current = false)
        handleRefresh()
      }).catch(() => {
        validationFailed && (validationFailed.current = true)
      })
    }
    
    let childNode = children;

    if (editable) {
      childNode = editingRef.current ? (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={rules}
        >
          <Input 
            type={inputType || 'input'}
            ref={inputRef}
            onMouseDown={updateNextEditCell}
            onChange={updateNextRecordData}
            onBlur={onBlur}
            onPressEnter={onBlur}
          />
        </Form.Item>
      ) : (
        <div className="inventory-detail-editable-cell-value-wrap" style={{ paddingRight: 24 }} onMouseDown={updateNextEditCell}>
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  }
);

interface EditableRowProps {
  index: number;
}

export const EditableRow: React.FC<EditableRowProps> = React.memo(
  ({ index, ...props }) => {
      const [form] = Form.useForm<InventoryDetailDto>();
      return (
        <Form form={form} component={false}>
          <EditableContext.Provider value={form}>
            <tr {...props} />
          </EditableContext.Provider>
        </Form>
      );
  }
);