import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "antd";
import { NumberRange } from "./types";
import { NumberRangePicker } from "./NumberRangePicker";



interface Props {
  values: NumberRange;
  onChange: (values: NumberRange) => void;
}

export function NumberRangeFilter(props: Readonly<Props>) {

  const [value, setValue] = useState(props.values);

  useEffect(() => {
    setValue(props.values);
  }, [props.values]);

  const onSearch = () => {
    if (checkAndSwitchMaxinum(value)) {
      props.onChange([value?.[1], value?.[0]]);
    } else {
      props.onChange(value)
    }
  };

  // 如果用户同时输入了最大值和最小值，且输入的数字区间的第一个元素（最小值）比第二个元素（最大值）大，返回 true
  const checkAndSwitchMaxinum = (range: NumberRange): boolean => {
    if (range && (range[0] !== undefined && range[0] !== null) && (range[1] !== undefined && range[1] !== null)) {
      if (range[0] > range[1]) {
        return true
      }
    }
    return false
  }

  const onReset = () => {
    setValue([null, null]);
  };


  return (
    <div className='filter'>
      <NumberRangePicker values={value} onChange={record => {setValue(record)}} style={{width: '250px'}}/>
      <div className='filterRow'>
        <Button type='primary' onClick={onSearch}><FormattedMessage id='common.conditions' /></Button>
        <Button className='reset-btn' onClick={onReset}><FormattedMessage id='common.reset' /></Button>
      </div>
    </div>
  );
}
