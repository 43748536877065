import React, { useEffect, useState } from 'react';
import { FormattedMessage, } from 'react-intl';
import { Col, Divider, Drawer, Row, } from 'antd';

import { DetailSmall, } from '../common'
import { DetailRecordDto, merge, OrderListRecordDto, removeById, getDeliveryInfoHistoryById, DeliveryInfoHistoryDto, getOrderResponseHistoryById, OrderResponseHistoryDto, } from './api';
import './index.css';
import { displayUtil } from '../util';
import { useFields } from '../fields';
import { useIntl } from 'react-intl';

import * as XLSX from 'xlsx';
import dayjs from 'dayjs';

import { OrderResponseDetailDevInstrucstion, Props as DevInstrucstionProps } from './OrderResponseDetailDevInstrucstion';
import { OrderResponseDetailOrderResponse, Props as OrderResponseProps } from './OrderResponseDetailOrderResponse';
import { OrderResponseDetailDeliveryInfo, Props as DeliveryInfoProps } from './OrderResponseDetailDeliveryInfo';
import { OrderResponseDetailOrderResponseHistory, Props as OrderResponseHistoryProps } from './OrderResponseDetailOrderResponseHistory';
import { OrderResponseDetailDeliveryInfoHistory, Props as DeliveryInfoHistoryProps } from './OrderResponseDetailDeliveryInfoHistory';
import { useUser } from '../authorization';

export interface Props {
  visible: boolean;
  detail?: OrderListRecordDto;
  close: () => void;
  refresh: () => void;
}

export function OrderResponseDetail(props: Props) {

  // 共通のstates
  const user = useUser();
  const locationCdOptions = useFields('locationName');
  const infoTypeOptions = useFields('infoType');
  const qualityCheckFlagOptions = useFields('qualityCheckFlag');
  const deliiveryFlagOptions = useFields('deliiveryFlag');
  const orderDetailHistoryStatus = useFields('orderDetailHistoryStatus')

  // 共通のstates
  const [editingDetail, setEditingDetail] = useState<DetailRecordDto | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const [devInstrucstionGap, setDevInstrucstionGap] = useState<number>(0);
  const [orderResponseGap, setOrderResponseGap] = useState<number>(0);
  const [deliveryInfoGap, setDeliveryInfoGap] = useState<number>(0);

  const [orderResponseHistoryDisplay, setOrderResponseHistoryDisplay] = useState<boolean>(false);
  const [deliveryInfoHistoryDisplay, setDeliveryInfoHistoryDisplay] = useState<boolean>(false);

  // 共通のツール
  const intl = useIntl();

  useEffect(
    () => {
      setEditingDetail(undefined);
      if (!!props.detail && !!props.detail.quantity) {
        setDevInstrucstionGap(props.detail.quantity - props.detail.devInstrucstions.reduce((current, next) => (current + (!!next.instrucstionQuantity ? next.instrucstionQuantity : 0)), 0));
        setOrderResponseGap(props.detail.quantity - props.detail.orderResponses.reduce((current, next) => (current + (!!next.quantity ? next.quantity : 0)), 0));
        setDeliveryInfoGap(props.detail.quantity - props.detail.deliveryDetails.reduce((current, next) => (current + (!!next.quantity ? next.quantity : 0)), 0));
      }
    }, [props.detail]
  );

  // 保存処理
  const save = (record: DetailRecordDto) => {
    setLoading(true);

    if (!!props.detail) {
      merge(props.detail.id, record)
        .then(
          () => {
            props.refresh();
            setLoading(false);
          })
        .catch(
          (errInfo) => {
            console.log('Delete Failed:', errInfo);
            setLoading(false);
          }
        );
    } else {
      setLoading(false);
    }
  };

  // 削除処理
  const remove = (record: DetailRecordDto) => {
    setLoading(true);

    if (!!props.detail) {
      removeById(props.detail.id, record)
        .then(
          () => {
            props.refresh();
            setLoading(false);
          })
        .catch(
          (errInfo) => {
            console.log('Delete Failed:', errInfo);
            setLoading(false);
          }
        );
    } else {
      setLoading(false);
    }
  };

  // OrderResponseDetailDeliveryInfoHistory ダウンロード
  const OrderResponseDetailDeliveryInfoHistoryDownload = () => {
    if (!!props.detail?.id && !!props.detail) {
      setLoading(true);

      getDeliveryInfoHistoryById(props.detail.id)
        .then((record: DeliveryInfoHistoryDto[]) => {
          setLoading(false);

          // download here
          let excelData = [];

          //注文明細title
          excelData.push([intl.formatMessage({ id: 'orderResponse.detail' })]);

          // buyer 行
          excelData.push([
            intl.formatMessage({ id: 'orderDetail.buyer' }),
            props.detail?.buyer,

            intl.formatMessage({ id: 'orderDetail.buyerName' }),
            props.detail?.buyerName,
          ]);

          // supplier 行
          excelData.push([
            intl.formatMessage({ id: 'orderDetail.supplier' }),
            props.detail?.supplier,

            intl.formatMessage({ id: 'orderDetail.supplierName' }),
            props.detail?.supplierName,
          ])

          // 注文 行
          excelData.push([
            intl.formatMessage({ id: 'orderDetail.orderNo' }),
            props.detail?.orderNo,

            intl.formatMessage({ id: 'orderDetail.detailNo' }),
            props.detail?.detailNo,
          ])

          // 注文 行
          excelData.push([
            intl.formatMessage({ id: 'orderDetail.itemName' }),
            props.detail?.itemName,
          ])


          // 表头行
          excelData.push([
            intl.formatMessage({ id: 'common.stateCode' }),
            intl.formatMessage({ id: 'orderResponse.detailNo' }),
            intl.formatMessage({ id: 'orderResponse.preShipDate' }),
            intl.formatMessage({ id: 'orderResponse.preEstimatedTimeArriva' }),
            intl.formatMessage({ id: 'orderResponse.preQuantity' }),
            intl.formatMessage({ id: 'orderResponse.preRemarks' }),
            intl.formatMessage({ id: 'orderResponse.currShipDate' }),
            intl.formatMessage({ id: 'orderResponse.currEstimatedTimeArriva' }),
            intl.formatMessage({ id: 'orderResponse.currQuantity' }),
            intl.formatMessage({ id: 'orderResponse.currRemarks' }),
            intl.formatMessage({ id: 'common.userName' }),
            intl.formatMessage({ id: 'common.userModifiedDate' }),
          ])
          // 表格体
          record.forEach(vo => [
            excelData.push([
              displayUtil.field(orderDetailHistoryStatus)(vo.stateCode),
              vo.detailNo,
              displayUtil.date(vo.preShipDate),
              displayUtil.date(vo.preEstimatedTimeArriva),
              vo.preQuantity,
              vo.preRemarks,
              displayUtil.date(vo.shipDate),
              displayUtil.date(vo.estimatedTimeArriva),
              vo.quantity,
              vo.remarks,
              vo.userName,
              displayUtil.date(vo.userModifiedDate)
            ])
          ])

          const worksheet = XLSX.utils.aoa_to_sheet(excelData);
          const workbook = XLSX.utils.book_new();

          XLSX.utils.book_append_sheet(workbook, worksheet, intl.formatMessage({ id: 'inventory.detail' }));
          XLSX.writeFile(workbook, `${intl.formatMessage({ id: 'orderResponse.deliveryInfoList' })}${intl.formatMessage({ id: 'common.hisory' })}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
        }).catch(() => {
          setLoading(false);
        });
    }
  }

  // OrderResponseDetailOrderResponseHistory ダウンロード
  const OrderResponseDetailOrderResponseHistoryDownload = () => {
    if (!!props.detail?.id && !!props.detail) {
      setLoading(true);

      getOrderResponseHistoryById(props.detail.id)
        .then((record: OrderResponseHistoryDto[]) => {

          setLoading(false);

          // download here
          let excelData = [];

          //注文明細title
          excelData.push([intl.formatMessage({ id: 'orderResponse.detail' })]);

          // buyer 行
          excelData.push([
            intl.formatMessage({ id: 'orderDetail.buyer' }),
            props.detail?.buyer,

            intl.formatMessage({ id: 'orderDetail.buyerName' }),
            props.detail?.buyerName,
          ]);

          // supplier 行
          excelData.push([
            intl.formatMessage({ id: 'orderDetail.supplier' }),
            props.detail?.supplier,

            intl.formatMessage({ id: 'orderDetail.supplierName' }),
            props.detail?.supplierName,
          ])

          // 注文 行
          excelData.push([
            intl.formatMessage({ id: 'orderDetail.orderNo' }),
            props.detail?.orderNo,

            intl.formatMessage({ id: 'orderDetail.detailNo' }),
            props.detail?.detailNo,
          ])

          // 注文 行
          excelData.push([
            intl.formatMessage({ id: 'orderDetail.itemName' }),
            props.detail?.itemName,
          ])


          // 表头行
          excelData.push([
            intl.formatMessage({ id: 'common.stateCode' }),
            intl.formatMessage({ id: 'orderResponse.detailNo' }),
            intl.formatMessage({ id: 'orderResponse.preDeliveryPlanDate' }),
            intl.formatMessage({ id: 'orderResponse.preQuantity' }),
            intl.formatMessage({ id: 'orderResponse.currDeliveryPlanDate' }),
            intl.formatMessage({ id: 'orderResponse.currQuantity' }),
            intl.formatMessage({ id: 'common.userName' }),
            intl.formatMessage({ id: 'common.userModifiedDate' }),
          ])
          // 表格体
          record.forEach(vo => [
            excelData.push([
              displayUtil.field(orderDetailHistoryStatus)(vo.stateCode),
              vo.detailNo,
              displayUtil.date(vo.preDeliveryPlanDate),
              vo.preQuantity,
              displayUtil.date(vo.deliveryPlanDate),
              vo.quantity,
              vo.userName,
              displayUtil.date(vo.userModifiedDate)
            ])
          ])

          const worksheet = XLSX.utils.aoa_to_sheet(excelData);
          const workbook = XLSX.utils.book_new();

          XLSX.utils.book_append_sheet(workbook, worksheet, intl.formatMessage({ id: 'inventory.detail' }));
          XLSX.writeFile(workbook, `${intl.formatMessage({ id: 'orderResponse.orderResponseList' })}${intl.formatMessage({ id: 'common.hisory' })}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
        }).catch(() => {
          setLoading(false);
        });
    }
  }

  //注文残数
  const showBalanceQuantity = (record?: OrderListRecordDto) => {
    if(record) {
      const deliveryCount = getDeliveryCount(record)
      const quantity = record.quantity
      if(quantity) {
        if(deliveryCount) {
          return reduceFloats(quantity, deliveryCount) 
        } else {
          return quantity
        }
      } else {
        return 0
      }
    } else {
      return 0
    }
  };
  //注文残額
  const showBalanceAmount = (record?: OrderListRecordDto) => {
    const balanceQuantity: number = showBalanceQuantity(record)
    var unitPrice1: number = (record?.unitPrice === undefined ? 0 : record?.unitPrice);

    const balanceAmount = displayUtil.amount(record?.currencyCode, balanceQuantity, unitPrice1);
    return displayUtil.currency(record?.currencyCode, balanceAmount);
  };
  //備考
  const showRemark = (record?: OrderListRecordDto) => {
    var checkflag = (record?.qualityCheckFlag === undefined ? "" : displayUtil.field(qualityCheckFlagOptions)(record.qualityCheckFlag));
    return checkflag + "／" + (record?.qualityCheckFlag === undefined ? "" : record.text)
  };

  // 出荷件数
  const getDeliveryCount = (record: OrderListRecordDto): number => {
    if (record && record.deliveryDetails) {
      const deliveryCount = record.deliveryDetails.reduce((accumulator, currentValue) => {
        if (currentValue.quantity) {
          return addFloats(accumulator, currentValue.quantity)
        } else {
          return accumulator
        }
      }, 0)
      if (deliveryCount) {
        return deliveryCount
      } else {
        return 0
      }
    } else {
      return 0
    }
  }

  /**
   * 不丢失精度的浮点数加法
   * 请注意，输入的数字的整数位与小数位合计长度，或者中期计算结果的数字位长度过长时仍然会丢失精度 (超过js安全整数长度)
   * 中期结果的意思是： 1.1 和 2.22 的中期计算结果为：1.1 * 100 + 2.22 * 100 = 110 + 222 = 332
   * @param a 加数 1
   * @param b 加数 2
   * @returns 加法计算结果
   */
  function addFloats(a: number, b: number) {
    function getDecimalLength(number: number) {
      const parts = number.toString().split('.');
      return parts.length > 1 ? parts[1].length : 0;
    }
    function multiply(a: number, b: number) {
      return a * b;
    }
    const factor = Math.pow(10, Math.max(getDecimalLength(a), getDecimalLength(b)));
    return (multiply(a, factor) + multiply(b, factor)) / factor;
  }

  /**
   * 不丢失精度的浮点数减法，计算的是 第一个参数 减 第二个参数 的结果
   * 请注意，输入的数字的整数位与小数位合计长度，或者中期计算结果的数字位长度过长时仍然会丢失精度 (超过js安全整数长度)
   * 中期结果的意思是： 1.1 和 2.22 的中期计算结果为：1.1 * 100 - 2.22 * 100 = 110 - 222 = -112
   * @param a 被减数
   * @param b 减速
   * @returns 减法计算结果
   */
  function reduceFloats(a: number, b: number) {
    function getDecimalLength(number: number) {
      const parts = number.toString().split('.');
      return parts.length > 1 ? parts[1].length : 0;
    }
    function multiply(a: number, b: number) {
      return a * b;
    }
    const factor = Math.pow(10, Math.max(getDecimalLength(a), getDecimalLength(b)));
    return (multiply(a, factor) - multiply(b, factor)) / factor;
  }

  // タイトル
  const cols: [JSX.Element, any][] = [];
  // 注文番号
  cols.push([<FormattedMessage id='orderDetail.orderNo' />, props.detail?.orderNo + '-' + props.detail?.detailNo,]);
  // 明細番号
  // cols.push([<FormattedMessage id='orderDetail.detailNo' />, props.detail?.detailNo,]);
  // 注文日
  cols.push([<FormattedMessage id='orderDetail.orderDate' />, displayUtil.date(props.detail?.orderDate),]);
  // 情種類
  cols.push([<FormattedMessage id='orderDetail.infoType' />, displayUtil.field(infoTypeOptions)(props.detail?.infoType),]);
  // 品目コード
  cols.push([<FormattedMessage id='orderDetail.item' />, props.detail?.item,]);
  // 品目名
  cols.push([<FormattedMessage id='orderDetail.itemName' />, props.detail?.itemName,]);
  // 購買担当
  cols.push([<FormattedMessage id='orderDetail.purchasingManager' />, props.detail?.purchasingManagerName,]);
  // 発注者コード
  cols.push([<FormattedMessage id='orderDetail.buyer' />, props.detail?.buyer,]);
  // 発注者名称
  cols.push([<FormattedMessage id='orderDetail.buyerName' />, props.detail?.buyerName,]);
  // 保管場所
  cols.push([<FormattedMessage id='orderDetail.locationCd' />, props.detail?.locationCd,]);
  // 受注者コード
  cols.push([<FormattedMessage id='orderDetail.supplier' />, props.detail?.supplier,]);
  // 受注者名称
  cols.push([<FormattedMessage id='orderDetail.supplierName' />, props.detail?.supplierName,]);
  // 受渡場所
  cols.push([<FormattedMessage id='delivery.locationCd' />, displayUtil.field(locationCdOptions)(props.detail?.locationName),]);
  // // 単位
  // cols.push([<FormattedMessage id='orderDetail.unit' />, props.detail?.unit,]);
  // 初期納期
  cols.push([<FormattedMessage id='orderDetail.iniDeliveryDate' />, displayUtil.date(props.detail?.iniDeliveryDate),]);
  if (user?.serviceState === 'cn') {
    // 再日程日
    cols.push([<FormattedMessage id='orderDetail.productionPlanDate' />, displayUtil.date(props.detail?.productionPlanDate),]);
  }
  // 単価
  cols.push([<FormattedMessage id='orderDetail.unitPrice' />, displayUtil.currency(props.detail?.currencyCode, props.detail?.unitPrice),]);
  // 注文数量
  cols.push([<FormattedMessage id='orderDetail.quantity' />, displayUtil.getQuantity(props.detail?.quantity, props.detail?.unit),]);
  //入庫数量
  cols.push([<FormattedMessage id='orderDetail.acceptanceQuantity' />, displayUtil.getQuantity(props.detail?.acceptanceQuantity, props.detail?.unit),]);
  //出荷数量
  cols.push([<FormattedMessage id='orderDetail.deliveryQuantity' />, displayUtil.getQuantity(props.detail?.deliveryQuantity, props.detail?.unit),]);
  // 注文金額
  cols.push([<FormattedMessage id='orderDetail.amount' />, displayUtil.currency(props.detail?.currencyCode, props.detail?.amount),]);
  //出荷残数量
  cols.push([<FormattedMessage id='orderDetail.deliveryBalanceQuantity' />, displayUtil.getQuantity(showBalanceQuantity(props.detail), props.detail?.unit),]);
  //出荷残金額
  cols.push([<FormattedMessage id='orderDetail.deliveryBalanceAmount' />, showBalanceAmount(props.detail),]);
  //通貨
  cols.push([<FormattedMessage id='orderDetail.currencyCode' />, props.detail?.currencyCode,]);
  //納品指示区分
  cols.push([<FormattedMessage id='orderDetail.deliveryFlag' />, displayUtil.field(deliiveryFlagOptions)(props.detail?.deliveryFlag),]);
  // // 品質検査
  // cols.push([<FormattedMessage id='orderDetail.qualityCheckFlag' />, displayUtil.field(props.qualityCheckFlagOptions)(props.detail?.qualityCheckFlag),]);
  // 備考
  cols.push([<FormattedMessage id='orderDetail.remark' />, showRemark(props.detail),]);

  const title = (
    <>
      <FormattedMessage id='orderResponse.detail' />
    </>
  );

  const devInstrucstionProps: DevInstrucstionProps = {
    detail: props.detail,
    editingDetail: editingDetail,
    gap: devInstrucstionGap,
    orderResponseGap: orderResponseGap,
    loading: loading,
    setEditingDetail: (tempDto) => setEditingDetail(tempDto),
    save: save,
    remove: remove,
  };

  const orderResponseProps: OrderResponseProps = {
    detail: props.detail,
    editingDetail: editingDetail,
    gap: orderResponseGap,
    deliveryInfoGap: deliveryInfoGap,
    loading: loading,
    setEditingDetail: (tempDto) => setEditingDetail(tempDto),
    save: save,
    remove: remove,
    showHistory: () => setOrderResponseHistoryDisplay(true),
  };

  const deliveryInfoProps: DeliveryInfoProps = {
    detail: props.detail,
    editingDetail: editingDetail,
    gap: deliveryInfoGap,
    loading: loading,
    setEditingDetail: (tempDto) => setEditingDetail(tempDto),
    save: save,
    remove: remove,
    showHistory: () => setDeliveryInfoHistoryDisplay(true),
  };

  const orderResponseHistoryProps: OrderResponseHistoryProps = {
    id: props.detail?.id,
    visible: orderResponseHistoryDisplay,
    close: () => setOrderResponseHistoryDisplay(false),
    download: OrderResponseDetailOrderResponseHistoryDownload
  };

  const deliveryInfoHistoryProps: DeliveryInfoHistoryProps = {
    id: props.detail?.id,
    visible: deliveryInfoHistoryDisplay,
    close: () => setDeliveryInfoHistoryDisplay(false),
    download: OrderResponseDetailDeliveryInfoHistoryDownload
  };

  return (
    <>
      <Drawer
        title={title}
        width='1380px'
        footer={null}
        visible={props.visible}
        destroyOnClose={true}
        onClose={props.close}>
        <DetailSmall cols={cols} />
        <Row gutter={[2, 0]}>
          {props.detail?.deliveryFlag === "2" &&
            <Col span={12}>
              <OrderResponseDetailDevInstrucstion {...devInstrucstionProps} />
            </Col>}
          <Col span={props.detail?.deliveryFlag === "2" ? 12 : 24}>
            <OrderResponseDetailOrderResponse {...orderResponseProps} />
          </Col>
        </Row>
        <Divider />
        <OrderResponseDetailDeliveryInfo {...deliveryInfoProps} />
      </Drawer >
      <OrderResponseDetailOrderResponseHistory {...orderResponseHistoryProps} />
      <OrderResponseDetailDeliveryInfoHistory {...deliveryInfoHistoryProps} />
    </>
  );
}
