import React, { Key } from 'react';
import { FormattedMessage, } from 'react-intl';
import { Button, Table, } from 'antd';
import { RollbackOutlined, SearchOutlined, } from '@ant-design/icons';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnsType } from 'antd/lib/table';

import { displayUtil, filterUtil, } from '../util'
import { InputFilter, RangeFilter, Filter, DateRange, constPageSizeOptions, constListScroll } from '../common'

import { NotificationSearchDto, NotificationListRecordDto, } from './api'
import { SorterResult } from 'antd/es/table/interface';
import { useNavigate } from 'react-router-dom';

export interface Props {
    loading: boolean;
    total: number;
    data: NotificationListRecordDto[];
    searchDto: NotificationSearchDto;
    openSearchModal: () => void;
    handleResearch: (researchDto: NotificationSearchDto) => void;
}

export function NotificationList(props: Props) {

    const navigate = useNavigate();

    // ページング、フィルター、ソート順の処理
    const handleTableChange = (pagination: TablePaginationConfig,
        filters: Record<string, (Key | boolean)[] | null>,
        sorter: SorterResult<NotificationListRecordDto> | SorterResult<NotificationListRecordDto>[],
        extra: any) => {

        // 再検索の条件を作成する
        const researchDto: NotificationSearchDto = { ...props.searchDto };
        let researchFlag = false;

        if (!!pagination.pageSize && props.searchDto.rowCount !== pagination.pageSize) {
            researchDto.page = 1;
            researchDto.rowCount = pagination.pageSize;
            researchFlag = true;
        }
        else if (props.searchDto.page !== pagination.current) {
            researchDto.page = pagination.current;
            researchFlag = true;
        }

        const fixSorter = sorter as SorterResult<NotificationListRecordDto>;
        if (props.searchDto.sortField !== fixSorter.field
            || props.searchDto.sortOrder !== fixSorter.order) {
            researchDto.sortField = fixSorter.field as string | undefined;
            researchDto.sortOrder = fixSorter.order;
            researchFlag = true;
        }

        if (researchFlag) {
            props.handleResearch(researchDto);
        }
    };

    const filterInput = (itemTitle: 'senderCode' | 'subject') =>
        (value?: string) => {
            if (props.searchDto[itemTitle] !== value) {
                const researchDto: NotificationSearchDto = { ...props.searchDto };
                researchDto[itemTitle] = value;
                researchDto.page = 1;
                props.handleResearch(researchDto);
            }
        };

    const filterRanger = (itemTitle: 'incomingDateAndTime') =>
        (value: DateRange) => {
            if (props.searchDto[itemTitle] !== value) {
                const researchDto: NotificationSearchDto = { ...props.searchDto };
                researchDto[itemTitle] = value;
                researchDto.page = 1;
                props.handleResearch(researchDto);
            }
        };

    const sortOrder = (itemTitle: string): 'ascend' | 'descend' | null | undefined => {

        if (itemTitle === props.searchDto.sortField) {
            return props.searchDto.sortOrder;
        }

        return null;
    };

    // タイトル
    const senderCodeTitle = <FormattedMessage id='notification.sender' />
    const subjectTitle = <FormattedMessage id='notification.subject' />
    const incomingDateAndTimeTitle = <FormattedMessage id='notification.incomingDateAndTime' />
    const textTitle = <FormattedMessage id='notification.text' />

    //filter
    type filterType = Filter<NotificationListRecordDto>;

    const senderCodeFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.senderCode} onChange={filterInput('senderCode')} />,
        filteredValue: !props.searchDto.senderCode ? [] : [props.searchDto.senderCode],
    };

    const subjectFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.subject} onChange={filterInput('subject')} />,
        filteredValue: !props.searchDto.subject ? [] : [props.searchDto.subject],
    };

    const incomingDateAndTimeFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <RangeFilter values={props.searchDto.incomingDateAndTime} onChange={filterRanger('incomingDateAndTime')} />,
        filteredValue: filterUtil.range2stringArray(props.searchDto.incomingDateAndTime),
    };

    const columns: ColumnsType<NotificationListRecordDto> = [
        {
            title: incomingDateAndTimeTitle,
            dataIndex: 'incomingDateAndTime',
            sorter: true,
            sortOrder: sortOrder('incomingDateAndTime'),
            render: displayUtil.date,
            ellipsis: true,
            width: '2em',
            align: 'center',
            ...incomingDateAndTimeFilter
        },
        {
            title: senderCodeTitle,
            dataIndex: 'senderCode',
            sorter: true,
            width: '2em',
            sortOrder: sortOrder('senderCode'),
            ...senderCodeFilter
        },
        {
            title: subjectTitle,
            dataIndex: 'subject',
            sorter: true,
            width: '2em',
            sortOrder: sortOrder('subject'),
            ...subjectFilter
        },


        {
            title: textTitle,
            dataIndex: 'text',
            width: '5em',
            sorter: true,
            sortOrder: sortOrder('text')
        }
    ];

    const paginationProps: TablePaginationConfig = {
        current: props.searchDto.page,
        total: props.total,
        defaultPageSize: props.searchDto.rowCount,
        position: ['bottomLeft'],
        showSizeChanger: true,
        pageSizeOptions: constPageSizeOptions,
    }

    const title = () => (<>
        <FormattedMessage id='notification.list' />
        <Button onClick={props.openSearchModal} style={{ marginLeft: 10, marginRight: 10 }}>
            <SearchOutlined />
            <FormattedMessage id='common.conditions' />
        </Button>
        <Button className='reset-btn' onClick={() => { navigate("/notifications") }}>
            <RollbackOutlined />
            <FormattedMessage id='common.reset' />
        </Button>
    </>)

    return (
        <Table<NotificationListRecordDto>
            className="list-table"
            pagination={paginationProps}
            columns={columns}
            dataSource={props.data}
            loading={props.loading}
            rowClassName={'notificationListRecordDto-highlight'}
            rowKey={'id'}
            tableLayout={'fixed'}
            title={title}
            onChange={handleTableChange}
            scroll={constListScroll}
        />
    );
}
