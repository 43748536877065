import { DownloadOutlined, FileExcelOutlined, PlusOutlined, RollbackOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Table, TablePaginationConfig } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import React, { Key, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { constListScroll, constPageSizeOptions, DateRange, Filter, InputFilter, RangeFilter, SelectFilter } from '../common';
import { useFields } from '../fields';
import { arrayUtil, displayUtil, filterUtil } from '../util';
import { EstimateRequestListDto, EstimateSearchDto } from './api';

export interface Props {
    loading: boolean;
    total: number;
    data: EstimateRequestListDto[];
    searchDto: EstimateSearchDto;
    openSearchModal: () => void;
    download: () => void;
    downloadPassed: () => void;
    createEstimate: () => void;
    uploadEstimate: () => void;
    handleResearch: (researchDto: EstimateSearchDto) => void;
    handleSearch: (searchDto: EstimateSearchDto) => void;
    handleSelectedRows: (selectedRows: any) => void;
    reset: (rowCount: number) => void;
}

export function EstimationList(props: Props) {

    const navigate = useNavigate();
    const [rowCount, setRowCount] = useState<number>(10);
    const buyerDepartmentOptions = useFields('buyerDepartment');
    const statusOptions = useFields('estimateStatus');
    const estimatesTypeOptions = useFields('estimatesType');
    const itemOptions = useFields('item');

    // ページング、フィルター、ソート順の処理
    const handleTableChange = (pagination: TablePaginationConfig,
        filters: Record<string, (Key | boolean)[] | null>,
        sorter: SorterResult<EstimateRequestListDto> | SorterResult<EstimateRequestListDto>[],
        extra: any) => {
        // // 再検索の条件を作成する
        const researchDto: EstimateSearchDto = { ...props.searchDto };
        let researchFlag = false;
        //案件状態の場合`
        const { status: preStatus } = researchDto;
        const { status } = filters;
        const curentStatus = status ? status as string[] : [];

        if (!!pagination.pageSize && rowCount !== pagination.pageSize) {
            setRowCount(pagination.pageSize)
        }
        if (!arrayUtil.equals(curentStatus, preStatus)) {
            researchDto.status = curentStatus;
            props.handleSearch(researchDto);
        }



        //所属部門の場合
        const { buyerDepartments: preBuyerDepartments } = researchDto;
        const { buyerDepartmentCode } = filters;
        const curentBuyerDepartments = buyerDepartmentCode ? buyerDepartmentCode as string[] : [];
        if (!arrayUtil.equals(curentBuyerDepartments, preBuyerDepartments)) {
            researchDto.buyerDepartments = curentBuyerDepartments;
            props.handleSearch(researchDto);
        }
        //見積タイプの場合
        const { estimatesType: preEstimatesType } = researchDto;
        const { estimatesType } = filters;
        const curentBstimatesType = estimatesType ? estimatesType as string[] : [];
        if (!arrayUtil.equals(curentBstimatesType, preEstimatesType)) {
            researchDto.estimatesType = curentBstimatesType;
            props.handleSearch(researchDto);
        }

        //itemの場合
        const { item: preItemType } = researchDto;
        const { item } = filters;
        const curentItemType = item ? item as string[] : [];
        if (!arrayUtil.equals(curentItemType, preItemType)) {
            researchDto.item = curentItemType;
            props.handleSearch(researchDto);
        }

        if (!!pagination.pageSize && props.searchDto.rowCount !== pagination.pageSize) {
            researchDto.page = 1;
            researchDto.rowCount = pagination.pageSize;
            researchFlag = true;
        }
        else if (props.searchDto.page !== pagination.current) {
            researchDto.page = pagination.current;
            researchFlag = true;
        }

        const fixSorter = sorter as SorterResult<EstimateRequestListDto>;
        if (props.searchDto.sortField !== fixSorter.field
            || props.searchDto.sortOrder !== fixSorter.order) {
            researchDto.sortField = fixSorter.field as string | undefined;
            researchDto.sortOrder = fixSorter.order;
            researchFlag = true;
        }

        if (researchFlag) {
            props.handleResearch(researchDto);
        }
    };

    const filterInput = (itemTitle: 'requestNo' | 'purchasingManagerName' | 'itemName') =>
        (value?: string) => {
            if (props.searchDto[itemTitle] !== value) {
                const researchDto: EstimateSearchDto = { ...props.searchDto };
                researchDto[itemTitle] = value;
                researchDto.page = 1;
                props.handleResearch(researchDto);
            }
        };

    const filterRanger = (itemTitle: 'requestDate' | 'answerDeadline') =>
        (value: DateRange) => {
            if (props.searchDto[itemTitle] !== value) {
                const researchDto: EstimateSearchDto = { ...props.searchDto };
                researchDto[itemTitle] = value;
                researchDto.page = 1;
                props.handleResearch(researchDto);
            }
        };

    const filterSelect = (itemTitle: 'item') =>
        (value: string[]) => {
            if (props.searchDto[itemTitle] !== value) {
                const researchDto: EstimateSearchDto = { ...props.searchDto };
                researchDto[itemTitle] = value;
                researchDto.page = 1;
                props.handleResearch(researchDto);
            }
        };

    const sortOrder = (itemTitle: string): 'ascend' | 'descend' | null | undefined => {

        if (itemTitle === props.searchDto.sortField) {
            return props.searchDto.sortOrder;
        }

        return null;
    };


    //filter
    type filterType = Filter<EstimateRequestListDto>;

    const statusFilter: filterType = {
        filters: filterUtil.field2Fileter(statusOptions), filterMultiple: true,
        filteredValue: props.searchDto.status,
        onFilter: (value: any, record: EstimateRequestListDto) => record.status === value
    };
    const requestNoFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.requestNo} onChange={filterInput('requestNo')} />,
        filteredValue: !props.searchDto.requestNo ? [] : [props.searchDto.requestNo],
    };
    const buyerDepartmentFilter: filterType = {
        filters: filterUtil.field2Fileter(buyerDepartmentOptions), filterMultiple: true,
        filteredValue: props.searchDto.buyerDepartments,
        onFilter: (value: any, record: EstimateRequestListDto) => record.buyerDepartmentCode === value
    };
    const estimatesTypeFilter: filterType = {
        filters: filterUtil.field2Fileter(estimatesTypeOptions),
        filteredValue: props.searchDto.estimatesType,
        onFilter: (value: any, record: EstimateRequestListDto) => record.estimatesType === value
    };
    const purchasingManagerFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.purchasingManagerName} onChange={filterInput('purchasingManagerName')} />,
        filteredValue: !props.searchDto.purchasingManagerName ? [] : [props.searchDto.purchasingManagerName],
    };
    const itemNameFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.itemName} onChange={filterInput('itemName')} />,
        filteredValue: !props.searchDto.itemName ? [] : [props.searchDto.itemName],
    };
    const itemFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <SelectFilter fields={itemOptions} values={props.searchDto.item} onChange={filterSelect('item')} />,
        filteredValue: props.searchDto.item,
    };
    const requestDateFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <RangeFilter values={props.searchDto.requestDate} onChange={filterRanger('requestDate')} />,
        filteredValue: filterUtil.range2stringArray(props.searchDto.requestDate),
    };
    const answerDeadlineFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <RangeFilter values={props.searchDto.answerDeadline} onChange={filterRanger('answerDeadline')} />,
        filteredValue: filterUtil.range2stringArray(props.searchDto.answerDeadline),
    };

    const requestNoLink = (text: any, record: EstimateRequestListDto, index: number) => {
        let random = Math.round(Math.random() * 100000);
        return <Link to={`/estimations/detail/${random}/${record.id}`}>{record.requestNo}</Link>;
    };

    // タイトル
    // 案件状態
    const statusTitle = <FormattedMessage id='estimate.status' />
    const statusWidth = '150px';
    // 依頼番号
    const requestNoTitle = <FormattedMessage id='estimate.requestNo' />
    const requestNoWidth = '150px';
    // 発注部門
    const buyerDepartmentTitle = <FormattedMessage id='estimate.buyerDepartment' />
    const buyerDepartmentWidth = '150px';
    // 見積タイプ
    const estimatesTypeTitle = <FormattedMessage id='estimate.estimatesType' />
    const estimatesTypeWidth = '160px';
    // 購買担当
    const purchasingManagerNameTitle = <FormattedMessage id='estimate.purchasingManager' />
    const purchasingManagerNameWidth = '150px';
    // 品番
    const itemTitle = <FormattedMessage id='estimate.item' />
    const itemWidth = '290px';
    // 品名
    const itemNameTitle = <FormattedMessage id='estimate.itemName' />
    const itemNameWidth = '130px';
    // 企画数量
    const planQuantityTitle = <FormattedMessage id='estimate.planQuantity' />
    const planQuantityWidth = '100px';
    // 見積依頼日
    const requestDateTitle = <FormattedMessage id='estimate.requestDate' />
    const requestDateWidth = '140px';
    // 回答希望日
    const answerDeadlineTitle = <FormattedMessage id='estimate.answerDeadline' />
    const answerDeadlineWidth = '160PX';
    // 依頼数量
    const requestCountTitle = <FormattedMessage id='estimate.requestCount' />
    const requestCountWidth = '100px';
    // 回答数量
    const responseCountTitle = <FormattedMessage id='estimate.responseCount' />
    const responseCountWidth = '100px';


    const columns: ColumnsType<EstimateRequestListDto> = [
        // 案件状態
        {
            title: statusTitle,
            dataIndex: 'status',
            render: displayUtil.field(statusOptions),
            width: statusWidth,
            sorter: true,
            sortOrder: sortOrder('status'),
            ...statusFilter

        },
        // 依頼番号
        {
            title: requestNoTitle,
            dataIndex: 'requestNo',
            width: requestNoWidth,
            render: requestNoLink,
            sorter: true,
            sortOrder: sortOrder('requestNo'),
            ...requestNoFilter
        },
        // 発注部門
        {
            title: buyerDepartmentTitle,
            dataIndex: 'buyerDepartmentCode',
            render: displayUtil.field(buyerDepartmentOptions),
            width: buyerDepartmentWidth,
            sorter: true,
            sortOrder: sortOrder('buyerDepartmentCode'),
            ...buyerDepartmentFilter
        },
        // 見積タイプ
        {
            title: estimatesTypeTitle,
            dataIndex: 'estimatesType',
            render: displayUtil.field(estimatesTypeOptions),
            width: estimatesTypeWidth,
            sorter: true,
            sortOrder: sortOrder('estimatesType'),
            ...estimatesTypeFilter
        },
        // 購買担当
        {
            title: purchasingManagerNameTitle,
            dataIndex: 'purchasingManagerName',
            width: purchasingManagerNameWidth,
            sorter: true,
            sortOrder: sortOrder('purchasingManagerName'),
            ...purchasingManagerFilter
        },
        // 品番
        {
            title: itemTitle,
            dataIndex: 'item',
            width: itemWidth,
            sorter: true,
            ellipsis: true,
            sortOrder: sortOrder('item'),
            ...itemFilter
        },
        // 品名
        {
            title: itemNameTitle,
            dataIndex: 'itemName',
            width: itemNameWidth,
            sorter: true,
            sortOrder: sortOrder('itemName'),
            ellipsis: true,
            ...itemNameFilter
        },
        // 企画数量
        {
            title: planQuantityTitle,
            dataIndex: 'planQuantity',
            width: planQuantityWidth,
        },
        // 見積依頼日
        {
            title: requestDateTitle,
            dataIndex: 'requestDate',
            width: requestDateWidth,
            sorter: true,
            sortOrder: sortOrder('requestDate'),
            render: displayUtil.date,
            ellipsis: true,
            ...requestDateFilter
        },
        // 回答希望日
        {
            title: answerDeadlineTitle,
            dataIndex: 'answerDeadline',
            width: answerDeadlineWidth,
            sorter: true,
            sortOrder: sortOrder('answerDeadline'),
            ellipsis: true,
            render: displayUtil.date,
            ...answerDeadlineFilter
        },
        // 依頼
        {
            title: requestCountTitle,
            dataIndex: 'requestCount',
            width: requestCountWidth
        },
        // 回答
        {
            title: responseCountTitle,
            dataIndex: 'responseCount',
            width: responseCountWidth
        },
    ];

    const paginationProps: TablePaginationConfig = {
        current: props.searchDto.page,
        total: props.total,
        defaultPageSize: props.searchDto.rowCount,
        position: ['bottomLeft'],
        showSizeChanger: true,
        pageSizeOptions: constPageSizeOptions,
    }

    const title = () => {
        return (
            <>
                <FormattedMessage id='estimate.list' />
                <Button onClick={props.openSearchModal} style={{ marginLeft: 10, marginRight: 10 }} >
                    <SearchOutlined />
                    <FormattedMessage id='common.conditions' />
                </Button>
                <Button onClick={props.createEstimate} style={{ marginLeft: 10, marginRight: 10 }} >
                    <PlusOutlined />
                    <FormattedMessage id='estimate.newEstimate' />
                </Button>
                {/* <Button onClick={props.uploadEstimate} style={{marginLeft : 10, marginRight:10}}>
                    <UploadOutlined />
                    <FormattedMessage id='common.upload' />
                </Button> */}
                <Button onClick={props.download} style={{ marginLeft: 10, marginRight: 10 }}>
                    <DownloadOutlined />
                    <FormattedMessage id='common.download' />
                </Button>

                <Button onClick={props.downloadPassed} style={{ marginLeft: 10, marginRight: 10 }}>
                    <DownloadOutlined />
                    <FormattedMessage id='common.downloadPassed' />
                </Button>

                <Button className='reset-btn' onClick={() => { props.reset(rowCount); }}>
                    <RollbackOutlined />
                    <FormattedMessage id='common.reset' />
                </Button>
            </>
        )
    };

    return (
        <Table<EstimateRequestListDto>
            className="list-table"
            pagination={paginationProps}
            columns={columns}
            dataSource={props.data}
            loading={props.loading}
            rowClassName={displayUtil.rowClassName}
            rowKey={'id'}
            tableLayout={'fixed'}
            title={title}
            onChange={handleTableChange}
            scroll={constListScroll}
        // rowSelection={rowSelection}
        />
    );
}












