import React, { Key, useState } from 'react';
import { FormattedMessage, } from 'react-intl';
import { Button, Table, } from 'antd';
import {  RollbackOutlined, SearchOutlined, DownloadOutlined, FunnelPlotFilled } from '@ant-design/icons';
import { TablePaginationConfig } from 'antd/es/table';

import {  displayUtil, filterUtil } from '../util'
import { InputFilter, RangeFilter, Filter, DateRange, constPageSizeOptions, constListScroll, SelectFilter, NumberRange, NumberRangeFilter } from '../common'

import { useFields } from '../fields';

import { BillingDetailSearchDto, BillingDetailListDto, } from './api'
import './index.css';
import { SorterResult } from 'antd/es/table/interface';
import { Link } from 'react-router-dom';

export interface Props {
  loading: boolean;
  total: number;
  data: BillingDetailListDto[];
  searchDto: BillingDetailSearchDto;
  openSearchModal: () => void;
  handleResearch: (researchDto: BillingDetailSearchDto) => void;
  handleSearch: (searchDto: BillingDetailSearchDto) => void;
  download: () => void;
  reset: (rowCount: number) => void;
}

export function BillingDetailList(props: Props) {

  const [rowCount, setRowCount] = useState<number>(10);
  // ページング、フィルター、ソート順の処理
  const handleTableChange = (pagination: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: SorterResult<BillingDetailListDto> | SorterResult<BillingDetailListDto>[],
    extra: any
  ) => {

    // 再検索の条件を作成する
    const researchDto: BillingDetailSearchDto = { ...props.searchDto };
    let researchFlag = false;


    if (!!pagination.pageSize && rowCount !== pagination.pageSize) {
      setRowCount(pagination.pageSize)
    }

    if (!!pagination.pageSize && props.searchDto.rowCount !== pagination.pageSize) {
      researchDto.page = 1;
      researchDto.rowCount = pagination.pageSize;
      researchFlag = true;
    } else if (props.searchDto.page !== pagination.current) {
      researchDto.page = pagination.current;
      researchFlag = true;
    }

    const fixSorter = sorter as SorterResult<BillingDetailListDto>;
    if (props.searchDto.sortField !== fixSorter.field
      || props.searchDto.sortOrder !== fixSorter.order) {
      researchDto.sortField = fixSorter.field as string | undefined;
      researchDto.sortOrder = fixSorter.order;
      researchFlag = true;
    }

    if (researchFlag) {
      props.handleResearch(researchDto);
    }
  };

  const filterInput = (itemTitle: 'supplierName') =>
    (value?: string) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: BillingDetailSearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };

  const filterSelect = (itemTitle: 'suppliers') =>
    (value: string[]) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: BillingDetailSearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };

  const filterRanger = (itemTitle: 'acquisitionDate' | 'paymentDate') =>
    (value: DateRange) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: BillingDetailSearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };

  const filterNumberRanger = (itemTitle: 'purchaseAmount') => {
    return (value: NumberRange) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: BillingDetailSearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };
  }

  const numberRangeFilterItemHighLight = (range: NumberRange) => {
    if (range === undefined || range === null) {
      return false
    }
    if (range[0] !== undefined && range[0] !== null) {
      return true
    }
    if (range[1] !== undefined && range[1] !== null) {
      return true
    }
    return false
  }

  const sortOrder = (itemTitle: string): 'ascend' | 'descend' | null | undefined => {

    if (itemTitle === props.searchDto.sortField) {
      return props.searchDto.sortOrder;
    }

    return null;
  };

  // タイトル
  // 年月
  const acquisitionDateTitle = <FormattedMessage id='billingDetail.acquisitionDate' />;
  // 支払日
  const paymentDateTitle = <FormattedMessage id='pay.paymentDate' />;
  // 明細ボタン
  const detailButtonTitle = <FormattedMessage id='billingDetail.detail' />;
  // 支払合計金額
  const totalPaymentAmountTitle = <FormattedMessage id='pay.totalPaymentAmount' />;
  // 仕入先コード
  const supplierCodeTitle = <FormattedMessage id='billingDetail.supplierCode' />;
  // 仕入先名称
  const supplierNameTitle = <FormattedMessage id='billingDetail.supplierName' />;

  const fullSupplierOptions = useFields('fullSupplier');

  //filter
  type filterType = Filter<BillingDetailListDto>;

  // 年月
  const acquisitionDateFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <RangeFilter values={props.searchDto.acquisitionDate} onChange={filterRanger('acquisitionDate')} />,
    filteredValue: filterUtil.range2stringArray(props.searchDto.acquisitionDate),
  };

  // 支払日
  const paymentDateFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <RangeFilter values={props.searchDto.paymentDate} onChange={filterRanger('paymentDate')} />,
    filteredValue: filterUtil.range2stringArray(props.searchDto.paymentDate),
  };

  // 支払合計
  const purchaseAmountFilter: filterType = {
    filterIcon: <FunnelPlotFilled />,
    filterDropdown: <NumberRangeFilter values={props.searchDto.purchaseAmount} onChange={filterNumberRanger('purchaseAmount')} />,
    filteredValue: filterUtil.numberRange2stringArray(props.searchDto.purchaseAmount),
    filtered: numberRangeFilterItemHighLight(props.searchDto.purchaseAmount),
  }

  // 仕入先コード
  const supplierFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <SelectFilter fields={fullSupplierOptions} values={props.searchDto.suppliers} onChange={filterSelect('suppliers')} />,
    filteredValue: props.searchDto.suppliers,
  };

  // 仕入先名称
  const supplierNameFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <InputFilter value={props.searchDto.supplierName} onChange={filterInput('supplierName')} />,
    filteredValue: !props.searchDto.supplierName ? [] : [props.searchDto.supplierName],
  };

  // Detail Link
  const detailButton = (text: any, record: BillingDetailListDto, index: number) => {
    const companyId = record?.companyId
    const acquisitionDateTimestamp = record?.acquisitionDate ? record?.acquisitionDate.valueOf() : 0
    const supplierCode = record?.supplierCode
    const paymentDate = record?.paymentDate ? record.paymentDate.valueOf() : 0
    return <Link to={`../billingDetail/detail/${companyId}/${supplierCode}/${acquisitionDateTimestamp}/${paymentDate}`}><FormattedMessage id='billingDetail' /></Link>
  };

  const acquisitionDateRecord = (text: any, record: BillingDetailListDto, index: number) => {
    return `${record?.acquisitionDate?.year()}/${record?.acquisitionDate?.month() + 1}`
  };

  const paymentDateRecord = (text: any, record: BillingDetailListDto, index: number) => {
    return record?.paymentUponEachUseFlag === '1' ? <FormattedMessage id='pay.paymentUponEachUse' /> : displayUtil.date(record?.paymentDate)
  };

  const width400 = '400px';
  const width200 = '200px';

  const array = [

    // 年月
    {
      title: acquisitionDateTitle,
      width: width200,
      dataIndex: 'acquisitionDate',
      render: acquisitionDateRecord,
      sorter: true,
      sortOrder: sortOrder('acquisitionDate'),
      ...acquisitionDateFilter,
    },

    // 支払日
    {
      title: paymentDateTitle,
      width: width200,
      dataIndex: 'paymentDate',
      render: paymentDateRecord,
      sorter: true,
      sortOrder: sortOrder('paymentDate'),
      ...paymentDateFilter,
    },

    // 明細ボタン
    {
      title: detailButtonTitle,
      width: width200,
      render: detailButton,
    },

    // 支払合計
    {
      title: totalPaymentAmountTitle,
      dataIndex: 'purchaseAmount',
      width: width200,
      sorter: true,
      sortOrder: sortOrder('purchaseAmount'),
      ...purchaseAmountFilter
    },

    // 仕入先コード
    {
      title: supplierCodeTitle,
      width: width200,
      dataIndex: 'supplierCode',
      sorter: true,
      sortOrder: sortOrder('supplierCode'),
      ...supplierFilter,
    },
    
    // 仕入先名称
    {
      title: supplierNameTitle,
      width: width400,
      dataIndex: 'supplierName',
      sorter: true,
      sortOrder: sortOrder('supplierName'),
      ...supplierNameFilter,
    },
  ];

  const paginationProps: TablePaginationConfig = {
    current: props.searchDto.page,
    total: props.total,
    defaultPageSize: props.searchDto.rowCount,
    position: ['bottomLeft'],
    showSizeChanger: true,
    pageSizeOptions: constPageSizeOptions,
  }

  const title = () => (<>
    <FormattedMessage id='billingDetail' />
    <Button onClick={props.openSearchModal} style={{ marginLeft: 10, marginRight: 10 }} >
      <SearchOutlined />
      <FormattedMessage id='common.conditions' />
    </Button>
    <Button onClick={props.download} style={{ marginLeft: 10, marginRight: 10 }} >
      <DownloadOutlined />
      <FormattedMessage id='common.download' />
    </Button>
    <Button className='reset-btn' onClick={() => { props.reset(rowCount); }}>
      <RollbackOutlined />
      <FormattedMessage id='common.reset' />
    </Button>
  </>)

  return (
    <Table<BillingDetailListDto>
      className="list-table"
      pagination={paginationProps}
      columns={array}
      dataSource={props.data}
      loading={props.loading}
      rowClassName={displayUtil.rowClassName}
      rowKey={'id'}
      tableLayout={'fixed'}
      title={title}
      onChange={handleTableChange}
      scroll={constListScroll}
    />
  );
}
