import zh_CN from './zh_CN';
import ja_JP from './ja_JP';
import en_US from './en_US';
import antdZh from 'antd/lib/locale/zh_CN'
import antdJa from 'antd/lib/locale/ja_JP'
import antdEn from 'antd/lib/locale/en_US'
import dayjsZh from 'dayjs/locale/zh'
import dayjsJa from 'dayjs/locale/ja'
import dayjsEn from 'dayjs/locale/en'

export const getLocales = () => {

  let locales: [string, typeof zh_CN, typeof antdZh, typeof dayjsZh] = ['zh-CN', zh_CN, antdZh, dayjsZh];

  if (typeof localStorage.getItem('et_lang') === 'string') {

    const lang = localStorage.getItem('et_lang');

    if (lang === 'ja-JP') {
      locales = [lang, ja_JP, antdJa, dayjsJa];
    }
    else if (lang === 'en-US') {
      locales = [lang, en_US, antdEn, dayjsEn];
    } 
  }

  return locales;
};

export const getLang = () => {

  const lang = localStorage.getItem('et_lang');

  if (typeof lang === 'string') {
    return lang;
  }

  return 'zh-CN';
}

export const setLang = (lang: 'zh-CN' | 'ja-JP' | 'en-US') => {

  const currentLang = localStorage.getItem('et_lang');

  if (lang !== currentLang) {
    window.location.reload();
    localStorage.setItem('et_lang', lang);
  }
}
