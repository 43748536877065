import React, { Key, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Table, } from 'antd';
import { RollbackOutlined, SearchOutlined, DownloadOutlined, FunnelPlotFilled } from '@ant-design/icons';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnsType } from 'antd/lib/table';

import { arrayUtil, displayUtil, filterUtil } from '../util'
import { InputFilter, RangeFilter, Filter, DateRange, constPageSizeOptions, constListScroll, SelectFilter, NumberRangeFilter, NumberRange } from '../common'

import { Field, useFields } from '../fields';

import { PaySearchDto, PayListDto, } from './api'
import './index.css';
import { SorterResult } from 'antd/es/table/interface';
import { Link } from 'react-router-dom';
import { useUser } from '../authorization';

export interface Props {
  loading: boolean;
  total: number;
  data: PayListDto[];
  readFlagOptions: Field[];
  searchDto: PaySearchDto;
  openSearchModal: () => void;
  handleResearch: (researchDto: PaySearchDto) => void;
  handleSearch: (searchDto: PaySearchDto) => void;
  download: () => void;
  reset: (rowCount: number) => void;
}

export function PayList(props: Props) {

  // 共通のstates
  const user = useUser();
  const [rowCount, setRowCount] = useState<number>(10);
  // ページング、フィルター、ソート順の処理
  const handleTableChange = (pagination: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: SorterResult<PayListDto> | SorterResult<PayListDto>[],
    extra: any) => {

    // 再検索の条件を作成する
    const researchDto: PaySearchDto = { ...props.searchDto };
    let researchFlag = false;


    const { readFlag: preReadFlag} = researchDto;
    const { readFlag } = filters;
    const curentReadFlag = readFlag ? readFlag as string[] : [];
  
    if (!arrayUtil.equals(curentReadFlag, preReadFlag)) {
      researchDto.readFlag = curentReadFlag;
   //   props.handleSearch(researchDto);
    }    

    if (!!pagination.pageSize && rowCount !== pagination.pageSize) {
      setRowCount(pagination.pageSize)
    }

    if (!!pagination.pageSize && props.searchDto.rowCount !== pagination.pageSize) {
      researchDto.page = 1;
      researchDto.rowCount = pagination.pageSize;
      researchFlag = true;
    }
    else if (props.searchDto.page !== pagination.current) {
      researchDto.page = pagination.current;
      researchFlag = true;
    }

    const fixSorter = sorter as SorterResult<PayListDto>;
    if (props.searchDto.sortField !== fixSorter.field
      || props.searchDto.sortOrder !== fixSorter.order) {
      researchDto.sortField = fixSorter.field as string | undefined;
      researchDto.sortOrder = fixSorter.order;
      researchFlag = true;
    }

    if (researchFlag) {
      props.handleResearch(researchDto);
    }
  };

  const filterInput = (itemTitle: 'paymentId' | 'supplierName') =>
    (value?: string) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: PaySearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };

  const filterSelect = (itemTitle: 'suppliers') =>
    (value: string[]) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: PaySearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };

  const filterDateRanger = (itemTitle: 'paymentDate' | 'acquisitionDate' | 'dateOfIssue') => {
    return (value: DateRange) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: PaySearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };
  }

  const filterNumberRanger = (itemTitle: 'totalPaymentAmount' | 'lastMonthPayAdjustAmountAll' | 'purchasePriceAll' | 'accountsReceivableOffset' | 'otherOffset' | 'payAdjustAmountAll' | 'balanceAmountPay') => {
    return (value: NumberRange) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: PaySearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };
  }

  const numberRangeFilterItemHighLight = (range: NumberRange) => {
    if (range === undefined || range === null) {
      return false
    }
    if (range[0] !== undefined && range[0] !== null) {
      return true
    }
    if (range[1] !== undefined && range[1] !== null) {
      return true
    }
    return false
  }

  const sortOrder = (itemTitle: string): 'ascend' | 'descend' | null | undefined => {

    if (itemTitle === props.searchDto.sortField) {
      return props.searchDto.sortOrder;
    }

    return null;
  };

  // タイトル

  //既読フラグ
  const readFlagTitle = <FormattedMessage id='pay.readFlag' />;
  // 支払コード
  const paymentIdTitle = <FormattedMessage id='pay.paymentId' />;
  // 仕入先コード
  const supplierCodeTitle = <FormattedMessage id='pay.supplierCode' />;
  // 受注者名称
  const supplierNameTitle = <FormattedMessage id='pay.supplierName' />;
  // 発注者名称
  const buyerNameTitle = <FormattedMessage id='pay.buyerName' />
  // 支払合計金額
  const totalPaymentAmountTitle = <FormattedMessage id='pay.totalPaymentAmount' />;
  // 通貨
  const currencyCodeTitle = <FormattedMessage id='pay.currencyCode' />;
  // 支払日
  const paymentDateTitle = <FormattedMessage id='pay.paymentDate' />;
  // 発行日
  const dateOfIssueTitle = <FormattedMessage id='pay.dateOfIssue' />;
  // 前月支払修正額(合計)
  const lastMonthPayAdjustAmountTitle = <FormattedMessage id='pay.lastMonthPayAdjustAmount' />;
  // 仕入代金(合計)
  const purchasePriceAllTitle = <FormattedMessage id='pay.purchasePrice' />;
  // 支払修正額(合計)
  const payAdjustAmountAllTitle = <FormattedMessage id='pay.payAdjustAmount' />;
  // 売掛金相殺(合計)
  const accountsReceivableOffsetTitle = <FormattedMessage id='pay.accountsReceivableOffset' />;
  // その他相殺(合計)
  const otherOffsetTitle = <FormattedMessage id='pay.otherOffset' />;
  // 差引お支払金額(合計)
  const balanceAmountPayTitle = <FormattedMessage id='pay.balanceAmountPay' />;

  const fullSupplierOptions = useFields('fullSupplier');

  const width90 = '85px';
  const width130 = '130px';
  const width160 = '160px';
  const width180 = '180px';
  const width200 = '200px';
  const width210 = '210px';

  //filter
  type filterType = Filter<PayListDto>;

  const paymentIdFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <InputFilter value={props.searchDto.paymentId} onChange={filterInput('paymentId')} />,
    filteredValue: !props.searchDto.paymentId ? [] : [props.searchDto.paymentId],
  };

  const supplierFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <SelectFilter fields={fullSupplierOptions} values={props.searchDto.suppliers} onChange={filterSelect('suppliers')} />,
    filteredValue: props.searchDto.suppliers,
  };

  const paymentDateFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <RangeFilter values={props.searchDto.paymentDate} onChange={filterDateRanger('paymentDate')} />,
    filteredValue: filterUtil.range2stringArray(props.searchDto.paymentDate),
  };

  const dateOfIssueFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <RangeFilter values={props.searchDto.dateOfIssue} onChange={filterDateRanger('dateOfIssue')} />,
    filteredValue: filterUtil.range2stringArray(props.searchDto.dateOfIssue),
  };
  
  const readFlagFilter: filterType = {
    filters: filterUtil.field2Fileter(props.readFlagOptions), filterMultiple: false,
    filteredValue: props.searchDto.readFlag,
    onFilter: (value: any, record:PayListDto) => record.readFlag === value
  };

  // 支払合計金額検索
  const totalPaymentAmountFilter: filterType = {
    filterIcon: <FunnelPlotFilled />,
    filterDropdown: <NumberRangeFilter values={props.searchDto.totalPaymentAmount} onChange={filterNumberRanger('totalPaymentAmount')} />,
    filteredValue: filterUtil.numberRange2stringArray(props.searchDto.totalPaymentAmount),
    filtered: numberRangeFilterItemHighLight(props.searchDto.totalPaymentAmount),
  };

  // 前月支払修正額(合計)
  const lastMonthPayAdjustAmountAllFilter: filterType = {
    filterIcon: <FunnelPlotFilled />,
    filterDropdown: <NumberRangeFilter values={props.searchDto.lastMonthPayAdjustAmountAll} onChange={filterNumberRanger('lastMonthPayAdjustAmountAll')} />,
    filteredValue: filterUtil.numberRange2stringArray(props.searchDto.lastMonthPayAdjustAmountAll),
    filtered: numberRangeFilterItemHighLight(props.searchDto.lastMonthPayAdjustAmountAll),
  }

  // 仕入代金(合計)
  const purchasePriceAllFilter: filterType = {
    filterIcon: <FunnelPlotFilled />,
    filterDropdown: <NumberRangeFilter values={props.searchDto.purchasePriceAll} onChange={filterNumberRanger('purchasePriceAll')} />,
    filteredValue: filterUtil.numberRange2stringArray(props.searchDto.purchasePriceAll),
    filtered: numberRangeFilterItemHighLight(props.searchDto.purchasePriceAll),
  }

  // 売掛金相殺(合計)
  const accountsReceivableOffsetFilter: filterType = {
    filterIcon: <FunnelPlotFilled />,
    filterDropdown: <NumberRangeFilter values={props.searchDto.accountsReceivableOffset} onChange={filterNumberRanger('accountsReceivableOffset')} />,
    filteredValue: filterUtil.numberRange2stringArray(props.searchDto.accountsReceivableOffset),
    filtered: numberRangeFilterItemHighLight(props.searchDto.accountsReceivableOffset),
  }

  // その他相殺(合計)
  const otherOffsetFilter: filterType = {
    filterIcon: <FunnelPlotFilled />,
    filterDropdown: <NumberRangeFilter values={props.searchDto.otherOffset} onChange={filterNumberRanger('otherOffset')} />,
    filteredValue: filterUtil.numberRange2stringArray(props.searchDto.otherOffset),
    filtered: numberRangeFilterItemHighLight(props.searchDto.otherOffset),
  }

  // 支払修正額(合計)
  const payAdjustAmountAllFilter: filterType = {
    filterIcon: <FunnelPlotFilled />,
    filterDropdown: <NumberRangeFilter values={props.searchDto.payAdjustAmountAll} onChange={filterNumberRanger('payAdjustAmountAll')} />,
    filteredValue: filterUtil.numberRange2stringArray(props.searchDto.payAdjustAmountAll),
    filtered: numberRangeFilterItemHighLight(props.searchDto.payAdjustAmountAll),
  }

  // 差引お支払金額
  const balanceAmountPayFilter: filterType = {
    filterIcon: <FunnelPlotFilled />,
    filterDropdown: <NumberRangeFilter values={props.searchDto.balanceAmountPay} onChange={filterNumberRanger('balanceAmountPay')} />,
    filteredValue: filterUtil.numberRange2stringArray(props.searchDto.balanceAmountPay),
    filtered: numberRangeFilterItemHighLight(props.searchDto.balanceAmountPay),
  }

  //金額フォーマット変換
  const showTotalPaymentAmount = (text: any, record: PayListDto, index?: number) => {
    return displayUtil.currency(record.currencyCode, record.totalPaymentAmount)
  };
  const showLastMonthPaymentAdjustmentAmount = (text: any, record: PayListDto, index?: number) => {
    return displayUtil.currency(record.currencyCode, record.lastMonthPayAdjustAmountAll)
  };
  const showPurchaseAllPrice = (text: any, record: PayListDto, index?: number) => {
    return displayUtil.currency(record.currencyCode, record.purchasePriceAll)
  };
  const showAccountsReceivableOffset = (text: any, record: PayListDto, index?: number) => {
    return displayUtil.currency(record.currencyCode, record.accountsReceivableOffset)
  };
  const showOtherOffset = (text: any, record: PayListDto, index?: number) => {
    return displayUtil.currency(record.currencyCode, record.otherOffset)
  };
  const showBalanceAmountPay = (text: any, record: PayListDto, index?: number) => {
    return displayUtil.currency(record.currencyCode, record.balanceAmountPay)
  };
  const showPayAdjustAmountAll = (text: any, record: PayListDto, index?: number) => {
    return displayUtil.currency(record.currencyCode, record.payAdjustAmountAll)
  };
  //Link
  const paymentIdLink = (text: any, record: PayListDto, index: number) => {
    return <Link to={`/pays/${record.id}`}>{record.paymentId}</Link>;
  };

  const paymentDateRender = (text: any, record: PayListDto, index: number) => {
    return record?.paymentUponEachUseFlag === '1' ? <FormattedMessage id='pay.paymentUponEachUse' /> : displayUtil.date(record?.paymentDate)
  }

  const array = [
    {//既読フラグ
      title: readFlagTitle,width: width160,
      dataIndex: 'readFlag',
      render: displayUtil.field(props.readFlagOptions),
      sorter: true,
      sortOrder: sortOrder('readFlag'),
      ...readFlagFilter
    },

    // 発行日
    {
      title: dateOfIssueTitle, width: width130,
      dataIndex: 'dateOfIssue',
      render: displayUtil.date,
      sorter: true,
      sortOrder: sortOrder('dateOfIssue'),
      ...dateOfIssueFilter
    },

    // 支払日
    {
      title: paymentDateTitle, width: width130,
      dataIndex: 'paymentDate',
      render: paymentDateRender,
      sorter: true,
      sortOrder: sortOrder('paymentDate'),
      ...paymentDateFilter
    },

    // 支払コード
    {
      title: paymentIdTitle, width: width160,
      dataIndex: 'paymentId',
      render: paymentIdLink,
      sorter: true,
      sortOrder: sortOrder('paymentId'),
      ...paymentIdFilter
    },

    // 発注者名称
    {
      title: buyerNameTitle, width: width160,
      dataIndex: 'buyerName',
      ellipsis: true,
      sorter: false
    },

    // 仕入先コード
    {
    title: supplierCodeTitle, width: width180,
    dataIndex: 'supplier',
    sorter: true,
    sortOrder: sortOrder('supplier'),
    ...supplierFilter
    },

    // 受注者名称
    {
      title: supplierNameTitle, width: width160,
      dataIndex: 'supplierName',
      ellipsis: true,
    },

    // 通貨コート
    {
      title: currencyCodeTitle, width: width90,
      dataIndex: 'currencyCode',
      sorter: false
    },

    // 支払合計金額
    {
      title: totalPaymentAmountTitle, width: width160,
      dataIndex: 'totalPaymentAmount',
      render: showTotalPaymentAmount,
      sorter: true,
      sortOrder: sortOrder('totalPaymentAmount'),
      ...totalPaymentAmountFilter
    },

    // 前月支払修正額(合計)
    {
      title: lastMonthPayAdjustAmountTitle, width: width210,
      dataIndex: 'lastMonthPayAdjustAmountAll',
      render: showLastMonthPaymentAdjustmentAmount,
      sorter: true,
      sortOrder: sortOrder('lastMonthPayAdjustAmountAll'),
      ...lastMonthPayAdjustAmountAllFilter
    },

    // 仕入代金(合計)
    {
      title: purchasePriceAllTitle, width: width160,
      dataIndex: 'purchasePriceAll',
      render: showPurchaseAllPrice,
      sorter: true,
      sortOrder: sortOrder('purchasePriceAll'),
      ...purchasePriceAllFilter
    },

    // 売掛金相殺(合計)
    {
      title: accountsReceivableOffsetTitle, width: width180,
      dataIndex: 'accountsReceivableOffset',
      render: showAccountsReceivableOffset,
      sorter: true,
      sortOrder: sortOrder('accountsReceivableOffset'),
      ...accountsReceivableOffsetFilter
    },

    // その他相殺(合計)
    {
      title: otherOffsetTitle, width: width160,
      dataIndex: 'otherOffset',
      render: showOtherOffset,
      sorter: true,
      sortOrder: sortOrder('otherOffset'),
      ...otherOffsetFilter
    },

    // 支払修正額(合計)
    {
      title: payAdjustAmountAllTitle, width: width160,
      dataIndex: 'payAdjustAmountAll',
      render: showPayAdjustAmountAll,
      sorter: true,
      sortOrder: sortOrder('payAdjustAmountAll'),
      ...payAdjustAmountAllFilter
    },

    // 差引お支払金額
    {
      title: balanceAmountPayTitle, width: width200,
      dataIndex: 'balanceAmountPay',
      render: showBalanceAmountPay,
      sorter: true,
      sortOrder: sortOrder('balanceAmountPay'),
      ...balanceAmountPayFilter
    },
  ];

  const columns: ColumnsType<PayListDto> = !user?.isSupplier ? array.filter(item =>item.dataIndex !== 'buyerName') : array.filter(item => item.dataIndex !== 'readFlag');

  const paginationProps: TablePaginationConfig = {
    current: props.searchDto.page,
    total: props.total,
    defaultPageSize: props.searchDto.rowCount,
    position: ['bottomLeft'],
    showSizeChanger: true,
    pageSizeOptions: constPageSizeOptions,
  }

  const title = () => (<>
    <FormattedMessage id='pay.list' />
    <Button onClick={props.openSearchModal} style={{ marginLeft: 10, marginRight: 10 }} >
      <SearchOutlined />
      <FormattedMessage id='common.conditions' />
    </Button>
    <Button onClick={props.download} style={{ marginLeft: 10, marginRight: 10 }} >
      <DownloadOutlined />
      <FormattedMessage id='common.download' />
    </Button>
    <Button className='reset-btn' onClick={() => { props.reset(rowCount); }}>
      <RollbackOutlined />
      <FormattedMessage id='common.reset' />
    </Button>
  </>)

  return (
    <Table<PayListDto>
      className="list-table"
      pagination={paginationProps}
      columns={columns}
      dataSource={props.data}
      loading={props.loading}
      rowClassName={displayUtil.rowClassNamePaymentHightLight}
      // rowClassName={'table-tr-normal'}
      rowKey={'id'}
      tableLayout={'fixed'}
      title={title}
      onChange={handleTableChange}
      scroll={constListScroll}
    // scroll={constListScroll}
    />
  );
}
