import dayjs from 'dayjs';

// 253402271999000 is the timestamp of 9999/12/31 23:59:59
// 253370736000000 is the timestamp of 9999/01/01 00:00:00
const MAX_LIMIT_DATE = 253370736000000;
// //               0 is the timestamp of 1970/01/01 00:00:00
// const MIN_LIMIT_DATE = 0;
//               1180800000 is the timestamp of 1970/01/15 00:00:00
const MIN_LIMIT_DATE = 1180800000;

export const isOverDateLimit = (date: any) => {
  if (date !== null && date instanceof dayjs) {
    const dateNumber = date.valueOf();
    return dateNumber as number > MAX_LIMIT_DATE || dateNumber as number < MIN_LIMIT_DATE;
  } else {
    return true;
  }
}

interface UnitValidator {
  validate: (value: any) => boolean;
  message: string;
}
// 通常情况下来说，当限制不能选择的日期大于 9999年12月31日 之后，就不需要加这个限制了，因为超过 9999年12月31日 的已经是 10000年 了，会触发默认的错误日期格式报错
export const excelDateLimit: UnitValidator[] = [{ validate: isOverDateLimit, message: 'message.timeOverTheLimit' }];
