import React, { Key, useState } from 'react';
import { FormattedMessage, useIntl, } from 'react-intl';
import { Link } from 'react-router-dom';

import { Button, Table, Popconfirm } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { TablePaginationConfig } from 'antd/es/table';
import { RollbackOutlined, SearchOutlined, DownloadOutlined, FunnelPlotFilled } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import { AcceptanceSearchDto, AcceptanceListRecordDto, } from './api'
import { InputFilter, RangeFilter, Filter, DateRange, SelectFilter, constPageSizeOptions, constListScroll, NumberRangeFilter, NumberRange, } from '../common'
import { arrayUtil, displayUtil, filterUtil, } from '../util'

import './index.css';
import { useFields } from '../fields';
import { useUser } from '../authorization';
export interface Props {
  loading: boolean;
  searchDto: AcceptanceSearchDto;
  total: number;
  data: AcceptanceListRecordDto[];
  openSearchModal: () => void;
  download: () => void;
  handleResearch: (researchDto: AcceptanceSearchDto) => void;
  reset:(rowCount:number)=> void;
  readAll: () => void;
}


export function AcceptanceList(props: Props) {

  // ユーザー
  const user = useUser();
  // 共通のstates
  const intl = useIntl();

  const fullSupplierOptions = useFields('fullSupplier')
  const itemOptions = useFields('item');
  const [rowCount, setRowCount] = useState<number>(10);
  // ページング、フィルター、ソート順の処理
  const handleTableChange = (pagination: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: SorterResult<AcceptanceListRecordDto> | SorterResult<AcceptanceListRecordDto>[],
    extra: any) => {

    // 再検索の条件を作成する
    const researchDto: AcceptanceSearchDto = { ...props.searchDto };
    let researchFlag = false;

    if (!!pagination.pageSize && rowCount !== pagination.pageSize) {
      setRowCount(pagination.pageSize)
    }
    
    if (!!pagination.pageSize && props.searchDto.rowCount !== pagination.pageSize) {
      researchDto.page = 1;
      researchDto.rowCount = pagination.pageSize;
      researchFlag = true;
    }
    else if (props.searchDto.page !== pagination.current) {
      researchDto.page = pagination.current;
      researchFlag = true;
    }

    //保管場所
    const { locationCd: preLocationCode } = researchDto;
    const { locationCd } = filters;
    const preLocationCds = preLocationCode ? [preLocationCode] : [];
    const curentLocationCds = locationCd ? locationCd as string[] : [];
    if (!arrayUtil.equals(curentLocationCds, preLocationCds)) {
      researchDto.locationCd = curentLocationCds[0];
      props.handleResearch(researchDto);
    }

    const fixSorter = sorter as SorterResult<AcceptanceListRecordDto>;
    if (props.searchDto.sortField !== fixSorter.field
      || props.searchDto.sortOrder !== fixSorter.order) {
      researchDto.sortField = fixSorter.field as string | undefined;
      researchDto.sortOrder = fixSorter.order;
      researchFlag = true;
    }

    if (researchFlag) {
      props.handleResearch(researchDto);
    }
  };

  const filterInput = (itemTitle: 'supplierName' | 'purchasingManager' | 'acceptanceNo' | 'orderNo' | 'deliveryNo' | 'itemName' | 'locationName' | 'locationCd') =>
    (value?: string) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: AcceptanceSearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };
  const filterSelect = (itemTitle: 'suppliers' | 'items') =>
    (value: string[]) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: AcceptanceSearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };
  const filterRanger = (itemTitle: 'acceptanceDate') =>
    (value: DateRange) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: AcceptanceSearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };

  const filterNumberRanger = (itemTitle: 'unitPrice' | 'amount' | 'orderAmount') => {
    return (value: NumberRange) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: AcceptanceSearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };
  }

  const numberRangeFilterItemHighLight = (range: NumberRange) => {
    if (range === undefined || range === null) {
      return false
    }
    if (range[0] !== undefined && range[0] !== null) {
      return true
    }
    if (range[1] !== undefined && range[1] !== null) {
      return true
    }
    return false
  }

  const sortOrder = (itemTitle: string): 'ascend' | 'descend' | null | undefined => {

    if (itemTitle === props.searchDto.sortField) {
      return props.searchDto.sortOrder;
    }

    return null;
  };

  type filterType = Filter<AcceptanceListRecordDto>;

  const supplierFilter: filterType = {
    // filterIcon: <SearchOutlined style={{ fontSize: '17px'}} />,  // large size icon
    filterIcon: <SearchOutlined />,
    filterDropdown: <SelectFilter fields={fullSupplierOptions} values={props.searchDto.suppliers} onChange={filterSelect('suppliers')} />,
    filteredValue: props.searchDto.suppliers,
  };

  const supplierNameFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <InputFilter value={props.searchDto.supplierName} onChange={filterInput('supplierName')} />,
    filteredValue: !props.searchDto.supplierName ? [] : [props.searchDto.supplierName],
  };
  const acceptanceDateFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <RangeFilter values={props.searchDto.acceptanceDate} onChange={filterRanger('acceptanceDate')} picker='date' />,
    filteredValue: filterUtil.range2stringArray(props.searchDto.acceptanceDate),
  };
  const orderNoFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <InputFilter value={props.searchDto.orderNo} onChange={filterInput('orderNo')} />,
    filteredValue: !props.searchDto.orderNo ? [] : [props.searchDto.orderNo],
  };
  const acceptanceNoFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <InputFilter value={props.searchDto.acceptanceNo} onChange={filterInput('acceptanceNo')} />,
    filteredValue: !props.searchDto.acceptanceNo ? [] : [props.searchDto.acceptanceNo],
  };
  const deliveryNoFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <InputFilter value={props.searchDto.deliveryNo} onChange={filterInput('deliveryNo')} />,
    filteredValue: !props.searchDto.deliveryNo ? [] : [props.searchDto.deliveryNo],
  };
  const itemFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <SelectFilter fields={itemOptions} values={props.searchDto.items} onChange={filterSelect('items')} />,
    filteredValue: props.searchDto.items,
  };
  const itemNameFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <InputFilter value={props.searchDto.itemName} onChange={filterInput('itemName')} />,
    filteredValue: !props.searchDto.itemName ? [] : [props.searchDto.itemName],
  };
  // 検収単価 フィルタ
  const unitPriceFilter: filterType = {
    filterIcon: <FunnelPlotFilled />,
    filterDropdown: <NumberRangeFilter values={props.searchDto.unitPrice} onChange={filterNumberRanger('unitPrice')} />,
    filteredValue: filterUtil.numberRange2stringArray(props.searchDto.unitPrice),
    filtered: numberRangeFilterItemHighLight(props.searchDto.unitPrice),
  }
  // 注文金額 フィルタ
  const amountFilter: filterType = {
    filterIcon: <FunnelPlotFilled />,
    filterDropdown: <NumberRangeFilter values={props.searchDto.amount} onChange={filterNumberRanger('amount')} />,
    filteredValue: filterUtil.numberRange2stringArray(props.searchDto.amount),
    filtered: numberRangeFilterItemHighLight(props.searchDto.amount),
  }
  // 注文残金額 フィルタ
  const orderAmountFilter: filterType = {
    filterIcon: <FunnelPlotFilled />,
    filterDropdown: <NumberRangeFilter values={props.searchDto.orderAmount} onChange={filterNumberRanger('orderAmount')} />,
    filteredValue: filterUtil.numberRange2stringArray(props.searchDto.orderAmount),
    filtered: numberRangeFilterItemHighLight(props.searchDto.orderAmount),
  }
  // 保管場所コード
  const locationCodeFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <InputFilter value={props.searchDto.locationCd} onChange={filterInput('locationCd')} />,
    filteredValue: !props.searchDto.locationCd ? [] : [props.searchDto.locationCd],
  };
  const acceptanceDetailNoLink = (text: any, record: AcceptanceListRecordDto, index: number) => {
    return <Link to={`../acceptances/${record.id}`}>{displayUtil.concatOrderNo(record.acceptanceNo, record.acceptanceDetailNo)}</Link>;
  };


  const acceptanceDateTitle = <FormattedMessage id='acceptance.acceptanceDate' />;
  const acceptanceNoTitle = <FormattedMessage id='acceptance.acceptanceNo' />
  const deliveryNoTitle = <FormattedMessage id='acceptance.deliveryNo' />
  const orderNoTitle = <FormattedMessage id='acceptance.orderNo' />;
  const supplierTitle = <FormattedMessage id='acceptance.supplier' />;
  const supplierNameTitle = <FormattedMessage id='acceptance.supplierName' />;
  const itemcodeTitle = <FormattedMessage id='acceptance.item' />;
  const itemNameTitle = <FormattedMessage id='acceptance.itemName' />;
  const quantityTitle = <FormattedMessage id='acceptance.acceptanceQuantity' />;
  const unitPriceTitle = <FormattedMessage id='acceptance.acceptanceUnitPrice' />;
  const amountTitle = <FormattedMessage id='acceptance.acceptanceAmount' />;
  const orderQuantityTitle = <FormattedMessage id='acceptance.orderQuantity' />;
  const orderAmountTitle = <FormattedMessage id='acceptance.orderAmount' />;
  const currencyCodeTitle = <FormattedMessage id='acceptance.currencyCode' />;
  const locationCdTitle = <FormattedMessage id='acceptance.locationCd' />;
  const buyerNameTitle = <FormattedMessage id='acceptance.buyerName' />;

  const width80 = '90px';
  const width100 = '100px';
  const width140 = '140px';
  const width160 = '160px';
  const width170 = '170px';
  const width240 = '240px';
  const width260 = '260px';

  //金額変換
  const showUnitPrice = (text: any, record: AcceptanceListRecordDto, index?: number) => {
    return !record.unitPrice ? "" : displayUtil.currency(record.currencyCode, record.unitPrice)
  };
  //金額変換
  const showAmount = (text: any, record: AcceptanceListRecordDto, index?: number) => {
    return !record.amount ? "" : displayUtil.currency(record.currencyCode, displayUtil.amount(record?.currencyCode, 1, record.amount))
  };
  //注文金額変換
  const showOrderAmount = (text: any, record: AcceptanceListRecordDto, index?: number) => {
    return !record.orderAmount ? "" : displayUtil.currency(record.currencyCode, record.orderAmount)
  };
  //注文番号
  const showOrderNo = (text: any, record: AcceptanceListRecordDto, index?: number) => {
    return !record.orderNo ? "" : displayUtil.concatOrderNo(record.orderNo, record.detailNo)
  };
  
  const columns: ColumnsType<AcceptanceListRecordDto> = [];


  // 発注者名称
 if (user?.isSupplier) {
    columns.push( {
    title: buyerNameTitle, width: width170,
    dataIndex: 'buyerName',
    ellipsis: true
  })
  }
  // 受注者
  columns.push({
    title: supplierTitle, width: width140,
    dataIndex: 'supplier',
    sorter: true, sortOrder: sortOrder('supplier'),
    ...supplierFilter,
  })
  // 受注者名称
  columns.push({
    title: supplierNameTitle, width: width260,
    dataIndex: 'supplierName',
    sorter: true, sortOrder: sortOrder('supplierName'),
    ...supplierNameFilter,
    ellipsis: true
  })  
  columns.push({
    title: acceptanceDateTitle, width: width140,
    dataIndex: 'acceptanceDate',
    sorter: true, sortOrder: sortOrder('acceptanceDate'),
    ...acceptanceDateFilter,
    render: displayUtil.date
  })
  // 品番
  columns.push({
    title: itemcodeTitle, width: width240,
    dataIndex: 'item',
    sorter: true,
    sortOrder: sortOrder('item'),
    ...itemFilter,
  })
  // 品名
  columns.push({
    title: itemNameTitle, width: width170,
    dataIndex: 'itemName',
    sorter: true, sortOrder: sortOrder('itemName'),
    ...itemNameFilter,
    ellipsis: true
  })
  // 検収番号
  columns.push({
    title: acceptanceNoTitle, width: width160,
    dataIndex: 'acceptanceNo',
    sorter: true, sortOrder: sortOrder('acceptanceNo'),
    ...acceptanceNoFilter,
    render: acceptanceDetailNoLink,
  });
  // 注文番号
  columns.push({
    title: orderNoTitle, width: width160,
    dataIndex: 'orderNo', render: showOrderNo,
    sorter: true, sortOrder: sortOrder('orderNo'),
    ...orderNoFilter,
  });
  // 検収数量
  columns.push({
    title: quantityTitle, width: width100,
    dataIndex: 'quantity1',
  });
  // 検収単価
  columns.push({
    title: unitPriceTitle, width: width140,
    dataIndex: 'unitPrice', render: showUnitPrice,
    sorter: true, sortOrder: sortOrder('unitPrice'),
    ...unitPriceFilter,
  });
  // 検収金額
  columns.push(  {
    title: amountTitle, width: width140,
    dataIndex: 'amount', render: showAmount,
    sorter: true, sortOrder: sortOrder('amount'),
    ...amountFilter,
  });
  // 注文数量
  columns.push({
    title: orderQuantityTitle, width: width100,
    dataIndex: 'orderQuantity1',
  });
  // 注文金額
  columns.push({
    title: orderAmountTitle, width: width140,
    dataIndex: 'orderAmount', render: showOrderAmount,
    sorter: true, sortOrder: sortOrder('orderAmount'),
    ...orderAmountFilter,
  })
  // 通貨
  columns.push({
    title: currencyCodeTitle, width: width80,
    dataIndex: 'currencyCode',
  })
  // 納品番号
  columns.push({
    title: deliveryNoTitle, width: width140,
    dataIndex: 'deliveryNo',
    sorter: true, sortOrder: sortOrder('deliveryNo'),
    ellipsis: true,
    ...deliveryNoFilter
  })
  // 保管場所コード
  columns.push({
    title: locationCdTitle, width: width160, ellipsis: true,
    dataIndex: 'locationCd',
    sorter: true, sortOrder: sortOrder('locationCd'), ...locationCodeFilter
  })
   
  // const columns: ColumnsType<AcceptanceListRecordDto> = !user?.isSupplier ? array.filter(item => item.dataIndex !== 'buyer' && item.dataIndex !== 'buyerName') : array;

  const title = () => {
    return (
      <>
        <FormattedMessage id='acceptance.list' />
        <Button onClick={props.openSearchModal} style={{ marginLeft: 10, marginRight: 10 }} >
          <SearchOutlined />
          <FormattedMessage id='common.conditions' />
        </Button>
        <Button onClick={props.download} style={{ marginLeft: 10, marginRight: 10 }} >
          <DownloadOutlined />
          <FormattedMessage id='common.download' />
        </Button>
        <Button className='reset-btn' onClick={() => {props.reset(rowCount) }}>
          <RollbackOutlined />
          <FormattedMessage id='common.reset' />
        </Button>
        <Popconfirm
          title={intl.formatMessage({ id: 'message.readAllConfirm' })}
          onConfirm={() => { props.readAll(); }}
          okText={intl.formatMessage({ id: 'common.confirm' })}
          cancelText={intl.formatMessage({ id: 'common.cancel2' })} >
          <Button className='reset-btn'>
            <RollbackOutlined />
            <FormattedMessage id='common.readAll' />
          </Button>
        </Popconfirm>
      </>
    )
  };

  const paginationProps: TablePaginationConfig = {
    current: props.searchDto.page,
    total: props.total,
    defaultPageSize: props.searchDto.rowCount,
    position: ['bottomLeft'],
    showSizeChanger: true,
    pageSizeOptions: constPageSizeOptions,
  }

  return (
    <Table<AcceptanceListRecordDto>
      className="list-table"
      pagination={paginationProps}
      columns={columns}
      dataSource={props.data}
      loading={props.loading}
      rowClassName={displayUtil.rowClassName}
      rowKey={'id'}
      tableLayout={'fixed'}
      title={title}
      onChange={handleTableChange}
      scroll={constListScroll}
    />
  );
}