import dayjs, { Dayjs } from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import { Field } from '../fields';
import { locales } from '../locales';

dayjs.extend(LocalizedFormat);

export const date = (text: any, record?: any, index?: number) => {

  if (!text) {
    return '';
  }

  const date = text as Dayjs;

  return date.valueOf() === 0 ? '' : date.format('L');
};

export const time = (text: any, record?: any, index?: number) => {

  if (!text) {
    return '';
  }

  const time = text as Dayjs;

  return date.valueOf() === 0 ? '' : time.format('HH:mm:ss');
};

export const datetime = (text: any, record?: any, index?: number) => {

  if (!text) {
    return '';
  }

  const date = text as Dayjs;

  const fromatDate = date.valueOf() === 0 ? '' : date.format('LL');
  const formatTime = date.valueOf() === 0 ? '' : date.format('HH:mm:ss')

  return fromatDate + " " + formatTime
};

export const locationDateTime = (text: any, serviceState?: string, record?: any, index?: number) => {

  if (!text) {
    return '';
  }

  const date = text as Dayjs;
  let loactionDate = date
  if(serviceState === 'jp') {
    loactionDate = date.add(9, 'hour')
  } else if(serviceState === 'cn') {
    loactionDate = date.add(8, 'hour')
  }

  const fromatDate = loactionDate.valueOf() === 0 ? '' : loactionDate.format('LL');
  const formatTime = loactionDate.valueOf() === 0 ? '' : loactionDate.format('HH:mm:ss')

  return fromatDate + " " + formatTime
};

export const month = (text: any, record?: any, index?: number) => {

  const lang = locales.getLang();

  let monthFormat = 'YYYY年M月';

  if (lang === 'ja-JP') {
    monthFormat = 'YYYY年MMMM';
  }
  else if (lang === 'en-US') {
    monthFormat = 'MMMM, YYYY';
  }

  return (!!text && (text as Dayjs).format(monthFormat));
};

export const currency = (currencyFlag?: string, num?: number) => {

  if (!currencyFlag) {
    return num;
  }

  if (num === undefined) {
    return "";
  }

  const lang = locales.getLang();

  const currencyFormat = new Intl.NumberFormat(lang, { style: 'currency', currency: currencyFlag, maximumFractionDigits: 6 });

  return (currencyFormat.format(num));
};

/**
 * 金額を算出する
 * 
 * 金額 = 単価 * 数量
 * 少数の場合、下記のパータンより切り上げ
 * 201 .3 JPY   => 202 JPY
 * 201 .316 CNY/USD  => 202.32 CNY/USD
 * 
 * @param currencyFlag 通貨コード
 * @param unitPrice 単価
 * @param quantity 数量
 */
export const amount = (currencyFlag?: string, unitPrice?: number, quantity?: number) => {

  const unitPriceIn = !!unitPrice ? unitPrice : 0;
  const quantityIn = !!quantity ? quantity : 0;

  const resultAmount = unitPriceIn * quantityIn;

  // format
  const leftShift = (currencyFlag === 'JPY') ? 1 : 100;
  return Math.ceil(resultAmount * leftShift) / leftShift;
}

export const field = (fields: Field[]) =>
  (text: any, record?: any, index?: number) => {

    const filtered = fields.filter((f) => f.value === text);

    if (filtered.length === 0) {
      return "";
    } else {
      return filtered[0].label;
    }
  };

//発注番号取得（発注番号＋明細）
export const concatOrderNo = (orderNo: string, detailNo: number) => {
  if (orderNo === null || orderNo === "") {
    return "";
  }
  return orderNo + "-" + detailNo;
};
//数取得（数量＋単位）
export const getQuantity = (quantity?: number, unit?: string) => {
  if (quantity === null || quantity === undefined) {
    quantity = 0;
  }
  return quantity + " " + unit;
};


export const sort = (title: string) =>
  (a: { [prop: string]: any }, b: typeof a): number => {

    if (!a[title] && !b[title]) {
      return 0;
    }

    if (!a[title]) {
      return -1;
    }

    if (!b[title]) {
      return 1;
    }

    if (typeof a[title] === 'number' || typeof a[title] === 'boolean' || a[title] instanceof Date) {
      return a[title] as any - b[title]  as any;
    }

    if (typeof a[title] === 'string') {
      return a[title].localeCompare(b[title]);
    }

    return 0;
  };

export const rowClassName = (record?: any, index?: number) => (
  !!record.unReadFlag ?
    (!!index && index % 2 === 1 ? 'table-tr-highlight-zebracolor' : 'table-tr-highlight')
    : !!index && index % 2 === 1 ? 'table-tr-normal-zebracolor' : 'table-tr-normal'
);

export const rowClassNamePaymentHightLight = (record?: any, index?: number) => (
  record.readFlag === '001' ?
    (!!index && index % 2 === 1 ? 'table-tr-highlight-zebracolor' : 'table-tr-highlight')
    : !!index && index % 2 === 1 ? 'table-tr-normal-zebracolor' : 'table-tr-normal'
);

export const rowClassNameModelHightLight = (record?: any, index?: number) => (
  ((record.moldNo === null || record.moldNo === '')) ?
    (!!index && index % 2 === 1 ?
      'table-tr-highlight-zebracolor'
      :
      'table-tr-highlight')
    :
    !!index && index % 2 === 1 ?
      'table-tr-normal-zebracolor'
      :
      'table-tr-normal'
);

export const rowClassInventoryHightLight = (record?: any, index?: number) => {
  if (record && record.moldsNum !== undefined && record.moldsNum !== null && record.actualMoldsNum !== undefined && record.actualMoldsNum !== null) {
    const moldsNum = parseInt(record.moldsNum, 10)
    const actualMoldsNum = parseInt(record.actualMoldsNum, 10)
    if (moldsNum !== undefined && moldsNum !== null && actualMoldsNum !== undefined && actualMoldsNum !== null) {
      if (moldsNum !== actualMoldsNum) {
        if (!!index && index % 2 === 1) {
          return 'table-tr-highlight-inventory-zebracolor'
        } else {
          return 'table-tr-highlight-inventory'
        }
      } else {
        if (!!index && index % 2 === 1) {
          return 'table-tr-normal-zebracolor'
        } else {
          return 'table-tr-normal'
        }
      }
    } else {
      if (!!index && index % 2 === 1) {
        return 'table-tr-normal-zebracolor'
      } else {
        return 'table-tr-normal'
      }
    }
  } else {
    if (!!index && index % 2 === 1) {
      return 'table-tr-normal-zebracolor'
    } else {
      return 'table-tr-normal'
    }
  }
};

export const rowClassExpandHightLight = (record?: any, index?: number) => (
  !!record.unReadFlag ?
    (!!index && index % 2 === 1 ? 'table-expand-tr-highlight-zebracolor' : 'table-expand-tr-highlight')
    : !!index && index % 2 === 1 ? 'table-expand-tr-normal-zebracolor' : 'table-expand-tr-normal'
);
