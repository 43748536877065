import { DownloadOutlined, FileExcelOutlined, RollbackOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Table, TablePaginationConfig } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import React, { Key, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { constListScroll, constPageSizeOptions, DateRange, Filter, InputFilter, RangeFilter, SelectFilter } from '../common';
import { arrayUtil, displayUtil, filterUtil } from '../util';
import { EstimateRequestSupplierResponseListDto, EstimateSearchDto } from './api';
import { useFields } from '../fields';
export interface Props {
    loading: boolean;
    total: number;
    data: EstimateRequestSupplierResponseListDto[];
    searchDto: EstimateSearchDto;
    openSearchModal: () => void;
    download: () => void;
    downloadPassed: () => void;
    // openFormModal: () => void;
    handleResearch: (researchDto: EstimateSearchDto) => void;
    handleSearch: (searchDto: EstimateSearchDto) => void;
    handleSelectedRows: (selectedRows: any) => void;
    reset:(rowCount:number)=> void;
}

export function EstimationSupplierList(props: Props) {

    const navigate = useNavigate();
    const responseStatusOptions = useFields('responseStatus');
    const statusOptions = useFields('estimateStatus');
    const itemOptions = useFields('item');
    const suppliersOption = useFields('supplier');
    const fullSupplierOptions = useFields('fullSupplier')
    const [rowCount, setRowCount] = useState<number>(10);

    // ページング、フィルター、ソート順の処理
    const handleTableChange = (pagination: TablePaginationConfig,
        filters: Record<string, (Key | boolean)[] | null>,
        sorter: SorterResult<EstimateRequestSupplierResponseListDto> | SorterResult<EstimateRequestSupplierResponseListDto>[],
        extra: any) => {
        // // 再検索の条件を作成する
        const researchDto: EstimateSearchDto = { ...props.searchDto };
        let researchFlag = false;

        //案件状態の場合
        const { responseStatus: preResponseStatus } = researchDto;
        const { responseStatus } = filters;
        const curentResponseStatus = responseStatus ? responseStatus as string[] : [];

        const { status: preStatus } = researchDto;
        const { status } = filters;
        const curentStatus = status ? status as string[] : [];

        //itemの場合
        const { item: preItemType } = researchDto;
        const { itemTypeCode } = filters;
        const curentItemType = itemTypeCode ? itemTypeCode as string[] : [];
        
        if (!!pagination.pageSize && rowCount !== pagination.pageSize) {
            setRowCount(pagination.pageSize)
        }

        if (!arrayUtil.equals(curentItemType, preItemType)) {
            researchDto.item = curentItemType;
            props.handleSearch(researchDto);
        }

        if (!arrayUtil.equals(curentResponseStatus, preResponseStatus)) {
            researchDto.responseStatus = curentResponseStatus;
            props.handleSearch(researchDto);
        }
        if (!arrayUtil.equals(curentStatus, preStatus)) {
            researchDto.status = curentStatus;
            props.handleSearch(researchDto);
        }


        if (!!pagination.pageSize && props.searchDto.rowCount !== pagination.pageSize) {
            researchDto.page = 1;
            researchDto.rowCount = pagination.pageSize;
            researchFlag = true;
        } else if (props.searchDto.page !== pagination.current) {
            researchDto.page = pagination.current;
            researchFlag = true;
        }

        const fixSorter = sorter as SorterResult<EstimateRequestSupplierResponseListDto>;
        if (props.searchDto.sortField !== fixSorter.field
            || props.searchDto.sortOrder !== fixSorter.order) {
            researchDto.sortField = fixSorter.field as string | undefined;
            researchDto.sortOrder = fixSorter.order;
            researchFlag = true;
        }

        if (researchFlag) {
            props.handleResearch(researchDto);
        }
    };

    // const filterInput = (itemTitle1: 'requestNo' | 'itemName' |'purchasingManagerName') =>
    const filterInput = (itemTitle1: 'requestNo' | 'itemName') =>
        (value?: string) => {
            if (props.searchDto[itemTitle1] !== value) {
                const researchDto: EstimateSearchDto = { ...props.searchDto };
                researchDto[itemTitle1] = value;
                researchDto.page = 1;
                props.handleResearch(researchDto);
            }
        };

    const filterRanger = (itemTitle1: 'requestDate' | 'answerDeadline') =>
        (value: DateRange) => {
            if (props.searchDto[itemTitle1] !== value) {
                const researchDto: EstimateSearchDto = { ...props.searchDto };
                researchDto[itemTitle1] = value;
                researchDto.page = 1;
                props.handleResearch(researchDto);
            }
        };

    const filterSelect = (itemTitle: 'item' | 'suppliers') =>
        (value: string[]) => {
            if (props.searchDto[itemTitle] !== value) {
                const researchDto: EstimateSearchDto = { ...props.searchDto };
                researchDto[itemTitle] = value;
                researchDto.page = 1;
                props.handleResearch(researchDto);
            }
        };

    const sortOrder = (itemTitle1: string): 'ascend' | 'descend' | null | undefined => {

        if (itemTitle1 === props.searchDto.sortField) {
            return props.searchDto.sortOrder;
        }

        return null;
    };

    const unitCurrency = (text: any, record: EstimateRequestSupplierResponseListDto, index?: number) => {
        return displayUtil.currency(record.unitCurrency, record.unitPrice)
    };

    //filter
    type filterType = Filter<EstimateRequestSupplierResponseListDto>;

    const responseStatusFilter: filterType = {
        filters: filterUtil.field2Fileter(responseStatusOptions), filterMultiple: true,
        filteredValue: props.searchDto.responseStatus,
        onFilter: (value: any, record: EstimateRequestSupplierResponseListDto) => record.responseStatus === value
    };

    const suppliersFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <SelectFilter fields={fullSupplierOptions} values={props.searchDto.suppliers} onChange={filterSelect('suppliers')} />,
        filteredValue: props.searchDto.suppliers,
    };
    const statusFilter: filterType = {
        filters: filterUtil.field2Fileter(statusOptions), filterMultiple: true,
        filteredValue: props.searchDto.status,
        onFilter: (value: any, record: EstimateRequestSupplierResponseListDto) => record.status === value
    };
    const requestNoFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.requestNo} onChange={filterInput('requestNo')} />,
        filteredValue: !props.searchDto.requestNo ? [] : [props.searchDto.requestNo],
    };

    // const purchasingManagerNameFilter: filterType = {
    //     filterIcon: <SearchOutlined />,
    //     // filterDropdown: <InputFilter value={props.searchDto.purchasingManagerName} onChange={filterInput('purchasingManagerName')} />,
    //     filterDropdown: <InputFilter value={props.searchDto.purchasingManagerName}  />,
    //     filteredValue: !props.searchDto.purchasingManagerName ? [] : [props.searchDto.purchasingManagerName],
    // };
    const itemNameFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <InputFilter value={props.searchDto.itemName} onChange={filterInput('itemName')} />,
        filteredValue: !props.searchDto.itemName ? [] : [props.searchDto.itemName],
    };
    const itemFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <SelectFilter fields={itemOptions} values={props.searchDto.item} onChange={filterSelect('item')} />,
        filteredValue: props.searchDto.item,
    };
    const requestDateFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <RangeFilter values={props.searchDto.requestDate} onChange={filterRanger('requestDate')} />,
        filteredValue: filterUtil.range2stringArray(props.searchDto.requestDate),
    };
    const answerDeadlineFilter: filterType = {
        filterIcon: <SearchOutlined />,
        filterDropdown: <RangeFilter values={props.searchDto.answerDeadline} onChange={filterRanger('answerDeadline')} />,
        filteredValue: filterUtil.range2stringArray(props.searchDto.answerDeadline),
    };

    const requestNoLink = (text: any, record: EstimateRequestSupplierResponseListDto, index: number) => {
        let random = Math.round(Math.random() * 100000);
        return <Link to={`../estimations/detail/${random}/${record.requestId}/${record.id}`}>{record.requestNo}</Link>;
    };

    // タイトル
    // 案件状態
    const statusTitle = <FormattedMessage id='estimate.status' />
    const supplierTitle = <FormattedMessage id='estimate.supplier' />
    const supplierNameTitle = <FormattedMessage id='estimate.supplierName' />
    // 回答状態
    const responseStatusTitle = <FormattedMessage id='estimate.responseStatus' />
    // 依頼番号
    const requestNoTitle = <FormattedMessage id='estimate.requestNo' />
    // 購買担当
    const purchasingManagerNameTitle = <FormattedMessage id='estimate.purchasingManager' />
    //依頼情報
    const estimateContentTitle = <FormattedMessage id='estimate.estimateContent' />
    //回答情報
    const responseContentTitle = <FormattedMessage id='estimate.responseContent' />
    // 品番
    const itemTitle = <FormattedMessage id='estimate.item' />
    // 品名
    const itemNameTitle = <FormattedMessage id='estimate.itemName' />
    // 見積依頼日
    const requestDateTitle = <FormattedMessage id='estimate.requestDate' />
    // 回答希望日
    const answerDeadlineTitle = <FormattedMessage id='estimate.answerDeadline' />
    // 見積有効日
    const estimatedEffectiveDateTitle = <FormattedMessage id='estimate.estimatedEffectiveDate' />
    // unitPrice
    const unitPriceTitle = <FormattedMessage id='estimate.unitPrice' />
    // unitCurrency
    const unitCurrencyTitle = <FormattedMessage id='estimate.unitCurrency' />
    // 最低発注数量
    const moqTitle = <FormattedMessage id='estimate.moq' />
    // 最小梱包数量
    const mpqTitle = <FormattedMessage id='estimate.mpq' />
    // 見積適用開始日
    const estimateStartDateTitle = <FormattedMessage id='estimate.estimateStartDate' />

    const width60 = '60px';
    const width80 = '80px';
    const width100 = '100px';
    const width120 = '120px';
    const width130 = '130px';
    const width140 = '140px';
    const width150 = '150px';
    const width160 = '160px';
    const width200 = '200px';
    const width240 = '240px';
    const widht290 = '290px';

    const columns: ColumnsType<EstimateRequestSupplierResponseListDto> = [
        // 案件状態
        {
            title: statusTitle,
            dataIndex: 'status',
            render: displayUtil.field(statusOptions),
            width: width140,
            sorter: true,
            sortOrder: sortOrder('status'),
            ...statusFilter

        },
        // response状態
        {
            title: responseStatusTitle,
            dataIndex: 'responseStatus',
            render: displayUtil.field(responseStatusOptions),
            width: width140,
            sorter: true,
            sortOrder: sortOrder('responseStatus'),
            ...responseStatusFilter

        },
        // 受注者コード
        {
            title: supplierTitle, width: width140,
            dataIndex: 'supplier',
            sorter: true, sortOrder: sortOrder('supplier'),
            ...suppliersFilter
        },
        // 受注者名称
        {
            title: supplierNameTitle, width: width240,
            dataIndex: 'supplier',
            ellipsis: true,
            render: displayUtil.field(suppliersOption)
        },
        {
            title: requestNoTitle,
            dataIndex: 'requestNo',
            width: width160,
            render: requestNoLink,
            sorter: true,
            sortOrder: sortOrder('requestNo'),
            ...requestNoFilter
        },
        // 品番
        {
            title: itemTitle,
            dataIndex: 'item',
            width: width240,
            sorter: true,
            sortOrder: sortOrder('item'),
            ellipsis: true,
            ...itemFilter
        },
        // 品名
        {
            title: itemNameTitle,
            dataIndex: 'itemName',
            width: width200,
            sorter: true,
            sortOrder: sortOrder('itemName'),
            ellipsis: true,
            ...itemNameFilter
        },
        // 見積依頼日
        {
            title: requestDateTitle,
            dataIndex: 'requestDate',
            width: width130,
            sorter: true,
            sortOrder: sortOrder('requestDate'),
            render: displayUtil.date,
            ...requestDateFilter
        },
        // 回答希望日
        {
            title: answerDeadlineTitle,
            dataIndex: 'answerDeadline',
            width: width160,
            sorter: true,
            sortOrder: sortOrder('answerDeadline'),
            render: displayUtil.date,
            ...answerDeadlineFilter
        },
        {
            title: estimatedEffectiveDateTitle,
            dataIndex: 'estimatedEffectiveDate',
            width: width130,
            render: displayUtil.date
        },
        // 単価
        {
            title: unitPriceTitle,
            dataIndex: 'unitPrice',
            width: width160,
            render: unitCurrency
        },
        {
            title: unitCurrencyTitle,
            dataIndex: 'unitCurrency',
            width: width100,
            // render: unitCurrency
        },
        // 最小梱包数量
        {
            title: moqTitle,
            dataIndex: 'moq',
            width: width80
        },
        // 最小梱包数量
        {
            title: mpqTitle,
            dataIndex: 'mpq',
            width: width80
        },
        // 报价适用开始日
        {
            title: estimateStartDateTitle,
            dataIndex: 'estimateStartDate',
            width: width130,
            render: displayUtil.date,
        },
        // 購買担当
        {
            title: purchasingManagerNameTitle,
            dataIndex: 'purchasingManagerName',
            width: width150,
           
        },
    ];

    const paginationProps: TablePaginationConfig = {
        current: props.searchDto.page,
        total: props.total,
        defaultPageSize: props.searchDto.rowCount,
        position: ['bottomLeft'],
        showSizeChanger: true,
        pageSizeOptions: constPageSizeOptions,
    }

    const title = () => {
        return (
            <>
                <FormattedMessage id='estimate.list' />
                <Button onClick={props.openSearchModal} style={{ marginLeft: 10, marginRight: 10 }} >
                    <SearchOutlined />
                    <FormattedMessage id='common.conditions' />
                </Button>
                {/* <Button className='add-btn' onClick={props.openFormModal}>
                    <FormattedMessage id='common.add' />
                </Button> */}
                <Button onClick={props.download} style={{ marginLeft: 10, marginRight: 10 }} >
                    {/* <FileExcelOutlined /> */}
                    <DownloadOutlined />
                    <FormattedMessage id='common.download' />
                </Button>
                <Button onClick={props.downloadPassed} style={{ marginLeft: 10, marginRight: 10 }} >
                    <DownloadOutlined />
                    <FormattedMessage id='common.downloadPassed' />
                </Button>
                <Button className='reset-btn' onClick={() => { props.reset(rowCount);}}>
                    <RollbackOutlined />
                    <FormattedMessage id='common.reset' />
                </Button>
            </>
        )
    };

    return (

        <Table<EstimateRequestSupplierResponseListDto>
            className="list-table"
            pagination={paginationProps}
            columns={columns}
            dataSource={props.data}
            loading={props.loading}
            rowClassName={displayUtil.rowClassName}
            rowKey={'id'}
            tableLayout={'fixed'}
            title={title}
            onChange={handleTableChange}
            scroll={constListScroll}
        // rowSelection={rowSelection}
        />
    );
}












