import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { ContainerOutlined, ScheduleOutlined, AccountBookOutlined, DeliveredProcedureOutlined, ReconciliationOutlined, InteractionOutlined, MessageOutlined, NotificationOutlined, BulbOutlined, DashboardOutlined} from '@ant-design/icons';
import { Layout, Menu } from 'antd';

import { CheckUser } from './api';
import { locales } from '../locales';

import { Doc } from '../doc';
import { Forecast } from '../forecast';
import { Pay } from '../pay';
import { BillingDetail } from '../billingDetail';
import { Acceptance } from '../acceptance';
import { Deliery, DeliveryOutput } from '../delivery';
import { OrderResponse } from '../orderResponse';
import { Notification } from '../notificate';
import { Estimation } from '../estimation'
import { Model } from '../model'
import { Inventory } from '../inventory';
import { useUser } from '../authorization';
import { SupplierEstimates } from '../supplierEstimates';
import { Guide } from '../guide';
import { Home } from '../home'

import { SuppierCommnunityFakeRouter } from './SuppierCommnunityFakeRtouer'
import { SuppierCommnunityAgreement } from './SuppierCommnunityAgreement';

import './header.css';
import './agreement.css';
import './siderMenu.css';
import './index.css'


const { SubMenu } = Menu;

export function SuppierCommnunityRouter() {
  const user = useUser();
  const currentLang = locales.getLang();  // 获取当前页面语言

  // 当页面语言为英语时，sider宽度为230， 否则是200
  const differentSiderWidht = currentLang === 'en-US' ? 280 : 200

  // 获取路由地址
  let url = new URL(window.location.href)
  let routerAddress = url.pathname

  let openSubMenuKey: Array<string> = []  // 当前路由被展开的下拉侧栏列表
  let selectItemKey: Array<string> = []  // 当前路由被选中的下拉侧栏列表
  if(routerAddress !== null && routerAddress !== ''){
    // 判定当前展开的下拉侧栏
    if('/orderresponses' === routerAddress) openSubMenuKey = ['delivery']
    if('/deliveryoutput' === routerAddress) openSubMenuKey = ['delivery']
    if('/deliveries' === routerAddress) openSubMenuKey = ['delivery']

    if('/estimations' === routerAddress) openSubMenuKey = ['quotation']
    if('/supplierestimates' === routerAddress) openSubMenuKey = ['quotation']

    if('/models' === routerAddress) openSubMenuKey = ['mold']
    if('/modelassets' === routerAddress) openSubMenuKey = ['mold']

    if('/pays' === routerAddress) openSubMenuKey = ['pay']
    if('/billingDetail' === routerAddress) openSubMenuKey = ['pay']

    // 判断当前被选中的路由
    if('/orderresponses' === routerAddress) selectItemKey = ['orderresponses']
    if('/deliveryoutput' === routerAddress) selectItemKey = ['deliveryoutput']
    if('/deliveries' === routerAddress) selectItemKey = ['deliveries']

    if('/forecasts' === routerAddress) selectItemKey = ['forecasts']

    if('/pays' === routerAddress) selectItemKey = ['pays']
    if('/billingDetail' === routerAddress) selectItemKey = ['billingDetail']

    if('/acceptances' === routerAddress) selectItemKey = ['acceptances']

    if('/estimations' === routerAddress) selectItemKey = ['estimations']
    if('/supplierestimates' === routerAddress) selectItemKey = ['supplierestimates']

    if('/models' === routerAddress) selectItemKey = ['models']
    if('/modelassets' === routerAddress) selectItemKey = ['modelassets']
    if('/modelassets' === routerAddress) selectItemKey = ['modelassets']

    if('/docs' === routerAddress) selectItemKey = ['docs']

    if('/home' === routerAddress) selectItemKey = ['home']
    if('/dashboard' === routerAddress) selectItemKey = ['home']

    if('/guide' === routerAddress) selectItemKey = ['guide']

  }

  // 获取用户是否已经同意
  let userReaded = CheckUser()

  const router = <Layout id='router'>
    <Layout.Sider className="site-layout-background" width={differentSiderWidht}>
      <Menu theme="light" mode="inline" defaultOpenKeys={openSubMenuKey} defaultSelectedKeys={selectItemKey}>
        <SubMenu key="delivery" title={<>
          <DeliveredProcedureOutlined />
          <FormattedMessage id='delivery' /> </>}>
          <Menu.Item key='orderresponses' >
            <Link to="/orderresponses?finishFlag=0">
              <InteractionOutlined />
              <FormattedMessage id='orderResponse' />
            </Link>
          </Menu.Item>
          {user?.isSupplier &&
          <Menu.Item key='deliveryoutput' >
            <Link to="/deliveryoutput">
              <ReconciliationOutlined />
              <FormattedMessage id='delivery.unprocessedlist' />
            </Link>
          </Menu.Item>
          }
          <Menu.Item key='deliveries' >
            <Link to="/deliveries?deliveryStatus=001">
              <ReconciliationOutlined />
              <FormattedMessage id='delivery.re-output' />
            </Link>
          </Menu.Item>
          {/* <Menu.Item >
            <Link to="/deliveryManifests">
              <FileDoneOutlined />
              <FormattedMessage id='manifest' />
            </Link>
          </Menu.Item> */}
        </SubMenu>
        <Menu.Item key='forecasts'>
          <Link to="/forecasts">
            <ScheduleOutlined />
            <FormattedMessage id='forecast' />
          </Link>
        </Menu.Item>
        {
          user?.serviceState === 'jp' && 
            <SubMenu key="pay" title={
              <>
                <ContainerOutlined />
                <FormattedMessage id='pay' />
              </>
            }>
              <Menu.Item key='pays'>
                <Link to="/pays">
                  <AccountBookOutlined />
                  <FormattedMessage id='pays' />
                </Link>
              </Menu.Item>
              <Menu.Item key='billingDetail'>
                <Link to="/billingDetail">
                  <AccountBookOutlined />
                  <FormattedMessage id='billingDetail' />
                </Link>
              </Menu.Item>
            </SubMenu>
        }
        <Menu.Item key='acceptances'>
          <Link to="/acceptances">
            <AccountBookOutlined />
            <FormattedMessage id='acceptance' />
          </Link>
        </Menu.Item>
        <SubMenu key="quotation" title={<>
          <ContainerOutlined />
          <FormattedMessage id='estimate' /> </>}>
          <Menu.Item key='estimations'>
            <Link to="/estimations">
              <ContainerOutlined />
              <FormattedMessage id='estimatesSub' />
            </Link>
          </Menu.Item>
          <Menu.Item key='supplierestimates'>
            <Link to="/supplierestimates">
              <ContainerOutlined />
              {user?.isSupplier &&
                <FormattedMessage id='supplierEstimates.supplier' />}
              {!user?.isSupplier &&
                <FormattedMessage id='supplierEstimates' />}
            </Link>
          </Menu.Item>
        </SubMenu>
        {/* 金型 */}
        {!user?.isSupplier &&
          <SubMenu key="mold" title={<>
            <MessageOutlined />
            <FormattedMessage id='modelManagement' /> </>}>
              <Menu.Item key='models'>
                <Link to="/models?deleteFlag=0">
                  <NotificationOutlined />
                  <FormattedMessage id='model' />
                </Link>
              </Menu.Item>
            <Menu.Item key='modelassets'>
              <Link to="/modelassets">
                <NotificationOutlined />
                <FormattedMessage id='inventoryConfirm' />
              </Link>
            </Menu.Item>
          </SubMenu>
          }
          {user?.isSupplier &&
            <Menu.Item key='modelassets'>
              <Link to="/modelassets">
                <NotificationOutlined />
                <FormattedMessage id='inventory' />
              </Link>
            </Menu.Item>
          }
          <Menu.Item key='docs'>
          <Link to="/docs">
            <ContainerOutlined />
            <FormattedMessage id='docInfo' />
          </Link>
        </Menu.Item>
        <Menu.Item key='home'>
          <Link to="/home">
            <DashboardOutlined />
            <FormattedMessage id='home' />
          </Link>
        </Menu.Item>
        <Menu.Item key='guide'>
          <Link to="/guide">
            <BulbOutlined />
            <FormattedMessage id='guide' />
          </Link>
        </Menu.Item>
        {/* <SubMenu key="sub2" title={<>
          <MessageOutlined />
          <FormattedMessage id='notification' /> </>}>
          <Menu.Item>
            <Link to="/notifications">
              <NotificationOutlined />
              <FormattedMessage id='subnotification' />
            </Link>
          </Menu.Item>
        </SubMenu> */}
      </Menu>
    </Layout.Sider>
    <Layout.Content className="site-layout-background">
      <Routes>
        <Route path="/guide" >
          <Guide />
        </Route>
        <Route path="/docs/:id/:detailId" >
          <Doc />
        </Route>
        <Route path="/docs/:id" >
          <Doc />
        </Route>
        <Route path="/docs" >
          <Doc />
        </Route>
        <Route path="/deliveryoutput" >
          <DeliveryOutput />
        </Route>
        <Route path="/deliveries/:id" >
          <Deliery />
        </Route>
        <Route path="/deliveries" >
          <Deliery />
        </Route>
        <Route path="/orderresponses/:id" >
          <OrderResponse />
        </Route>
        <Route path="/orderresponses" >
          <OrderResponse />
        </Route>
        <Route path="/forecasts/:date" >
          <Forecast />
        </Route>
        <Route path="/forecasts" >
          <Forecast />
        </Route>
        {
          user?.serviceState === 'jp' && 
            <Route path="/pays/:id" >
              <Pay />
            </Route>
        }
        {
          user?.serviceState === 'jp' && 
            <Route path="/pays" >
              <Pay />
            </Route>
        }
        {
          user?.serviceState === 'jp' && 
            <Route path="/billingDetail/detail/:companyID/:supplier/:acquisitiondate/:paymentDate" >
              <BillingDetail />
            </Route>
        }
        {
          user?.serviceState === 'jp' && 
            <Route path="/billingDetail" >
              <BillingDetail />
            </Route>
        }
        <Route path="/estimations/detail/:random/:id/:subId" >
          <Estimation />
        </Route>
        <Route path="/estimations/detail/:random/:id" >
          <Estimation />
        </Route>
        <Route path="/estimations/:id" >
          <Estimation />
        </Route>
        <Route path="/estimations" >
          <Estimation />
        </Route>
        <Route path="/acceptances/:id" >
          <Acceptance />
        </Route>
        <Route path="/acceptances" >
          <Acceptance />
        </Route>
        <Route path="/notifications" >
          <Notification />
        </Route>
        <Route path="/notifications/:id" >
          <Notification />
        </Route>
        <Route path="/notifications" >
          <Notification />
        </Route>
        <Route path="/models/:id" >
          <Model />
        </Route>
        <Route path="/models" >
          <Model />
        </Route>
        <Route path="/modelassets/:id" >
          <Inventory />
        </Route>
        <Route path="/modelassets" >
          <Inventory />
        </Route>
        <Route path="/supplierestimates/:id/details/:detailId" >
          <SupplierEstimates />
        </Route>
        <Route path="/supplierestimates/:id" >
          <SupplierEstimates />
        </Route>
        <Route path="/supplierestimates" >
          <SupplierEstimates />
        </Route>
        <Route path="/home/:batchName" >
          <Home />
        </Route>
        <Route path="/home" >
          <Home />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Routes>
    </Layout.Content>
  </Layout>


  // 这个是被模糊掉的路由
  // 之所以加这个，一是为了防止用户越权点击后面真实的路由，二是模糊效果是直接作用在页面所有元素上的，取消模糊的方法兼容性和性能都可能有问题，不如做一个假的元素然后直接移除
  // 这个假路由移除了侧栏的路由功能，路由体也只保留了主页这一个页面
  const fakeRouter = SuppierCommnunityFakeRouter()
  const userAgreement = SuppierCommnunityAgreement()

  return (
    <Router>
      <Layout>
        {
          <>
            {router}
            {
              (!userReaded && user?.serviceState === 'jp') &&
              <>
                {/* fake blur router （一个只有home和support功能的路由，被模糊之后当作agreement的背景） */}
                <Layout id='userAgreementBlur' className='agreementBlurRouter'>
                  {fakeRouter}
                </Layout>
                <Layout id='userAgreement' className='agreementMaskAndBox'>
                  {userAgreement}
                </Layout>
              </>
            }
          </>
        }
      </Layout>
    </Router>
  )
}
