import React, { useState, useEffect } from "react";
import { Col, Input, InputNumber, Row } from "antd"
import { useIntl } from "react-intl";
import { NumberRange } from "./types";

interface Props {
  values: NumberRange;
  onChange: (values: NumberRange) => void;
  style?: React.CSSProperties;
}

export const NumberRangePicker = (props: Readonly<Props>) => {
  // 共通のstates
  const intl = useIntl();

  const [value, setValue] = useState(props.values);

  const onChangeMininum = (record: number | null) => {
    props.onChange([record, value?.[1]])
  }

  const onChangeMaxinum = (record: number | null) => {
    props.onChange([value?.[0], record])
  }

  useEffect(() => {
    setValue(props.values);
  }, [props.values]);

  return (
      <Row className='picker' style={props.style}>
        <Col span={10}>
          <InputNumber
            className="site-input-left"
            value={value?.[0]}
            style={{ width: '100%', textAlign: 'center' }}
            placeholder={intl.formatMessage({id: 'common.minimun'})}
            onChange={onChangeMininum}
          />
        </Col>
        <Col span={4}>
          <Input
            className="site-input-split"
            style={{
              width: '100%',
              borderLeft: 0,
              borderRight: 0,
              pointerEvents: 'none',
              textAlign: 'center',
            }}
            placeholder="~"
            disabled
          />
        </Col>
        <Col span={10}>
          <InputNumber
            value={value?.[1]}
            className="site-input-right"
            style={{
              width: '100%',
              textAlign: 'center',
            }}
            placeholder={intl.formatMessage({id: 'common.maximum'})}
            onChange={onChangeMaxinum}
          />
        </Col>
      </Row>
  )
}
