import React, { Key, useState } from 'react';
import { Link, useNavigate, } from "react-router-dom";
import { FormattedMessage, useIntl, } from 'react-intl';

import { Table, Input, Form, Button, Row, Col, Popconfirm } from 'antd';
import { DeleteOutlined, FileExcelOutlined, RollbackOutlined, SearchOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnsType } from 'antd/lib/table';
import { ExpandableConfig, SorterResult } from 'antd/es/table/interface';

import { arrayUtil, displayUtil, filterUtil } from '../util'
import { Field } from '../fields';
import { InputFilter, RangeFilter, Filter, DateRange, constListScroll, constPageSizeOptions, Attachment, SelectFilter } from '../common'
import { DocSearchDto, DocListRecordDto, DocDetailSupplierDto } from './api'
import './index.css';
import { useUser } from '../authorization';
import { useFields } from '../fields';

// 实验
import { createRef } from 'react';
import type { FormInstance } from 'antd';
import { CommonFileUpload } from '../common/CommonFileUpload';

export interface Props {
  loading: boolean;
  total: number;
  data: DocListRecordDto[];
  searchDto: DocSearchDto;
  categoryOptions: Field[];
  supplierOptions: Field[];
  buyerOptions: Field[];
  replyFlagOptions: Field[];
  download: () => void;
  openFormModal: () => void;
  openSearchModal: () => void;
  openCreateModal: () => void;
  handleDelete: (data: DocListRecordDto, version: number) => void;
  handleResearch: (researchDto: DocSearchDto) => void;
  handleSearch: (searchDto: DocSearchDto) => void;
  handleSubmit: (data: DocDetailSupplierDto) => void;
  reset: (rowCount: number) => void;
}

export function DocList(props: Props) {

  const navigate = useNavigate();
  const user = useUser();
  const supplierOptions = useFields('supplier');
  const fullSupplierOptions = useFields('fullSupplier');
  const [rowCount, setRowCount] = useState<number>(10);
  // const acceptFlag = useFields('acceptFlag');
  // const suppliers = useFields('supplier');
  // const readFlag = useFields('readFlag');
  // const replyFlagOptions = useFields('replyFlag');

  // ページング、フィルター、ソート順の処理
  const handleTableChange = (pagination: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: SorterResult<DocListRecordDto> | SorterResult<DocListRecordDto>[],
    extra: any) => {

    const { documentCategory: preCategory, replyFlag: preReplyFlag } = props.searchDto;
    const { documentCategory, replyFlag } = filters;
    const curentCategory = documentCategory ? documentCategory as string[] : [];
    const curentReplyFlag = replyFlag ? replyFlag as string[] : [];

    if (!!pagination.pageSize && rowCount !== pagination.pageSize) {
      setRowCount(pagination.pageSize)
    }

    if (!arrayUtil.equals(curentCategory, preCategory)) {
      props.searchDto.documentCategory = curentCategory;
      props.handleSearch(props.searchDto);
    }
    if (!arrayUtil.equals(curentReplyFlag, preReplyFlag)) {
      props.searchDto.replyFlag = curentReplyFlag;
      props.handleSearch(props.searchDto);
    }

    // 再検索の条件を作成する
    const researchDto: DocSearchDto = { ...props.searchDto };
    let researchFlag = false;

    if (!!pagination.pageSize && props.searchDto.rowCount !== pagination.pageSize) {
      researchDto.page = 1;
      researchDto.rowCount = pagination.pageSize;
      researchFlag = true;
    } else if (props.searchDto.page !== pagination.current) {
      researchDto.page = pagination.current;
      researchFlag = true;
    }

    const fixSorter = sorter as SorterResult<DocListRecordDto>;
    if (props.searchDto.sortField !== fixSorter.field
      || props.searchDto.sortOrder !== fixSorter.order) {
      researchDto.sortField = fixSorter.field as string | undefined;
      researchDto.sortOrder = fixSorter.order;
      researchFlag = true;
    }

    if (researchFlag) {
      props.handleResearch(researchDto);
    }
  };


  const filterInput = (itemTitle: 'title') =>
    (value?: string) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: DocSearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };

  const filterRanger = (itemTitle: 'sendTime') =>
    (value: DateRange) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: DocSearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };
  const filterSelect = (itemTitle: 'suppliers') =>
    (value: string[]) => {
      if (props.searchDto[itemTitle] !== value) {
        const researchDto: DocSearchDto = { ...props.searchDto };
        researchDto[itemTitle] = value;
        researchDto.page = 1;
        props.handleResearch(researchDto);
      }
    };

  const sortOrder = (itemTitle: string): 'ascend' | 'descend' | null | undefined => {
    if (itemTitle === props.searchDto.sortField) {
      return props.searchDto.sortOrder;
    }
    return null;
  };

  type filterType = Filter<DocListRecordDto>;

  const sendTimeFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <RangeFilter values={props.searchDto.sendTime} onChange={filterRanger('sendTime')} />,
    filteredValue: filterUtil.range2stringArray(props.searchDto.sendTime),
  };
  const documentCategoryFilter: filterType = {
    filters: filterUtil.field2Fileter(props.categoryOptions), filterMultiple: false,
    filteredValue: props.searchDto.documentCategory,
    onFilter: (value: any, record: DocListRecordDto) => record.documentCategory === value
  };
  const titleFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <InputFilter value={props.searchDto.title} onChange={filterInput('title')} />,
    filteredValue: !props.searchDto.title ? [] : [props.searchDto.title],
  };


  const replyFlagFilter: filterType = {
    filters: filterUtil.field2Fileter(props.replyFlagOptions), filterMultiple: false,
    filteredValue: props.searchDto.replyFlag,
    onFilter: (value: any, record: DocListRecordDto) => record.replyFlag === value
  };


  const suppliersFilter: filterType = {
    filterIcon: <SearchOutlined />,
    filterDropdown: <SelectFilter fields={fullSupplierOptions} values={props.searchDto.suppliers} onChange={filterSelect('suppliers')} />,
    filteredValue: props.searchDto.suppliers,
  };

  const idLink = (text: any, record: DocListRecordDto, index: number) => {
    return !!user?.isSupplier ? <Link to={`../docs/${record.id}/${record.detailId}`}>{record.title}</Link> : <Link to={`../docs/${record.id}`}>{record.title}</Link>;
  };


  // タイトル
  const replyFlag = <FormattedMessage id='docInfo.replyFlag' />
  const sendTimeTitle = <FormattedMessage id='docInfo.sendTime' />;
  const receiptTimeTitle = <FormattedMessage id='docInfo.receiptTime' />;
  const buyerNameTitle = <FormattedMessage id='docInfo.buyerName' />;
  const documentCategoryTitle = <FormattedMessage id='docInfo.documentCategory' />;
  const titleTitle = <FormattedMessage id='docInfo.title' />;
  const messageTitle = <FormattedMessage id='docInfo.sendContent' />;
  const receiptContent = <FormattedMessage id='docInfo.receiptContent' />;
  const sendQuantityTitle = <FormattedMessage id='docInfo.sendQuantity' />;
  const acceptQuantityTitle = <FormattedMessage id='docInfo.acceptQuantity' />;
  const replayQuantityTitle = <FormattedMessage id='docInfo.replayQuantity' />;
  // アクション
  const operationTitle = <FormattedMessage id='common.operation' />;
  // 受注者コード
  const supplierTitle = <FormattedMessage id='forecast.supplier' />;
  // 受注者名称
  const supplierNameTitle = <FormattedMessage id='forecast.supplierName' />;
  //Width
  const width120 = '120px'
  const width140 = '140px'
  const width160 = '160px'
  const width200 = '200px'
  const width280 = '280px'


  const arrays = [
    {
      title: replyFlag,
      dataIndex: 'replyFlag',
      render: displayUtil.field(props.replyFlagOptions),
      sorter: true,
      sortOrder: sortOrder('replyFlag'),
      width: width160,
      ...replyFlagFilter
    },

    {
      title: buyerNameTitle,
      dataIndex: 'buyer',
      width: width200,
      render: displayUtil.field(props.buyerOptions),
      ellipsis: true
    },
    // 受注者コード
    {
      title: supplierTitle, width: width140,
      dataIndex: 'supplier',
      sorter: true, sortOrder: sortOrder('supplier'),
      ...suppliersFilter
    },
    // 受注者名称
    {
      title: supplierNameTitle, width: width200,
      dataIndex: 'supplier',
      render: displayUtil.field(supplierOptions),
      ellipsis: true,
    },
    {
//      title: user?.isSupplier ? receiptTimeTitle : sendTimeTitle,
      title: sendTimeTitle,
      dataIndex: 'sendTime',
      width: width140,
      render: displayUtil.date,
      sorter: true,
      sortOrder: sortOrder('sendTime'),
      ...sendTimeFilter,
    },
    {
      title: documentCategoryTitle,
      dataIndex: 'documentCategory',
      width: width140,
      render: displayUtil.field(props.categoryOptions),
      sorter: true,
      sortOrder: sortOrder('documentCategory'),
      ...documentCategoryFilter,
      ellipsis: true
    },
    {
      title: titleTitle,
      dataIndex: 'title',
      width: width200,
      render: idLink,
      sorter: true,
      sortOrder: sortOrder('title'),
      ...titleFilter,
      ellipsis: true
    },
    {
      title: user?.isSupplier ? receiptContent : messageTitle,
      dataIndex: 'message',
      width: width200,
      ellipsis: true
    },
    {
      title: sendQuantityTitle,
      dataIndex: 'sendQuantity',
      width: width140,
      ellipsis: true
    },
    {
      title: acceptQuantityTitle,
      dataIndex: 'acceptQuantity',
      width: width140,
      ellipsis: true
    },
    {
      title: replayQuantityTitle,
      dataIndex: 'replayQuantity',
      width: width140,
      ellipsis: true
    },
    {
      title: operationTitle,
      dataIndex: 'id',
      width: width140,
      render: (text: any, record: DocListRecordDto, index: number) => delBtn(record, record.version)
    }
  ]

  const columns: ColumnsType<DocListRecordDto> =
    !!user?.isSupplier
      ? arrays.filter(item1 => item1.dataIndex !== 'sendQuantity' && item1.dataIndex !== 'acceptQuantity' && item1.dataIndex !== 'replayQuantity' && item1.dataIndex !== 'id')
      : arrays.filter(item1 => item1.dataIndex !== 'buyer' && item1.dataIndex !== 'replyFlag' && item1.dataIndex !== 'supplier' && item1.dataIndex !== 'supplierName')

  const paginationProps: TablePaginationConfig = {
    current: props.searchDto.page,
    total: props.total,
    defaultPageSize: props.searchDto.rowCount,
    position: ['bottomLeft'],
    showSizeChanger: true,
    pageSizeOptions: constPageSizeOptions
  }

  const delBtn = (id: DocListRecordDto, ver: number) => {
    return (
      <>
        <Popconfirm placement="left" title={<FormattedMessage id='message.delConfirm' />}
          onConfirm={(event) => props.handleDelete(id, ver)}
          okText={<FormattedMessage id='common.delete' />}
          cancelText={<FormattedMessage id='common.cancel' />} >
          <Button className="deleteBtn">
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </>
    )
  };

  const title = () => {
    return (
      <>
        <FormattedMessage id='docInfo.list' />
        <Button onClick={props.openSearchModal} style={{ marginLeft: 10, marginRight: 10 }} >
          <SearchOutlined />
          <FormattedMessage id='common.conditions' />
        </Button>
        <Button onClick={props.download} style={{ marginLeft: 10, marginRight: 10 }} >
          <DownloadOutlined />
          <FormattedMessage id='common.download' />
        </Button>
        {!user?.isSupplier &&
          <Button onClick={props.openCreateModal} style={{ marginLeft: 10, marginRight: 10 }} >
            <PlusOutlined />
            <FormattedMessage id='docInfo.add' />
          </Button>
        }

        <Button className='reset-btn' onClick={() => { props.reset(rowCount); }}>
          <RollbackOutlined />
          <FormattedMessage id='common.reset' />
        </Button>
      </>
    )
  };

  const intl = useIntl();
  const expandedRowRender = (record: DocListRecordDto, index: any, indent: any, expanded: any) => {

    const { TextArea } = Input;
    const replyConfirm = <FormattedMessage id='message.replyConfirm' />
    const confirm = <FormattedMessage id='common.confirm' />;
    const replyCancel = <FormattedMessage id='common.cancel' />;
    const reply = <FormattedMessage id='docInfo.reply' />;
    const formSubmitRef = createRef<FormInstance>();

    return <>
      <Form
        className="form"
        // form={form}
        preserve={false}
        onFinish={props.handleSubmit}
        layout={'vertical'}
        colon={false}
        requiredMark={true}
        size={'large'}
        ref={formSubmitRef}
      >

        <Row style={{ marginLeft: '20px', marginBottom: '10px' }}>
          <Col span={10}>
            <FormattedMessage id='docInfo.replyContent' />
          </Col>

          <Col span={10} offset={1}>
            <FormattedMessage id='docInfo.docFiles' />
          </Col>
        </Row>

        <Row style={{ height: '265px', marginLeft: '20px' }}>
          <Col span={10}>
            <Form.Item
              name='replyMessage'
              style={{ marginBottom: '0px' }}
              initialValue={record.replyMessage}
              rules={[{ max: 200, message: intl.formatMessage({ id: 'estimate.maxError' }, { a: intl.formatMessage({ id: 'docInfo.replyContent' }), b: '200' }) }]}>
              <TextArea autoSize={{ minRows: 10, maxRows: 10 }}
                disabled={record.acceptFlag === '002' ? true : false}
              />
            </Form.Item>
          </Col>

          <Col span={10} offset={1}>
            <Form.Item name='detailAttachments' noStyle>
              <CommonFileUpload
                attachments={!!record ? record.detailAttachments : []}
                canUpload={false}
                persist={(uuid: string) => {
                  // 処理なし
                }}
                remove={(uuid: string) => {
                  // 処理なし
                }}
              />
            </Form.Item>
          </Col>

          <Col span={1} offset={1}>
            {!(record.acceptFlag === '002') &&

              <Popconfirm
                placement='left'
                title={replyConfirm}
                onConfirm={() => {
                  const formDom = formSubmitRef.current?.getFieldsValue(true) || {};
                  formDom['id'] = record.id;
                  formDom['detailId'] = record.detailId;
                  formDom['detailAttachments'] = record.detailAttachments;
                  // formDom.submit();
                  props.handleSubmit(formDom)
                }}
                disabled={record.acceptFlag === '002' ? true : false}
                okText={confirm}
                cancelText={replyCancel} >
                <Button style={{}}
                  disabled={record.acceptFlag === '002' ? true : false}
                >
                  {reply}</Button>
              </Popconfirm>
            }
          </Col>
        </Row>
      </Form>
    </>
  }

  const rowExpandable = () => {
    return true;
  };

  const expandableProps: ExpandableConfig<DocListRecordDto> = {
    defaultExpandAllRows: false,
    expandedRowRender: expandedRowRender,
    rowExpandable: rowExpandable,
    columnWidth: '40px',
  };

  return (
    <>
      <Table<DocListRecordDto>
        className="list-table"
        dataSource={props.data}
        columns={columns}
        pagination={paginationProps}
        loading={props.loading}
        rowClassName={displayUtil.rowClassName}
        rowKey={'uniqueDocKey'}
        tableLayout={'fixed'}
        title={title}
        onChange={handleTableChange}
        scroll={constListScroll}
      // expandable={expandableProps}
      />
    </>
  );
}