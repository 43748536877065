// React APIs
import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate, } from "react-router-dom";
import { ListStates } from '../common';

// 共通のAPIs
// 機能内部のAPIs
import { get, search2Dto, dto2search, NotificationListRecordDto, NotificationSearchDto, } from './api';
import { Props as ListProps } from './NotificationList';
import { Props as SearchProps } from './NotificationSearch';

// システム情報お知らせ
export function NotificationHoc(
    List: React.ComponentType<ListProps>,
    Search: React.ComponentType<SearchProps>) {

    // URLのID
    const { id: urlId } = useParams<{ id?: string }>();
    // URLのquery文字列
    const { search } = useLocation();
    // URL変更のハンドル
    const navigate = useNavigate();
    // 検索条件（フィルター、ページとソート順）
    const [searchDto, setSearchDto] = useState<NotificationSearchDto>(search2Dto(search));

    // 一覧のstates
    const [listData, setListData] = useState<ListStates<NotificationListRecordDto>>({ loading: false, total: 0, data: [] });

    // 検索のstates
    // 検査画面の表示状態
    const [searchVisible, setSearchVisible] = useState<boolean>(false);

    // URLのQuery変更によって検索を再実施する
    useEffect(() => {

            setListData(data => ({ ...data, loading: true }))
            setSearchDto(search2Dto(search));

            get(search)
                .then((para: [number, NotificationListRecordDto[]]) => {
                    const [count, vos] = para;
                    setListData({ loading: false, total: count, data: vos })
                })
                .catch(() => {
                    setListData(data => ({ ...data, loading: false }))
                });
        }
        , [urlId,search]);

    // 検索条件を変更する
    const setSearch = (researchDto: NotificationSearchDto) => {

        // 期間範囲の編集
        if (!!researchDto.incomingDateAndTime && !!researchDto.incomingDateAndTime[0]) {
            researchDto.incomingDateAndTime[0] = researchDto.incomingDateAndTime[0].startOf('day');
        }
        if (!!researchDto.incomingDateAndTime && !!researchDto.incomingDateAndTime[1]) {
            researchDto.incomingDateAndTime[1] = researchDto.incomingDateAndTime[1].endOf('day');
        }

        // 検索条件変更を反映する
        const query = dto2search(researchDto);

        // URLを再設定する
        navigate(`/notifications${query}`);
    };

    const listProps = {
        // 画面loading状態
        loading: listData.loading,
        // 件数合計
        total: listData.total,
        // 一覧画面の表示データ
        data: listData.data,
        // 検索条件（フィルター、ページとソート順）
        searchDto: searchDto,
        // 検査画面を表示する
        openSearchModal: () => setSearchVisible(true),
        // 検査画面を表示する
        handleResearch: setSearch,
    };

    const searchProps = {
        // 検査画面の表示状態
        visible: searchVisible,
        searchDto: searchDto,
        handleSearch: (value: NotificationSearchDto) => {
            setSearchVisible(false);
            setSearch(value);
        },
        close: () => setSearchVisible(false),
    };

    return (
        <>
            <List {...listProps} />
            <Search {...searchProps} />
        </>
    );
}
