import React from 'react';
import { Routes, Route, Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { ContainerOutlined, ScheduleOutlined, AccountBookOutlined, DeliveredProcedureOutlined, ReconciliationOutlined, InteractionOutlined, MessageOutlined, NotificationOutlined, BulbOutlined, DashboardOutlined} from '@ant-design/icons';
import { Layout, Menu} from 'antd';

import { locales } from '../locales';

import { useUser } from '../authorization';
import { Guide } from '../guide';
import { Home } from '../home'

import './header.css';
import './agreement.css';
import './siderMenu.css';
import './index.css'

const { SubMenu } = Menu;

export function SuppierCommnunityFakeRouter() {
  
  const user = useUser();

  const currentLang = locales.getLang();  // 获取当前页面语言

  // 当页面语言为英语时，sider宽度为230， 否则是200
  const differentSiderWidht = currentLang === 'en-US' ? 280 : 200

  const fakeRouter = <>
    <Layout.Sider className="site-layout-background" width={differentSiderWidht}>
      <Menu theme="light" mode="inline">
        <SubMenu key="sub1" title={<>
          <DeliveredProcedureOutlined />
          <FormattedMessage id='delivery' /> </>}>
          <Menu.Item >
            <Link to="/">
              <InteractionOutlined />
              <FormattedMessage id='orderResponse' />
            </Link>
          </Menu.Item>
          {user?.isSupplier &&
          <Menu.Item >
            <Link to="/">
              <ReconciliationOutlined />
              <FormattedMessage id='delivery.unprocessedlist' />
            </Link>
          </Menu.Item>
          }
          <Menu.Item >
            <Link to="/">
              <ReconciliationOutlined />
              <FormattedMessage id='delivery.re-output' />
            </Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item>
          <Link to="/">
            <ScheduleOutlined />
            <FormattedMessage id='forecast' />
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/">
            <AccountBookOutlined />
            <FormattedMessage id='pay' />
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/">
            <AccountBookOutlined />
            <FormattedMessage id='acceptance' />
          </Link>
        </Menu.Item>
        <SubMenu key="sub4" title={<>
          <ContainerOutlined />
          <FormattedMessage id='estimate' /> </>}>
          <Menu.Item>
            <Link to="/">
              <ContainerOutlined />
              <FormattedMessage id='estimatesSub' />
            </Link>
          </Menu.Item>
          <Menu.Item >
            <Link to="/">
              <ContainerOutlined />
              {user?.isSupplier &&
                <FormattedMessage id='supplierEstimates.supplier' />}
              {!user?.isSupplier &&
                <FormattedMessage id='supplierEstimates' />}
            </Link>
          </Menu.Item>
        </SubMenu>
        {/* 金型 */}
        {!user?.isSupplier &&
          <SubMenu key="sub3" title={<>
            <MessageOutlined />
            <FormattedMessage id='modelManagement' /> </>}>
              <Menu.Item>
                <Link to="/">
                  <NotificationOutlined />
                  <FormattedMessage id='model' />
                </Link>
              </Menu.Item>
            <Menu.Item>
              <Link to="/">
                <NotificationOutlined />
                <FormattedMessage id='inventoryConfirm' />
              </Link>
            </Menu.Item>
          </SubMenu>
          }
          {user?.isSupplier &&
            <Menu.Item>
              <Link to="/">
                <NotificationOutlined />
                <FormattedMessage id='inventory' />
              </Link>
            </Menu.Item>
          }
          <Menu.Item>
          <Link to="/">
            <ContainerOutlined />
            <FormattedMessage id='docInfo' />
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/home">
            <DashboardOutlined />
            <FormattedMessage id='home' />
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/guide">
            <BulbOutlined />
            <FormattedMessage id='guide' />
          </Link>
        </Menu.Item>
      </Menu>
    </Layout.Sider>
    <Layout.Content className="site-layout-background">
      <Routes>
        <Route path="/guide" >
          <Guide />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Routes>
    </Layout.Content>
  </>
  return fakeRouter
}
