// React APIs
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from "react-router-dom";

// Antd APIs
// Library APIs
import * as XLSX from 'xlsx';
import { useUser } from '../authorization';
import { DetailStates, ListStates } from '../common';
import { displayUtil } from '../util';

// 共通のAPIs
import { useFields } from '../fields';

// 機能内部のAPIs
import { get, getById, search2Dto, dto2search, PayListDto, PaySearchDto, PayDetailDto, } from './api';
import { Props as DetailProps } from './PayDetail';
import { Props as ListProps } from './PayList';
import { Props as SearchProps } from './PaySearch';
/**
 * 支払通知のコンポネート
 */
export function PayHoc(
  Detail: React.ComponentType<DetailProps>,
  List: React.ComponentType<ListProps>,
  Search: React.ComponentType<SearchProps>) {

  // 共通のstates
  const intl = useIntl();

  const readFlagOptions = useFields('readFlag');

  // URLのID
  const { id: urlId } = useParams<{ id?: string }>();
  // URLのquery文字列
  const { search } = useLocation();
  // URL変更のハンドル
  const navigate = useNavigate();
  // 検索条件（フィルター、ページとソート順）
  const [searchDto, setSearchDto] = useState<PaySearchDto>(search2Dto(search));

  // 一覧のstates
  const [listData, setListData] = useState<ListStates<PayListDto>>({ loading: false, total: 0, data: [] });

  // 検索のstates
  // 検査画面の表示状態
  const [searchVisible, setSearchVisible] = useState<boolean>(false);

  // 詳細のstates
  const [detailData, setDetailData] = useState<DetailStates<PayDetailDto>>({ visible: false });
  const user = useUser();
  // URLのQuery変更によって検索を再実施する
  useEffect(() => {
    setDetailData(data => ({ ...data, visible: false }));

    if (!!urlId) {
      getById(urlId)
        .then((record: PayDetailDto) => {
          setDetailData({ visible: true, data: record });
        })
        .catch(() => {
        })
    } else {
      setListData(data => ({ ...data, loading: true }))
      setSearchDto(search2Dto(search));

      get(search)
        .then((para: [number, PayListDto[]]) => {
          const [count, vos] = para;
          setListData({ loading: false, total: count, data: vos })
        })
        .catch(() => {
          setListData(data => ({ ...data, loading: false }))
        });
    }
  }, [urlId, search]);
  // 検索の処理
  const handleSearch = (value: any) => {
    setSearchVisible(false);
    setSearch(value);
  };
  
  // 検索条件を変更する
  const setSearch = (researchDto: PaySearchDto) => {

    // 期間範囲の編集
    if (!!researchDto.paymentDate && !!researchDto.paymentDate[0]) {
      researchDto.paymentDate[0] = researchDto.paymentDate[0].startOf('day');
    }
    if (!!researchDto.paymentDate && !!researchDto.paymentDate[1]) {
      researchDto.paymentDate[1] = researchDto.paymentDate[1].endOf('day');
    }

    if (!!researchDto.dateOfIssue && !!researchDto.dateOfIssue[0]) {
      researchDto.dateOfIssue[0] = researchDto.dateOfIssue[0].startOf('day');
    }
    if (!!researchDto.dateOfIssue && !!researchDto.dateOfIssue[1]) {
      researchDto.dateOfIssue[1] = researchDto.dateOfIssue[1].endOf('day');
    }

    // 検索条件変更を反映する
    const query = dto2search(researchDto);

    // URLを再設定する
    navigate(`/pays${query}`);
  };

  const download = () => {

    const downloadSearchDto = { ...searchDto, rowCount: 0 };

    setListData(data => ({ ...data, loading: true }));

    get(dto2search(downloadSearchDto))
      .then((para: [number, PayListDto[]]) => {

        setListData(data => ({ ...data, loading: false }));
        
        const [, vos] = para;
        const excelData = [];
        const titles = [];
        if (!user?.isSupplier) {
          titles.push(intl.formatMessage({ id: 'pay.readFlag' }));
        }
        titles.push(intl.formatMessage({ id: 'pay.paymentDate' }));
        titles.push(intl.formatMessage({ id: 'pay.dateOfIssue' }))
        titles.push(intl.formatMessage({ id: 'pay.paymentId' }));
        titles.push(intl.formatMessage({ id: 'pay.buyerName' }));
        titles.push(intl.formatMessage({ id: 'pay.supplierCode' }));
        titles.push(intl.formatMessage({ id: 'pay.supplierName' }));
        titles.push(intl.formatMessage({ id: 'pay.currencyCode' }));
        titles.push(intl.formatMessage({ id: 'pay.totalPaymentAmount' }));
        titles.push(intl.formatMessage({ id: 'pay.lastMonthPayAdjustAmount' }));
        titles.push(intl.formatMessage({ id: 'pay.purchasePrice' }));
        titles.push(intl.formatMessage({ id: 'pay.accountsReceivableOffset' }));
        titles.push(intl.formatMessage({ id: 'pay.otherOffset' }));
        titles.push(intl.formatMessage({ id: 'pay.payAdjustAmount' }));
        titles.push(intl.formatMessage({ id: 'pay.balanceAmountPay' }));
        excelData.push(titles);
        vos.forEach(vo => {
          const datas = [];
          if (!user?.isSupplier) {
            datas.push(displayUtil.field(readFlagOptions)(vo.readFlag));            
          }
          datas.push(vo?.paymentUponEachUseFlag === '1' ? intl.formatMessage({ id: 'pay.paymentUponEachUse' }) : displayUtil.date(vo?.paymentDate));
          datas.push(displayUtil.date(vo.dateOfIssue))
          datas.push(vo.paymentId);
          datas.push(vo.buyerName);
          datas.push(vo.supplier);
          datas.push(vo.supplierName);
          datas.push(vo.currencyCode);
          datas.push(vo.totalPaymentAmount);
          datas.push(vo.lastMonthPayAdjustAmountAll);
          datas.push(vo.purchasePriceAll);
          datas.push(vo.accountsReceivableOffset);
          datas.push(vo.otherOffset);
          datas.push(vo.payAdjustAmountAll);
          datas.push(vo.balanceAmountPay);
          excelData.push(datas);
        })

        const worksheet = XLSX.utils.aoa_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, intl.formatMessage({ id: 'pay' }));
        XLSX.writeFile(workbook, `${intl.formatMessage({ id: 'pay' })}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
      })
      .catch(() => {

        setListData(data => ({ ...data, loading: false }));
      });

  };

  const reset = (rowCount: number) => {
    setSearch({

      acquisitionDate: [null, null],

      paymentDate: [null, null],

      suppliers: [],

      supplierName: '',
      
      paymentId: '',

      readFlag: [],

      rowCount: rowCount,

      dateOfIssue: [null, null],

      totalPaymentAmount: [null, null],

      lastMonthPayAdjustAmountAll: [null, null],

      purchasePriceAll: [null, null],

      accountsReceivableOffset: [null, null],

      otherOffset: [null, null],

      payAdjustAmountAll: [null, null],

      balanceAmountPay: [null, null],

    } as PaySearchDto)
  }

  const listProps = {
    // 画面loading状態
    loading: listData.loading,
    // 件数合計
    total: listData.total,
    // 一覧画面の表示データ
    data: listData.data,
    //既得フラグリスト
    readFlagOptions:readFlagOptions,
    // 検索条件（フィルター、ページとソート順）
    searchDto: searchDto,
    // 検査画面を表示する
    openSearchModal: () => setSearchVisible(true),
    //Download
    download: download,
    // 検査画面を表示する
    handleResearch: setSearch,
    handleSearch: handleSearch,
    reset: reset
  };

  const searchProps = {
    // 検査画面の表示状態
    visible: searchVisible,
    searchDto: searchDto,
    readFlagOptions:readFlagOptions,
    handleSearch: (value: PaySearchDto) => {
      setSearchVisible(false);
      setSearch(value);
    },
    close: () => setSearchVisible(false),
  };

  const detailProps = {
    // 詳細画面の表示状態
    visible: detailData.visible,
    detail: detailData.data,
    close: () => {
      if (dto2search(searchDto) !== '') {
        navigate(-1);
      } else {
        navigate('/pays');
      }
    },
  };

  return (
    <>
      <List {...listProps} />
      <Search {...searchProps} />
      <Detail {...detailProps} />
    </>
  );
}
